export function calculateMinDate() {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()); // minimum age is 18
    return minDate.toISOString().split('T')[0];
  }
  
export  function calculateMaxDate() {
    const today = new Date();
    const maxDate = new Date(today.getFullYear()  - 100, today.getMonth(), today.getDate()); // Assuming a maximum age of 100 years
    return maxDate.toISOString().split('T')[0];
  }