import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';

const Portal = ({ parent, children }: any) => {
  const parentElement = document.getElementById(parent) || document.body
  return ReactDOM.createPortal(
    children, parentElement)
}

const Navbar = ({ token }: { token: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [selectedMenu, setSelectedMenu] = useState('');
  const location = useLocation();
  const Navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const pathname = location.pathname;
    setSelectedMenu(pathname);
  }, [location]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <>
      <nav className="z-50 ">
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="flex justify-between h-16 items-center">
            <div className="flex">
              <div className="hidden md:block">
                <div className="flex flex-between text-white font-medium text-sm lg:text-base items-center space-x-2 max-w-[350px] lg:max-w-[500px] xl:max-w-none lg:space-x-4 gap-2 md:gap-3 xl:gap-12">
                  <NavLink to="/aboutus" className={`nav-link  ${selectedMenu === '/aboutus' ? 'text-[#0076CD]' : 'hover:text-blue-300'}`}>
                    About Us
                  </NavLink>
                  <NavLink to="/user" className={`nav-link ${selectedMenu === '/user' ? 'text-[#0076CD]' : 'hover:text-blue-300'}`}>
                    YOVI User
                  </NavLink>
                  <NavLink to="/event" className={`nav-link ${selectedMenu === '/event' ? 'text-[#0076CD]' : 'hover:text-blue-300'}`}>
                    YOVI Event Organizer
                  </NavLink>
                  <NavLink to="/business" className={`nav-link ${selectedMenu === '/business' ? 'text-[#0076CD]' : 'hover:text-blue-300'}`}>
                    YOVI For Business
                  </NavLink>
                  <NavLink to="/support" className={`nav-link ${selectedMenu === '/support' ? 'text-[#0076CD]' : 'hover:text-blue-300'}`}>
                    Support
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden relative z-20">
              <button
                onClick={toggleMenu}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
                aria-label="Main menu"
                aria-expanded={isOpen ? 'true' : 'false'}
                ref={buttonRef}
              >
                <svg width="24px" height="40px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5 7H19" stroke="#ffffff" stroke-width="1.8240000000000003" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5 12L19 12" stroke="#ffffff" stroke-width="1.8240000000000003" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5 17L19 17" stroke="#ffffff" stroke-width="1.8240000000000003" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              </button>
            </div>
          </div>
        </div>
        {/* <div className={`${isOpen ? 'block fixed inset-0 bg-black opacity-50 z-40' : 'hidden'}`} onClick={toggleMenu}></div> */}
      </nav >
      <Portal parent="portal">
        <div style={{ backgroundColor: "#003761", opacity: "1" }} ref={menuRef} className={`${isOpen ? 'block w-[100%] absolute   top-[60px] right-0  bg-gray-800 z-[10000]' : 'hidden'} md:hidden`} >
          <div className="px-2 pt-2 pb-3 sm:px-3 bg-[]">
            {token ? <NavLink
              to="/my-tickets"
              className={`block  px-3 py-4 rounded-md text-base font-medium hover:bg-gray-700 ${selectedMenu === '/my-tickets' ? 'text-[#0076CD]' : 'text-white hover:text-blue-300'}`}
              onClick={toggleMenu}
            >
              My Tickets
            </NavLink>
              : <NavLink
                to=""
                className={`block  px-3 py-4 rounded-md text-base font-medium hover:bg-gray-700 ${selectedMenu === '/login' ? 'text-[#0076CD]' : 'text-white hover:text-blue-300'}`}
              // onClick={toggleMenu}
              >
                <span onClick={() => Navigate("/login")}>  Login </span> / <span className='cursor-pointer' onClick={() => Navigate("/sign-up")}> SignUp</span>
              </NavLink>}
            {/* <NavLink
              to="/login"
              className="block px-3 py-2 rounded-md text-base font-medium text-white  hover:bg-gray-700"
              onClick={toggleMenu}
            >
              Register
            </NavLink> */}
            <NavLink
              to="/aboutus"
              className={`block px-3 py-4 rounded-md text-base font-medium hover:bg-gray-700 ${selectedMenu === '/aboutus' ? 'text-[#0076CD]' : 'text-white hover:text-blue-300'}`}
              onClick={toggleMenu}
            >
              About Us
            </NavLink>
            <NavLink
              to="/user"
              className={`mt-1 block px-3 py-4 rounded-md text-base font-medium hover:bg-gray-700 ${selectedMenu === '/user' ? 'text-[#0076CD]' : 'text-white hover:text-blue-300'}`}
              onClick={toggleMenu}
            >
              YOVI User
            </NavLink>
            <NavLink
              to="/event"
              className={`mt-1 block px-3 py-4 rounded-md text-base font-medium hover:bg-gray-700 ${selectedMenu === '/event' ? 'text-[#0076CD]' : 'text-white hover:text-blue-300'}`}
              onClick={toggleMenu}
            >
              YOVI Event Organizer
            </NavLink>
            <NavLink
              to="/business"
              className={`mt-1 block px-3 py-4 rounded-md text-base font-medium hover:bg-gray-700 ${selectedMenu === '/business' ? 'text-[#0076CD]' : 'text-white hover:text-blue-300'}`}
              onClick={toggleMenu}
            >
              YOVI For Business
            </NavLink>
            <NavLink
              to="/support"
              className={`mt-1 block px-3 py-4 pb-4 rounded-md text-base font-medium hover:bg-gray-700 ${selectedMenu === '/support' ? 'text-[#0076CD]' : 'text-white hover:text-blue-300'}`}
              onClick={toggleMenu}
            >
              Support
            </NavLink>
          </div>
        </div>
      </Portal>
    </>)
};

export default Navbar;
