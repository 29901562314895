import axios from 'axios';
// define all api call method function
export const getReq=(url:string)=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
        'AccessToken':localStorage.getItem('token'),
       // 'Access-Control-Allow-Origin':'*'
    }
   return  axios.get(url,{headers})
}
export const getReqWithT=(url:string)=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
        'AccessToken':localStorage.getItem('token') || '9Wf7IKPSBBum9PeGwEhEl69rC0VpeF0u4OutWOsmjdV8Xt6UC4nodK3Zlhej9sz0',
    }
   return  axios.get(url,{headers})
}

export const postReq=(url:string,data:any)=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
    }
   return  axios.post(url,data,{headers})
}
export const postReqJson=(url:string,data:any)=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
        'AccessToken':localStorage.getItem('token'),
        'Content-Type':'application/json',
    }
   return  axios.post(url,data,{headers})
}
export const postReqParam=(url:string,data:any)=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
        'AccessToken':localStorage.getItem('token'),
        'Content-Type':'multipart/form-data'
    }
   return  axios.post(url,data,{headers})
}
export const deleteReq=(url:string)=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
        'AccessToken':localStorage.getItem('token')
    }
   return  axios.delete(url,{headers})
}
export const postReqwithForm=(url:string,data:any)=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
        'AccessToken':localStorage.getItem('token'),
        'Content-Type':'application/json',
    }
   return  axios.post(url,data,{headers})
}

export const patchReq=(url:string,data={})=>{
    let headers={
        'Authorization':'Basic ZGluZzpkaW5nQDEyMw==',
        'AccessToken':localStorage.getItem('token'),
       // 'Access-Control-Allow-Origin':'*'
    }
   return  axios.patch(url,data,{headers})
}