//import Footer from "../../components/Footer";
import Header from "../../components/Header";
import aboutBanner from "../../assests/images/Top Photo for new tabs.png"
import AboutUs from "../../components/AboutUs";
const About = () => {
  return (
    <div className="w-full">
      <Header banner={aboutBanner} name="It’s all about finding and controlling “your vibe” on YOVI." header="About Us" />
      <AboutUs />
      {/*   <Footer/> */}
    </div>
  );
};

export default About;

