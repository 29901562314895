// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Keyboard, Navigation, Pagination, Scrollbar } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AboutMobile from "../assests/images/aboutScreen.png";
import ourMission from "../assests/images/ourMission.png";
import webKitImg from "../assests/images/webkitimg.png";
import Image1 from "../assests/images/Pass The Aux Photo File.png";
import Image2 from "../assests/images/Image2.png";
import Image3 from "../assests/images/Image3.png";
import Image4 from "../assests/images/Image4.png";
import Sophie from "../assests/images/Sophie.png";
import Matt from "../assests/images/Matt.png";
import John from "../assests/images/John.png";
import James from "../assests/images/James.png";
import BlueBg from "../assests/images/blue-bg.png";
import VideoThumbnails from "../assests/images/VideoThumbnails.png";
import Footer from "./Footer";

const AboutUs = () => {
  var settings = {

    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth < 768 ? 1 : window.innerWidth < 992 ? 2 : window.innerWidth < 1500 ? 3 : 4,
    // slidesToScroll: 1,

  };

  return (
    <div className="mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-12 mx-6 lg:mx-16 gap-4 mt-3 md:mt-10">
        <div className="col-span-1 md:col-span-7 my-auto">
          <div className="flex-1 flex flex-col  justify-center p-3">
            <div className="leading-7 text-sm flex flex-col my-auto justify-center align-middle md:text-lg  ">
              <p className="mb-2 ">
                YOVI is your new go-to platform for all social outings and
                experiences. YOVI serves three main groups; those groups include
                social businesses such as bars, lounges & clubs, event
                organizers (people or organizations) that host ticketed events
                and individuals who like to attend social venues and events.
              </p>
              <p className="mb-2 ">
                For social businesses and event organizers, YOVI provides a
                simple way for businesses to market their business or event
                directly to current and potential customers. Participating
                businesses receive demographic and behavioral data on their
                customers allowing businesses to get an inside look at their
                customer's experience. Businesses and event organizers also have
                the ability to directly sell and monitor ticket sales through
                YOVI.
              </p>
              <p>
                For individual users YOVI is a one stop platform for people
                seeking social experiences through social businesses and events.
                Users can browse participating businesses and events to receive
                real-time data about their favorite venues & events. In addition
                YOVI users have the opportunity within the platform to connect
                with other users who attend the same or similar social venues &
                events as them.
              </p>
            </div>
          </div >
        </div >

        <div className="col-span-1 md:col-span-5">
          <div className="flex-1 flex items-center justify-center w-60 h-auto lg:h-[505px] mx-auto p-3 mx-auto">
            <img src={AboutMobile} alt="about" className="md:h-[505px] lg:h-[480px]" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:mt-10 w-full bg-[#EAF3F9] p-16">
        <div className="col-span-1 my-auto">
          <div className="flex items-center ">
            <div className=" rounded-lg flex items-center  mx-auto md:mx-3 w-[350px] md:w-[488px] h-[250px] md:h-[310px]">
              <img src={ourMission} alt="yovi-icon" className="w-full  " />
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="w-full my-auto md:py-28  py-10 ">
            <h3 className="w-full text-2xl md:text-[32px] mb-4 text-center md:text-left font-medium">Our Mission</h3>
            <div className="w-full">
              <p className="text-sm sm:text-lg text-center  md:text-left">
                The goal of YOVI is to support local economies by enhancing
                business discovery and improving individual social experiences.
                Our goal for YOVI is to provide a platform that makes life
                easier for event organizers, that enhances their events while
                allowing users to more accurately find the events and venues
                that fit their desired vibes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-16">
        <div
          className="w-full h-[350px] md:h-[459px] md:mt-10 sm:px-[72px]"

        >
          <div className="flex   h-[280px] md:h-[369px] rounded-2xl  lg:relative md:px-[4%] about-us-freeTextArea"
            style={{
              backgroundImage: `url(${BlueBg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >

            <div className="p-4 sm:py-20 2xl:px-20 mx-auto my-auto md:m-0 font-medium">
              <h3 className="font-medium font-sans text-2xl md:text-4xl text-white pb-4 md:text-[32px]">
                YOVI  is a completely <span className="bg-gray-100 text-gray-800  font-semibold me-2 px-3 py-0 rounded dark:bg-gray-700 dark:text-gray-300">FREE</span> product to use
              </h3>
              <p className="font-medium text-xs md:text-lg text-[#FFFFFF] pb-2">
                Our standard YOVI fee for customers buying tickets is $1.10 + 3.6%
              </p>
              {/* <p className="w-32 text-center md:text-lg px-3 py-3 bg-white rounded-lg mt-2 font-medium text-md text-[#0076CD]">
                Get started
              </p> */}
            </div >
            <img
              src={webKitImg}
              alt="webkit-img"
              className="hidden xl:block xl:absolute bottom-0 right-0 w-auto h-[469px] -mt-16"
            />
          </div >
        </div >
      </div >
      <div className="w-full px-16 mb-20">
        <div className="w-full text-center">
          <h3 className="w-full pb-6 font-medium text-xl text-center md:text-3xl">
            Our Clients
          </h3>
          {/* <p className="px-30 text-sm mx-auto md:md:text-lg md:w-[80%] pb-10 md:px-20">
            Lorem ipsum dolor sit amet consectetur. Sollicitudin urna eu libero
            est suspendisse diam ante nunc. Sed rutrum sed sit semper. Neque
            nibh purus risus lectus. Rhoncus
          </p> */}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-2">
          <img src={Image3} alt="" className="mx-auto" />
          <img src={Image4} alt="" className="mx-auto" />
          <img src={Image2} alt="" className="mx-auto" />
          <img src={Image1} alt="" className="mx-auto" />
        </div >
      </div >
      <div className="grid grid-cols-1 gap-4 md:mt-10 w-full bg-[#EAF3F9] py-5">
        <div className="col-span-1">
          <div className="w-full h-[110px] text-center mt-6">
            <h3 className="w-full h-[38px] font-medium text-xl md:text-3xl text-center mb-4">
              Testimonials
            </h3 >
            {/* <p className="px-30 text-sm md:text-lg mx-auto md:w-[80%] md:px-20 mb-4">
              Lorem ipsum dolor sit amet consectetur. Sollicitudin urna eu
              libero est suspendisse diam ante nunc. Sed rutrum sed sit semper.
              Neque nibh purus risus lectus. Rhoncus
            </p> */}
          </div >
          <div className="w-full md:pt-0 pb-10 ">
            <Slider className=" md:grid sm:grid-cols-3 md:grid-cols-5 gap-x-4 md:gap-x-8 gap-y-4" {...settings}>
              <div id="Muse" className="relative !w-auto ">
                <div className="mx-8  w-80 md:w-96 border-2 p-4 rounded-lg border-[#D4D2E3] h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={Matt} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl">Matt Cannon</h3>
                  </div>
                  <p className="text-lg">
                    "YOVI is the next big platform. I save so much time and money
                    by seeing the vibes in real time"
                  </p>
                </div>
              </div>
              <div id="Nashvile" className="relative !w-auto">
                <div className="mx-8 w-80  border-2 md:w-96 p-4 rounded-lg border-[#D4D2E3]	h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={Sophie} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl		">Sophie Moore</h3>
                  </div>
                  <p className="text-lg">
                    "I have used YOVI for my past three events and I feel like I
                    actually know my customers before they come"
                  </p>
                </div>
              </div>
              <div id="Alphas" className="relative !w-auto">

                <div className="mx-8 md:w-96  w-80 border-2 p-4 rounded-lg border-[#D4D2E3]	h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={John} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl		">John Carter</h3>
                  </div>
                  <p className="text-lg">
                    "I've been using YOVI for about 3 months now and have met
                    some pretty cool new friends through the platform"
                  </p>
                </div></div>
              <div id="Palate" className="relative !w-auto">
                <div className="mx-8 relative   w-80 md:w-96 border-2 p-4 rounded-lg border-[#D4D2E3]	h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={James} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl		">James C</h3>
                  </div>
                  <p className="text-lg">
                    "This platform has helped to elevate my events. I have had
                    record attendances for my events since switching to YOVI"
                  </p>
                </div></div>
            </Slider>


            {/* 
            <Swiper
              slidesPerView={window.innerWidth < 768 ? 1 : "auto"}
              grabCursor={true}
              // navigation={true}

              modules={[Navigation, Autoplay]}
              spaceBetween={window.innerWidth < 768 ? 50 : 20}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,

              }}
              speed={5000}
              loop={true}
              centeredSlides={window.innerWidth < 768 ? true : false}


              className="mySwiper  md:grid sm:grid-cols-3 md:grid-cols-5 gap-x-4 md:gap-x-8 gap-y-4 "
            >
              <SwiperSlide id="Muse" className="relative !w-auto ">
                <div className="mx-8 ml-16 w-80 md:w-96 border-2 p-4 rounded-lg border-[#D4D2E3]	h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={Matt} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl	">Matt Cannon</h3>
                  </div>
                  <p className="text-lg">
                    "YOVI is the next big platform. I save so much time and money
                    by seeing the vibes in real time"
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide id="Nashvile" className="relative !w-auto">
                <div className="mx-8 w-80 md:mx-0 border-2 p-4 rounded-lg border-[#D4D2E3]	h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={Sophie} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl		">Sophie Moore</h3>
                  </div>
                  <p className="text-lg">
                    "I have used YOVI for my past three events and I feel like I
                    actually know my customers before they come"
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide id="Alphas" className="relative !w-auto">
                <div className="mx-8  md:mx-0 w-80 border-2 p-4 rounded-lg border-[#D4D2E3]	h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={John} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl		">John Carter</h3>
                  </div>
                  <p className="text-lg">
                    "I've been using YOVI for about 3 months now and have met
                    some pretty cool new friends through the platform"
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide id="Palate" className="relative !w-auto">
                <div className="ml-8 md:mx-0 w-80 border-2 p-4 rounded-lg border-[#D4D2E3]	h-60 bg-white">
                  <div className="flex  mb-6">
                    <img src={James} alt="" className="w-12 h-12 mr-3" />
                    <h3 className="mb-4 font-semibold text-xl		">James C</h3>
                  </div>
                  <p className="text-lg">
                    "This platform has helped to elevate my events. I have had
                    record attendances for my events since switching to YOVI"
                  </p>
                </div>
              </SwiperSlide>
            </Swiper> */}
          </div>
        </div >
      </div >
      {/* <div>
        <img src={VideoThumbnails} alt="" className="w-[85%] lg:w-[60%]  mx-auto py-10 p-5 lg:p-10" />
      </div> */}
      < Footer />
    </div >
  );
};

export default AboutUs;
