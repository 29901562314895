import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import { useSearchParams } from 'react-router-dom';
import { apiConstant } from '../../contants/apiContants'
import { getReq, patchReq } from '../../services/apiCall'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../components/Loader';
import LazyLoad from 'react-lazy-load';
import { CalenderIcon, LocationIcon } from '../../assests/icon/customIcon';
import Header from '../../components/Header';
import TicketPayment from '../../components/TicketPayment';
import { convertDateStringforEventPage, convertTimeStampToDateTime } from '../../utils/commonFunction';
import PopupForm from '../../components/PopupForm';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../store/features/user/userSelectors';
import Comment from './Comment';
import { clearComments, fetchCommentSuccess } from '../../store/features/comment/commentSlice'
import IosLink from '../user-profile/IosLink';
import Footer from '../../components/Footer';

function EventDetails() {
    const { eventId } = useParams()
    const [eventID, setEventId] = useState('')
    const [token, setToken] = useState('')
    const userData = useSelector(getUserData).data
    const [eventDetails, setEventDetails] = useState({} as any)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [isAtTop, setisAtTop] = useState(false)
    const [editInfoPopup, setEditInfoPopUp] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const live = localStorage.getItem('activeTab')
    const [searchParams, setSearchParams] = useSearchParams();
    const aff = searchParams.get('aff')
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch()
    useEffect(() => {
        let id = eventId
        let t = localStorage.getItem('token')
        if (id) {
            setEventId(id)
        }
        if (t) {
            setToken(t)
        }
    }, [userData])
    useEffect(() => {
        if (eventID) {
            fetchEventDetails()
        }
        return () => {
            dispatch(clearComments())
        }
    }, [eventID, token])

    useEffect(() => {
        if (aff && eventID) {
            searchParams.delete('eventid')
            // let promoArr = []
            // const isPromoSaved = Cookies.get('affs' + eventID)
            const isPromoExist = Cookies.get('aff' + eventID)
            const cookieExpiration = new Date(eventDetails.endDateTimeStamp)
            Cookies.set('aff' + eventID, aff, { expires: cookieExpiration })
            // searchParams.delete('aff');
            setSearchParams(searchParams);
            // if (isPromoSaved) {
            //     promoArr = JSON.parse(isPromoSaved)
            //     if (aff === Cookies.get('aff') || promoArr.includes(aff)) return
            // }
            if (isPromoExist === aff) return
            incrementPromoView(aff)
            // promoArr.push(aff)
            // Cookies.set('affs' + eventID, JSON.stringify(promoArr), { expires: 1 })
        };
    }, [aff, eventID, eventDetails])

    const incrementPromoView = (promo: string) => {
        const url = `${apiConstant.BASE_URL}/link/incrementviewcount?eventId=${eventID}&affname=${promo}`
        patchReq(url)
            .then(async (res) => {
                setLoading(false)
                if (res?.data?.responseData.success) {
                }
                else
                    if (res.data?.responseData.response == null) {
                        Cookies.remove('aff' + eventID)
                        // const isPromoSaved = Cookies.get('aff' + eventID)
                        // if (isPromoSaved) {
                        //     const promoArr: string[] = await JSON.parse(isPromoSaved) || []
                        //     const findPromo = promoArr.indexOf(res?.data?.responseData?.promo)
                        //     if (findPromo) promoArr.splice(findPromo, 1)
                        //     Cookies.set('aff' + eventID, JSON.stringify(promoArr), { expires: 3 })
                        // }
                    }
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                // toast.error('Ack, sorry! We’re having a server issue. Try again later')
            })
    }

    const calculateDay = (timestamp: any) => {
        if (timestamp) {
            const date1 = new Date(timestamp) as any;
            const date2 = new Date() as any;

            const differenceInMilliseconds = date1 - date2;
            const differenceInDays = Math.floor(differenceInMilliseconds / 86400000);
            if (differenceInDays > 0) {
                return differenceInDays
            }
            else {
                return 0
            }
        }
    }

    const fetchEventDetails = () => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/event/getEventDetailsForAdmin?eventId=${eventID}&limit=10`
        getReq(url)
            .then((res) => {
                setLoading(false)
                if (res?.data?.statusCode) {
                    let data = res?.data?.responseData?.response
                    if (data) {
                        setEventDetails(data)
                        dispatch(fetchCommentSuccess(data.comments))
                    }
                    // if (res?.data.responseData?.promoDetail?.promoDisabled) {
                    //     toast.warn("The Link is currently unavailable. ")
                    // }
                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg);
                }

            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                toast.error('Ack, sorry! We’re having a server issue. Try again later')
            })
    }
    useEffect(() => {
        const isUserAnsweredPrompts = typeof userData?.promptedQues == 'object' && Object.values(userData?.promptedQues).filter((questions: any) => questions?.answer && questions?.answer !== "").length >= 2
        const userHasallAdditionalInfo = Boolean(userData?.city && userData?.state && userData?.gender && userData?.ethnicity && userData?.country && isUserAnsweredPrompts)
        const editInfoPopup = Boolean(eventDetails.requireAdditionalInfo && !userHasallAdditionalInfo)
        setEditInfoPopUp(editInfoPopup)
    }, [userData, eventDetails])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let animationFrameId: any;
        if (window.innerWidth < 778) return setisAtTop(false)
        const scrollHandler = () => {
            animationFrameId = requestAnimationFrame(() => {
                window.scrollY > 500
                    ? setisAtTop(true) : setisAtTop(false);
            });
        };
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
            cancelAnimationFrame(animationFrameId);

        };
    }, [windowWidth])
    useEffect(() => {
        if (ref.current) {
            ref.current?.classList.add('opacity-0')
            setTimeout(() => {
                ref.current?.classList.remove('opacity-0')
            }, 300)
        }
    }, [isAtTop])
    return (
        <div>
            {
                loading ?
                    <Loader />
                    :
                    <div className='relative'>
                        <Header bg={eventDetails?.eventImage} name={!null} live={live} />
                        <div className='w-full flex justify-center absolute z-0'>
                            <div className='w-full md:w-[90%] bg-gray-50 p-4 md:p-6 border rounded-2xl shadow-md' style={{ marginTop: '-4%' }}>
                                <p className='px-4 text-xl md:text-3xl py-4 w-full font-semibold md:w-[60%] text-ellipsis'>{eventDetails?.eventName}</p>
                                <div className='w-full flex  flex-col md:flex-row  justify-center md:justify-between pr-0 px-4 gap-4 md:gap-0'>
                                    <div className='w-full md:w-[70%]'>
                                        <div className='w-full'>
                                            <div className=' text-gray-500 pb-4 pr-5 md:pr-10 '>
                                                {eventDetails?.description}
                                            </div>
                                            <div className=' my-4 pr-5 md:pr-10 max-h-96 md:max-h-[450px]  overflow-hidden'>
                                                <p className='font-medium text-xl  text-[#333333] pb-2'> Event Banner</p>
                                                <img src={eventDetails?.eventImage} alt='event' className='w-full min-w-full  min-h-[200px] object-cover rounded-md' />

                                            </div>



                                            <div className='md:flex border-b-2  mb-4 '>
                                                <div className='flex-1 py-2 md:pr-[5%] md:pt-0 md:pb-4'>
                                                    <p className='pb-4 text-xl md:text-xl font-semibold'> Details</p>
                                                    <div className='flex items-start gap-4 pb-6'>
                                                        <CalenderIcon />
                                                        <div className='text-gray-500'>
                                                            <p> {convertDateStringforEventPage(eventDetails?.startDate, eventDetails.startTime)} - {convertDateStringforEventPage(eventDetails.endDate, eventDetails.endTime)}  </p>
                                                            <p className='text-gray-500'>  In {calculateDay(eventDetails?.endDateTimeStamp)} Days</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-start gap-4 pb-6'>
                                                        <LocationIcon />
                                                        <div className=''>
                                                            <p> Location Event Address </p>
                                                            <p className='text-gray-500'>{eventDetails?.address}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-start gap-4 pb-6'>
                                                        <LocationIcon />
                                                        <div className=''>
                                                            <p>Location Event Title Address  </p>
                                                            <p className='text-gray-500'>{eventDetails?.eventLocationTitle}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='border-l-0 md:mx-auto md:pl-[5%]  md:px-auto md:border-l-2 flex-1'>
                                                    <div className=''>
                                                        <p className='pb-2 text-xl md:text-xl font-semibold'> Additional Notes</p>
                                                        <p className='text-gray-500'>
                                                            {eventDetails?.additionalNote}
                                                        </p>
                                                    </div>

                                                    <div className='w-full py-2 flex flex-wrap gap-6 md:block'>
                                                        <div className='md:flex'>
                                                            <div className='flex gap-6 flex-wrap'>
                                                                {
                                                                    eventDetails?.hostedBy?.map((item: any, i: any) => (
                                                                        <div key={i} className='flex flex-col md:flex-row items-center'>
                                                                            <div className='h-[50px] w-[50px] border rounded-full flex justify-center items-center bg-white'>
                                                                                <LazyLoad threshold={0.95}>
                                                                                    <img src={item?.thumbUrl} alt='dp' className='h-[50px] w-[50px] rounded-full' />
                                                                                </LazyLoad>
                                                                            </div>
                                                                            <div className='ml-4'>
                                                                                <p className='pb-2 md:pb-0 text-xl md:text-base text-gray-500 '>Hosted by</p>
                                                                                <p className='font-semibold'>{item?.hostName}</p>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        {eventDetails?.djBy?.length ? <div className='pl-4 md:pl-0 md:pt-4'>
                                                            <div className='md:flex'>

                                                                <div className='flex gap-6 flex-wrap'>
                                                                    {
                                                                        eventDetails?.djBy?.map((item: any, i: any) => (
                                                                            <div className='flex flex-col md:flex-row items-center'>
                                                                                <div className='flex justify-center items-center rounded-full'>
                                                                                    <LazyLoad threshold={0.95}>
                                                                                        <img src={item?.thumbUrl} alt='dp' className='h-[50px] w-[50px] rounded-full' />
                                                                                    </LazyLoad>
                                                                                </div>
                                                                                <div className='ml-4'>
                                                                                    <p className='pb-2 text-xl md:text-base text-gray-500'>DJ</p>
                                                                                    <p className='font-semibold'>{item?.djName} </p> </div>
                                                                            </div>
                                                                        ))

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div> : ""}
                                                    </div>
                                                    {eventDetails?.layout ? <div className='my-4 w-full'>
                                                        <p className='pb-2 text-xl md:text-2xl'>Layout/Photo</p>
                                                        {
                                                            eventDetails?.layout &&
                                                            <div className='w-[335px] h-[140px] w-auto md:h-auto  overflow-hidden ' >
                                                                <LazyLoad threshold={0.95}>
                                                                    <img src={eventDetails?.layout} alt='layout' className=' rounded-md h-52 w-52' />
                                                                </LazyLoad>
                                                            </div>
                                                        }

                                                    </div> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='hidden md:block'>
                                            <Comment isAtTop={isAtTop} userImage={userData?.profileCoverPhotos[0]?.url} eventId={eventID} />
                                        </div>
                                    </div>
                                    <div className='w-full md:w-[30%] border-l-0  md:border-l-2 mb-0 pt-[40px]'>
                                        <div ref={ref} id='ticketPriceBar' className={` pl-0 md:pl-12 transition-all duration-100 ease-in-out  ${isAtTop ? 'w:[100%] left:[67.4%] fixed md:top-16 mt-2 ' : 'md:w-[100]'}`}>
                                            <TicketPayment
                                                eventDetails={eventDetails}
                                                token={token}
                                                eventID={eventID}
                                                openPopup={() => setOpen(true)}
                                                editInfoPopup={editInfoPopup}
                                            />
                                        </div>
                                    </div>
                                    <div className='md:hidden'>
                                        <Comment userImage={userData?.profileCoverPhotos[0]?.url} eventId={eventID} />
                                    </div>
                                </div>
                                {/* <div className="fixed bottom-0 left-0  w-full z-50 shadow-xl px-2 py-1"> */}
                                <IosLink buttonText="Continue" urlType="ticketedEvents" />
                                {/* </div> */}
                            </div>
                        </div>
                        {open ? <PopupForm userData={userData} closePopup={() => setOpen(false)} /> : null}
                    </div>
            }
            <ToastContainer />
        </div>
    )
}

export default EventDetails