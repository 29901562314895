import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice'
import eventSlice from './features/event/eventSlice'
import commentSlice from './features/comment/commentSlice';
import rating from './features/ios/appmeta';
export const store = configureStore({
  reducer: {
    user:userSlice,
    event:eventSlice,
    comment:commentSlice,
    rating
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;