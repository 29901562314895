import React, { useEffect } from 'react';
import DialogBox, { DialogBoxProps } from './DialogBox';

interface ConfirmDialog extends DialogBoxProps {
    title: string,
    text: string,
    action: () => void,
    actionName: string
}
function ConfirmDialogBox({ title, isOpen, onClose, action, text, actionName }: ConfirmDialog) {

    useEffect(() => {
        if (document !== undefined && isOpen) {
            document.body.style.setProperty("overflow", "hidden")
        }
        return () => {
            document.body.style.removeProperty("overflow")
        }
    }, [isOpen])
    return (
        <DialogBox isOpen={isOpen} overrideClass='!p-3 !w-[auto]' onClose={() => { }} >
            <main className="antialiased  text-gray-900 font-sans overflow-x-hidden">
                <div className="relative px-4  md:flex md:items-center md:justify-center">
                    <div className=" opacity-25 w-auto h-auto absolute z-10 inset-0"></div>
                    <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-[10%] z-50 mb-4 mx-4 md:relative">
                        <div className=" border-gray-300 flex items-center justify-center w-20 h-20 flex-shrink-0 mx-auto">
                            <svg width="60px" height="60px" viewBox="-10.56 -10.56 45.12 45.12" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)"><rect x="-10.56" y="-10.56" width="45.12" height="45.12" rx="22.56" fill="#ffcccc" strokeWidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.048"></g><g id="SVGRepo_iconCarrier"> <g clip-path="url(#clip0_949_22806)"> <path d="M12 6.5C12.5523 6.5 13 6.94772 13 7.5L13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5L11 7.5C11 6.94772 11.4477 6.5 12 6.5Z" fill="#ff0000"></path> <path d="M12 18.5C12.8284 18.5 13.5 17.8284 13.5 17C13.5 16.1716 12.8284 15.5 12 15.5C11.1716 15.5 10.5 16.1716 10.5 17C10.5 17.8284 11.1716 18.5 12 18.5Z" fill="#ff0000"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M9.82664 2.22902C10.7938 0.590326 13.2063 0.590325 14.1735 2.22902L23.6599 18.3024C24.6578 19.9933 23.3638 22 21.4865 22H2.51362C0.63634 22 -0.657696 19.9933 0.340215 18.3024L9.82664 2.22902ZM12.4511 3.24557C12.2578 2.91814 11.7423 2.91814 11.549 3.24557L2.06261 19.319C1.90904 19.5792 2.07002 20 2.51362 20H21.4865C21.9301 20 22.0911 19.5792 21.9375 19.319L12.4511 3.24557Z" fill="#ff0000"></path> </g> <defs> <clipPath id="clip0_949_22806"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg>
                        </div>
                        <div className="md:flex items-center">
                            <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                                <p className="font-bold text-2xl mb-6 text-center">{title}</p>
                                <p className="text-base text-gray-500 mt-1 mb-6">{text}</p>
                            </div>
                        </div>
                        <div className=" w-full flex justify-center flex-col">
                            <button onClick={action} className="block  px-4 py-2 text-base capitalize text-red-500 hover:bg-red-600 hover:text-white font-semibold rounded-lg border-2 mb-2">
                                {actionName}
                            </button>

                            <button onClick={onClose} className="block px-4 py-2 text-base capitalize text-gray-700 rounded-lg border-2 font-semibold">
                                No
                            </button>
                        </div>
                        {/* <div className="text-center md:text-right mt-4 md:flex justify-end ml-4  w-[95%]">
                            <button className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2" onClick={action}>{actionName}</button>
                            <button className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1" onClick={onClose}>Cancel</button>
                        </div> */}
                    </div>
                </div>
            </main>
        </DialogBox>

    );
}

export default ConfirmDialogBox;
