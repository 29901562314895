import React, { useEffect, useState } from 'react'
import { Card } from '../../components/TicketCard'
import { getReq } from '../../services/apiCall';
import { apiConstant } from '../../contants/apiContants';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Keyboard, Navigation, Pagination, Scrollbar } from 'swiper/modules';

const urlParams = new URLSearchParams(window.location.search);
const paymentId = urlParams.get('id');
const ticketId = urlParams.get('ticket')

console.log(paymentId, 'paymentId', ticketId, 'ticketId');


function SingleTicket() {

    const [data, setData] = useState([{}])

    const getTicketData = () => {
        let url;
        if (ticketId) {
            url = `${apiConstant.BASE_URL}/payment/getTicketDetailsbyOrder?ticketId=${ticketId}`;
        } else if (paymentId) {
            url = `${apiConstant.BASE_URL}/payment/getTicketDetailsbyOrder?paymentId=${paymentId}`;
        }
        if (url) {
            getReq(url)
                .then((res) => {
                    if (res?.data?.statusCode) {
                        console.log(res.data, 'res.data');

                        let dataResponse = res.data.responseData
                        console.log(dataResponse);
                        setData(dataResponse)
                        // let Data = [];

                        // for (let dataItem of dataResponse) {
                        //     const eventData = {
                        //         startDateTimeStamp: dataItem.eventId.startDateTimeStamp,
                        //         endDateTimeStamp: dataItem.eventId.endDateTimeStamp,
                        //         address: dataItem.eventId.address,
                        //         eventImage: dataItem.eventId.eventImage,
                        //         eventName: dataItem.eventId.eventName,
                        //     }
                        //     const item = {
                        //         ticketArray: dataItem.ticketId,
                        //         quantity: dataItem.quantity,
                        //         bookingId: dataItem.bookingId.toUpperCase()
                        //     }
                        //     if (dataItem.bookingId.length !== 10) {
                        //         return null
                        //     }

                        //     Data.push({ eventData, item });

                        // }

                        // setData(Data)

                    } else {
                        let msg = res?.data?.error?.responseMessage;
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                });
        } else {
            return (
                <div></div>
            )
        }
    }

    useEffect(() => {
        getTicketData();
    }, [])

    console.log(data, "dataaa")

    return (data ? (window.innerWidth > 786 ?
        <div className="grid md:grid-cols-2  xl:grid-cols-3 gap-4 items-center place-content-center h-full" >
            {
                data.map((item: any, index: number) => (
                    <Card eventData={item.eventData} item={item} single={true} key={index} />
                ))
            }
        </div >
        : <div className='m-auto lg:w-7/12'>
            <Swiper
                slidesPerView={'auto'}
                grabCursor={true}
                navigation={true}
                modules={[Navigation]}
                centeredSlides={true}
                loop={false}
                spaceBetween={20}
                className="mySwiper flex md:grid sm:grid-cols-1 gap-x-4 md:gap-x-8 gap-y-4 mt-16"
            >
                {data.map((item: any, index: number) => (
                    <SwiperSlide id={index + 'index'} >
                        <div className='flex justify-center items-center h-screen' key={index}>
                            <Card eventData={item.eventData} item={item} single={true} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper >
        </div>) :
        (<div>

        </div>)
    )

}

export default SingleTicket