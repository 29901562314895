import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import BasePhoneNumberInput from '../../components/BasePhoneNumberInput';
import { isValidNumber } from "../../utils/commonFunction";
import { apiConstant } from '../../contants/apiContants';
import { postReq, postReqParam } from '../../services/apiCall';
import LoginPopup from '../../components/LoginPopup';
import DialogBox from '../../components/DialogBox';
import DemographicForm from '../../components/DemographicForm';
import { propmtQ } from '../../contants/formConstants';
import { useDispatch } from 'react-redux';
import { fetchDataSuccess } from '../../store/features/user/userSlice';
import { setIsFromGuest } from '../../store/features/event/eventSlice';

function UserDetailsForm({ openOtp, setOpenOtp, otpbtn, currentStep, totalStep, setCurrentStep, firstStepBtn, secondStepBtn, selectedTicketIndex }: any) {
    const [errors, setErrors] = useState({
        name: "",
        number: "",
        email: "",
    });
    const [phone, setPhone] = useState({
        countryCode: "",
        mobile: "",
    });
    const [otp, setOtp] = useState('')
    const [isResend, setIsResend] = useState(false)
    const [isOtpSend, setIsOtpSend] = useState(false)
    const [time, setTime] = useState(0)
    const [loginPopup, setloginPopup] = useState(false);
    const dispatch = useDispatch()
    useMemo(() => {
        if (phone?.countryCode !== "1") {
            setTimeout(() => {
                setPhone({ ...phone, mobile: phone?.countryCode });
            }, 100);
        }
    }, [phone?.countryCode]);
    const handlePhone = (val: any, code: any) => {
        setPhone({ ...phone, countryCode: code?.dialCode, mobile: val });
        formik.setFieldValue('mobile', val)
        setErrors({ ...errors, number: '' })
        setIsOtpSend(false)
        // console.log(dateRange)
    };
    const checkNumberExist = (val: string) => {
        let dataParam = {
            userType: 1,
            // email: val,
            countryCode: phone?.countryCode,
            mobile: phone?.mobile.slice(phone?.countryCode.length),
        };
        if (!errors.number && phone.mobile) {
            checkMobileAndEmailExist(dataParam);
        }

    };
    const checkEmailExist = (val: string) => {
        console.log(val, errors.email, "email")
        let dataParam = {
            userType: 1,
            email: val,
            // countryCode: val? " ": phone?.countryCode,
            // mobile:val? "": phone?.mobile.slice(phone?.countryCode.length)
        };
        if (!errors.email && formik.values?.email) {
            checkMobileAndEmailExist(dataParam);
        }
    };


    const checkMobileAndEmailExist = (dat: any) => {
        let url = `${apiConstant.BASE_URL}/user/isEmailMobileExist`;
        postReq(url, dat)
            .then((res) => {
                // console.log('check email/mobile status:;', res)
                if (res?.data?.statusCode) {

                } else {
                    let msg = res?.data?.error?.responseMessage;
                    if (dat?.mobile) {
                        setErrors({
                            ...errors,
                            number: msg,
                        });
                        setloginPopup(true);
                    }

                    if (dat?.email) {
                        setErrors({
                            ...errors,
                            email: msg,
                        });
                    }
                }
            })
            .catch((err: any) => {
                console.log("err", err);
                setTimeout(() => {
                    toast.error(
                        "Ack, sorry! We’re having a server issue. Try again later", { toastId: "server_isse" }
                    );
                }, 1000);
            });
    };


    const UservalidationSchema = Yup.object({
        mobile: Yup.string()
            .required('Phone number is required')
            .min(10, 'Phone Number must be at least 10 characters')
            .test('isValidNumber', 'Phone number is invalid', (val) => {
                // Additional validation logic here
                val = val?.slice(phone?.countryCode?.length);
                return isValidNumber(val);
            }),
        email: Yup.string().email('Email is invalid').required('Email is required'),
        fname: Yup.string().required('First name is required').matches(/^[a-zA-Z]*$/, 'First name is Invalid'),
        TC: Yup.boolean().oneOf([true], 'Please accept terms and conditions'),
    })
    const DemographicvalidationSchema = Yup.object().shape({
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        gender: Yup.string().required('Gender is required'),
        ethnicity: Yup.string().required('Ethnicity is required'),
        country: Yup.string().required('Country is required'),
        dob: Yup.string().required('Dob is required'),
        question1: Yup.string().notRequired(),
        question2: Yup.string().notRequired(),
        question3: Yup.string().notRequired(),
        question4: Yup.string().notRequired(),
        question5: Yup.string().notRequired(),
        question6: Yup.string().notRequired(),
    }).test('question1', 'Please fill at least two answer of prompted questions', (obj: any) => {
        const questions = ['question1', 'question2', 'question3', 'question4', 'question5', 'question6'];
        let count = 0;
        for (let i = 0; i < questions.length; i++) {
            console.log(obj[questions[i]], "qustions of i")
            if (obj[questions[i]]) {
                count++;
            }
            console.log(count, "count")
            if (count >= 2) {
                return true;
            }
        }
        console.log(count, "count")
        throw new Yup.ValidationError('Please fill at least two answer of prompted questions', null, 'question1')
    })


    const initialValues = {
        mobile: '',
        email: '',
        fname: '',
        lname: '',
        TC: false,
        prompted: 0,
        ...(totalStep > 2 && {
            ethnicity: "",
            gender: "",
            city: "",
            state: "",
            country: "",
            dob: '',
            question1: "",
            question2: "",
            question3: "",
            question4: "",
            question5: "",
            question6: "",
        })
    };

    const handleValidation = (step: number) => {
        switch (step) {
            case 1:
                return UservalidationSchema
            case 2:
                return DemographicvalidationSchema
        }
    };


    const createUser = (data: any) => {
        const formData = new FormData();

        data.promptedQues = JSON.stringify(propmtQ.map((q: any, i: number) => {
            q.answer = data[`question${i + 1}`]
            return q
        }))
        let timestamp = ''
        if (data?.dob) {
            timestamp = new Date(data?.dob).getTime() as any
        }
        console.log(data?.dob, "dob", timestamp)

        formData.append("deviceId", '645dfdd')
        formData.append("deviceToken", '645dfdd')
        formData.append("platform", '3')
        formData.append("userType", '1')
        formData.append("socialType", '4')
        formData.append("firstName", data?.fname)
        formData.append("lastName", data?.lname)
        formData.append("countryCode", phone.countryCode)
        formData.append("mobile", phone.mobile?.slice(phone.countryCode?.length))
        formData.append("gender", data?.gender)
        timestamp && formData.append("dob", new Date(timestamp).getTime().toString())
        formData.append("city", data?.city)
        formData.append("state", data?.state)
        formData.append("country", data?.country)
        formData.append("promptedQues", data?.promptedQues)
        formData.append("ethnicity", data?.ethnicity)
        formData.append("email", data?.email)
        formData.append("showPrompted", Number(data?.prompted).toString())
        const url = `${apiConstant.BASE_URL}/user/guestRegistration`
        postReq(url, formData).then((res) => {
            if (res?.data?.statusCode && res?.data?.responseData?.accessToken) {
                let token = res?.data?.responseData?.accessToken
                localStorage.setItem('token', token);
                if (selectedTicketIndex !== -1 && selectedTicketIndex !== undefined) {
                    const existingQueryParams = new URLSearchParams(window.location.search);
                    existingQueryParams.set('selected', selectedTicketIndex.toString());
                    const newUrl = `${window.location.pathname}?${existingQueryParams.toString()}`;
                    window.history.replaceState(null, '', newUrl);
                    dispatch(setIsFromGuest(true))
                }

                dispatch(fetchDataSuccess(res?.data?.responseData?.userProfile));
                let msg = "Guest registration successful"
                // res?.data?.responseData?.message
                toast.success(msg, { toastId: 'guestReg' });
                formik.resetForm()
                setCurrentStep(3)
            }
            else {
                let msg = res?.data?.error?.responseMessage
                toast.error(msg, { toastId: 'guestReg' })
                formik.resetForm()
                setCurrentStep(1)
            }
        })

    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: handleValidation(currentStep),
        validate: (values) => {
            let errors: { [key: string]: string } = {}; // Provide type annotation for 'errors' object

            if (currentStep === 1) {
                // Validation logic for step 1 using UservalidationSchema
                const validationSchema: Yup.ObjectSchema<Yup.InferType<typeof UservalidationSchema>> = UservalidationSchema; // Use InferType utility type to reference UservalidationSchema as a type
                try {
                    validationSchema.validateSync(values, { abortEarly: false });
                } catch (validationErrors: Yup.ValidationError | any) { // Provide type annotation for 'validationErrors'
                    validationErrors.inner.forEach((error: Yup.ValidationError) => { // Provide type annotation for 'error'
                        errors[error.path as string] = error.message; // Fix type mismatch error by providing type annotation for 'errors' object
                    });
                }
            } else if (currentStep === 2) {
                // Validation logic for step 2 using DemographicvalidationSchema
                const validationSchema: Yup.ObjectSchema<Yup.InferType<typeof DemographicvalidationSchema>> = DemographicvalidationSchema; // Use InferType utility type to reference DemographicvalidationSchema as a type
                try {
                    validationSchema.validateSync(values, { abortEarly: false });
                } catch (validationErrors: Yup.ValidationError | any) { // Provide type annotation for 'validationErrors'
                    validationErrors.inner.forEach((error: Yup.ValidationError) => { // Provide type annotation for 'error'
                        errors[error.path as string] = error.message; // Fix type mismatch error by providing type annotation for 'errors' object
                    });
                }
            }

            return errors;
        },
        onSubmit: (values) => {
            // Handle form submission
            console.log('handle submit is running ')
            sendOtp({ countryCode: phone.countryCode, mobile: phone.mobile.slice(phone.countryCode?.length) }, false)
            setOpenOtp(true)
            // createUser(values)
        },
    });
    console.log(formik.touched, "formik.values")
    const checkdisabled = () => {
        const errorsEmpty = Object.values(errors).every((value) => value === "" || value === undefined);
        const { mobile, email, fname, TC } = formik.errors;
        const formikErrorsEmpty = !mobile && !email && !fname && !TC;
        return errorsEmpty && formikErrorsEmpty
    };
    console.log(formik.values, "formik.values")
    console.log(errors, "errors", formik.errors, formik.touched)
    console.log(totalStep, "totalStep", currentStep, openOtp)

    const sendOtp = (num: any, resend: boolean) => {
        // setLoading(true)
        if (isOtpSend && !resend) return
        let url = `${apiConstant.BASE_URL}/user/sendOtp`
        postReq(url, num)
            .then((res) => {
                setIsOtpSend(true)
                // console.log('res::;', res)
                // setLoading(false)
                if (res?.data?.statusCode) {
                    let msg = res?.data?.responseData?.message
                    if (resend) {
                        msg = 'Resend otp sent successfully'
                    }
                    toast.success(msg);
                    // setIsOTP(true)
                }

            })
            .catch((err) => {
                console.log('err', err)
                // setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }
    const verifyOtp = (otp: any) => {
        // setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/verifyOtp`
        postReq(url, otp)
            .then((res) => {
                // console.log('res:verify otp:;', res)
                if (res?.data?.statusCode && res?.data?.responseData?.accessToken) {
                    let token = res?.data?.responseData?.accessToken
                    localStorage.setItem('token', token)
                    // setLoading(false)
                    let msg = res?.data?.responseData?.message
                    toast.success(msg);
                    setOpenOtp(false)
                    // setOtp({
                    //     1: '',
                    //     2: '',
                    //     3: '',
                    //     4: ''
                    // })
                }
                else {
                    if (res?.data?.statusCode) {
                        // handleSignup()
                        createUser(formik.values)
                        setOpenOtp(false)
                    }
                    else {
                        // setLoading(false)
                        let msg = res?.data?.error?.responseMessage
                        toast.error('Incorrect OTP. Please try again');
                    }
                }
            })
            .catch((err: any) => {
                console.log('err', err)
                // setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 500)

            })
    }
    const handlResendOtp = () => {
        setIsResend(true)
        setTime(60)
        sendOtp({ countryCode: phone.countryCode, mobile: phone.mobile.slice(phone.countryCode.length) }, true)
    }
    useEffect(() => {
        let interval = null as any;
        if (time > 0 && openOtp) {
            interval = setInterval(() => {
                setTime(time - 1);
            }, 1000);
        }
        else {
            // setIsResend(false)
            setTime(0)
            clearInterval(interval)
        }
        return () => clearInterval(interval); // Clear the interval on unmount
    }, [time, isResend]);
    return (
        <div>



            {openOtp ?
                <div className='p-2  items-center'>
                    <p className='py-2 text-1xl md:text-2xl md:py-4'>  Enter your OTP </p>
                    <p className='text-gray-500 my-3'>
                        Enter the verification code that was sent to you at +{phone.mobile}
                    </p>
                    <input
                        type="tel"
                        className="bg-gray-100 outline-none px-4 py-2 w-full"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        maxLength={6} // Adjust the maxLength as per your OTP length
                        placeholder="Enter OTP"
                    />
                    {
                        (time > 0) ?
                            <p className='pt-6 text-1xl md:text-2xl text-blue-400 text-center'>00:{time} </p>
                            : null
                    }
                    <p className='text-gray-500 py-4 mt-4 flex flex-col content-center text-center'>
                        Didn't receive a code? <br />
                        <button
                            disabled={time > 0}
                            className={time === 0 ? 'text-blue-500 cursor-pointer font-bold hover:underline' : 'text-blue-300'}
                            onClick={handlResendOtp}> Resend a new one
                        </button>
                    </p>

                    <div className="mt-5 flex justify-between items-center gap-x-8">
                        <button type="button" onClick={() => {
                            //    setCurrentStep(currentStep - 1)
                            setOpenOtp(false)
                        }} className="py-2 px-4 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" data-hs-stepper-back-btn>
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                            Back
                        </button>
                        <button
                            className="text-white px-4 py-2 justify-center  w-full inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border bg-sky-600 hover:bg-sky-500  disabled:bg-gray-400"
                            onClick={() => {

                                verifyOtp({ userType: 1, otp: otp, type: 0, countryCode: phone?.countryCode, mobile: phone?.mobile.slice(phone?.countryCode.length) })
                            }} type='submit' > Verify</button>
                    </div>


                </div> :
                <form onSubmit={formik.handleSubmit}>
                    {currentStep === 1 && totalStep > 1 ? <div>
                        <p id="user-title" className="py-2 text-1xl md:text-2xl md:py-4">
                            User Details
                        </p>
                        <div className="max-w-full">
                            <p className="text-gray-500 pb-2">
                                Enter phone number <span className="text-red-500"> *</span>
                            </p>
                            <BasePhoneNumberInput
                                val={formik.values?.mobile}
                                setval={handlePhone}
                                err={() => formik.validateField('mobile')}
                                duplicate={(e: any) => {
                                    formik.setFieldTouched('mobile')
                                    checkNumberExist(e)
                                }}
                                className="!w-full"


                            />
                            {formik.errors.mobile && formik.errors.mobile && (
                                <span className="text-sm text-red-500">{formik.errors.mobile}</span>
                            )}
                            {
                                errors.number && <span className="text-sm text-red-500">phone number is already exist  <a onClick={() => setloginPopup(true)} className='text-gray-500 cursor-pointer hover:underline'>Please Login</a> </span>
                            }
                        </div>
                        <div className='py-2' >
                            <p className="text-gray-500 pb-1">
                                Enter Email <span className="text-red-500"> *</span>
                            </p>
                            <input
                                type="email"
                                name="email"
                                className="bg-gray-100 outline-none px-4 py-2 w-full"
                                placeholder="Enter email"
                                onChange={(e) => {
                                    setErrors({ ...errors, email: '' })
                                    formik.handleChange(e)
                                }}
                                onBlur={(e) => {
                                    formik.handleBlur(e);
                                    checkEmailExist(formik.values.email);
                                }}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <span className="text-sm text-red-500">{formik.errors.email}</span>
                            )}
                            {
                                errors.email && <span className="text-sm text-red-500">email is already exist  </span>
                                // <a onClick={() => setloginPopup(true)} className='text-gray-500 cursor-pointer hover:underline'>Please Login</a> 
                            }
                        </div>
                        <div className='py-2' >
                            <p className="text-gray-500 pb-1">
                                Enter First Name <span className="text-red-500"> *</span>
                            </p>
                            <input
                                type="text"
                                name="fname"
                                className="bg-gray-100 outline-none px-4 py-2 w-full"
                                placeholder="Enter first name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.fname}
                            />
                            {formik.touched.fname && formik.errors.fname && (
                                <span className="text-sm text-red-500">{formik.errors.fname}</span>
                            )}
                        </div>
                        <div className="py-2">
                            <p className="text-gray-500 pb-1"> Enter Last Name</p>
                            <input
                                type="text"
                                name="lname"
                                className="bg-gray-100 outline-none px-4 py-2 w-full"
                                placeholder="Enter last name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lname}
                            />
                        </div>
                        <div className='p-2 flex items-center'>
                            <input type='checkbox'
                                className='h-[20px] w-[20px]'
                                checked={formik.values.TC}
                                onChange={(e) => formik.setFieldValue('TC', e.target.checked)}
                            />
                            <p className='pl-4 text-gray-600 text-sm'>
                                <a className='cursor-pointer text-sky-600 hover:text-sky-500' href={apiConstant.TC} target='_blank' rel='noreferrer'>Terms and Conditions </a>
                                &amp; <a className='cursor-pointer text-sky-600 hover:text-sky-500 mr-2' href={apiConstant.PRIVACY} target='_blank' rel='noreferrer'>Privacy and Policy </a>
                                I Agree
                            </p>
                        </div>
                        {formik.touched.TC && formik.errors.TC && (
                            <span className="text-sm text-red-500">{formik.errors.TC}</span>
                        )}

                        {/* <div className="mt-5 flex justify-between items-center gap-x-2">
                    <button type="button" className="py-2 px-4 inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none" data-hs-stepper-back-btn>
                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                        Back
                    </button>
                    <button
                        className="text-white px-4 py-2  inline-flex items-center gap-x-1 text-sm font-medium rounded-lg border bg-sky-600 hover:bg-sky-500  disabled:bg-gray-400"
                        type="submit">Next</button>
                </div> */}
                    </div> :

                        currentStep === 2 && totalStep > 2 ? <DemographicForm setCurrentStep={setCurrentStep} secondStepBtn={secondStepBtn} formik={formik} /> : ''
                    }
                    <button hidden type='button' onClick={() => {
                        const touchedEmptly = Object.values(formik.touched).every((value) => value === false || value === undefined);
                        formik.setTouched({ ...formik.touched, mobile: true, email: true, fname: true, TC: true })
                        formik.validateField('mobile')
                        formik.validateField('fname')
                        formik.validateField('email')
                        formik.validateField('TC')
                        if (touchedEmptly) return
                        if (!checkdisabled()) return
                        if (totalStep === 2) {
                            formik.handleSubmit()
                            return
                            // sendOtp({ countryCode: phone.countryCode, mobile: phone.mobile }, false)
                        }
                        setCurrentStep(2)
                        formik.validateForm()
                    }
                    } ref={firstStepBtn} />
                </form>}

            <DialogBox isOpen={loginPopup} onClose={() => setloginPopup(false)} withClose>
                <LoginPopup
                    open={loginPopup}
                    setOpen={setloginPopup}
                    mobile={phone}
                    setPhone={setPhone}
                    setCurrentStep={setCurrentStep}
                    selectedTicketIndex={selectedTicketIndex}
                />
            </DialogBox>
        </div>
    );
};



export default UserDetailsForm