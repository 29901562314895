import React from 'react';
import EventList from '../../components/EventList';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import homeBanner from '../../assests/images/banner.png'

function Home() {
  return (
    <div className='w-full'>
      <Header banner={homeBanner} />
      <EventList />
      <Footer />
    </div>
  );
}

export default Home;
