import React, { ReactNode, useEffect } from 'react';
import { CloseIcon } from '../assests/icon/customIcon';

export interface DialogBoxProps {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  withClose?: boolean,
  overrideClass?: string,
  overrideMainClass?: string
}
const DialogBox: React.FC<DialogBoxProps> = ({ isOpen, children, onClose, withClose, overrideClass, overrideMainClass }) => {
  const overlayClasses = isOpen ? "fixed inset-0 bg-black opacity-75 z-40" : "hidden";
  const dialogClasses = isOpen ? "fixed inset-0 flex items-center justify-center z-50 " + overrideMainClass : "hidden";
  useEffect(() => {
    if (document !== undefined && isOpen) {
      document.body.style.setProperty("overflow", "hidden")
    }
    return () => {
      document.body.style.removeProperty("overflow")

    }
  }, [])

  return (
    <>
      <div className={overlayClasses} onClick={onClose}></div>
      <div className={dialogClasses}>
        <div className={`bg-white rounded-lg w-3/4 md:w-[400px] lg:w-[500px] p-4 md:p-8 ${overrideClass}`}>
          {withClose && <div className="flex justify-end mb-2" onClick={onClose}>
            <CloseIcon />
          </div>}
          <div className="w-full flex justify-center items-center">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
export default DialogBox;
