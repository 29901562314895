import React, { useRef, useState, useEffect } from 'react'
import { deleteReq, getReq, getReqWithT, postReqJson } from '../../services/apiCall';
import { apiConstant } from '../../contants/apiContants';
import { CloseIcon, UserAvatar, UserIcon } from '../../assests/icon/customIcon'
import CommentCard from '../../components/CommentCard'
import { useDispatch, useSelector } from 'react-redux';
import { getComments } from '../../store/features/comment/commentSelector';
import { addNewComment, addReply, addnewReply, deleteReply, deleteComment as removeComment } from '../../store/features/comment/commentSlice';
import { toast } from 'react-toastify';
import { getUserData } from '../../store/features/user/userSelectors';
import ConfirmDialog from '../../components/ConfirmDialog';
import moment from 'moment';
function Comment({ eventId }: any) {

    const commentInputRef = useRef<HTMLTextAreaElement>(null)
    const [commentInput, setComment] = useState('')
    const [optionOpen, setOption] = useState({ _id: "", open: false, parent: "" })
    const [commentLoad, setLoading] = useState(false)
    const [selectedtoReply, setSelectedReply] = useState({ id: "", name: "" })
    const [showCommentBox, setCommentBox] = useState(false)
    const [newReply, setReply] = useState({ _id: null, commentId: null })
    const comments = useSelector(getComments)
    const user = useSelector(getUserData)
    const dispatch = useDispatch()

    moment.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: (diff) => (diff === 'just now' ? diff : diff + " ago"),
            s: 'just now',
            ss: '%d S',
            m: "1 m",
            mm: "%d m",
            h: "1 hr",
            hh: "%d hrs",
            d: "1 day",
            dd: function (number) {
                if (number < 7) {
                    return number + ' days'; // Moment uses "d" when it's just 1 day.
                } else {
                    var weeks = Math.round(number / 7);
                    return weeks + ' ' + (weeks > 1 ? 'weeks' : 'week');
                }
            },
            M: "a mo",
            MM: "%d mon",
            y: "a year",
            yy: "%d years"
        }
    });
    useEffect(() => {
        let animationFrameId: any;
        if (!comments?.length) return
        const desiredScrollY = 250 + 0.16722408026755853 * (window.innerHeight - 612)
        const scrollHandler = () => {
            animationFrameId = requestAnimationFrame(() => {
                window.scrollY > desiredScrollY
                    ? setCommentBox(true) : setCommentBox(false);
            });
        };
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
            cancelAnimationFrame(animationFrameId);
        };
    }, [])

    const addComment = async (comment: string, commentedOn: string, commentType: string) => {
        if (commentLoad) return
        setLoading(true)
        const formData = new FormData()
        formData.append('commentedOn', commentedOn)
        formData.append('comment', comment)
        formData.append('eventId', eventId)
        formData.append('commentType', commentType)
        formData.append('userType', '1')
        try {
            const response = await postReqJson(`${apiConstant.BASE_URL}/comment/addCommentOnEvent`, formData)
            if (response.data.responseData?.CommentDetails) {
                setComment("")
                const newComment = response.data.responseData.CommentDetails
                newComment.userData = user.data
                if (commentType.toString() === '1') dispatch(addNewComment(newComment))
                else if (commentType.toString() === '2') {
                    const comment: number = comments?.findIndex((comment: any) => comment._id === commentedOn)
                    dispatch(addReply(comment))
                    setSelectedReply({ name: "", id: "" })
                    dispatch(addnewReply({ _id: commentedOn, data: newComment }))
                    setReply(newComment)
                    setTimeout(() => {
                        const newElement = document.getElementById(newComment._id)
                        newElement?.scrollIntoView({ block: "center" })
                    }, 400)
                }
            }
            else {
                toast.error(response.data.error?.responseMessage)
            }
        } catch (error) {
            console.log(error, "erroir")
            toast.error('Ack, sorry! We’re having a server issue. Try again later')
        }
        finally {
            setLoading(false)
        }
    }

    const deleteComment = async (id: string, parent: string) => {
        setLoading(true)
        if (commentLoad) return
        try {
            const deleteIds = JSON.stringify([id])
            const res = await deleteReq(`${apiConstant.BASE_URL}/comment/deleteComments?deleteIds=${deleteIds}`)
            console.log(res)
            if (res.data.responseData?.replies?.length) {
                console.log(optionOpen, "optionnn", parent)
                const comment: number = comments?.findIndex((comment: any) => comment._id === parent)
                if (parent !== "") dispatch(deleteReply({ id: id, parent: parent, index: comment }))
                else dispatch(removeComment({ id: id }))
                if (id === newReply._id) setReply({ _id: null, commentId: null })
                toast.success("Comment Deleted")
            }
            else {
                toast.error(res.data.error?.responseMessage)
            }
        } catch (e) {
            toast.error('Ack, sorry! We’re having a server issue. Try again later')
        } finally {
            setOption({ _id: "", open: false, parent: "" })
            setLoading(false)
        }
    }

    return (
        <>
            <section className="bg-gray-50   mt-0  antialiased   ">
                <div className="px-0 md:px-4 -ml-4">
                    <div className="flex  justify-between items-center mb-6">
                        <p className="text-lg lg:text-xl   font-semibold ">Comments</p>
                    </div>
                    <article className="p-1 pt-2 md:p-0  text-base rounded-lg ">
                        {comments?.map((comment: any, index: number) => {
                            return (<CommentCard setOption={(id: string, parent: string) => setOption({ _id: id, open: true, parent })} newReply={newReply?.commentId === comment._id ? newReply : {}} setReply={setReply} isLogged={user.data} key={comment._id} index={index} commentLength={comments?.length} commentInputRef={commentInputRef} setSelectedReply={setSelectedReply} userImage={comment?.userData?.profileCoverPhotos[0]?.url} comment={comment} selectedtoReply={selectedtoReply} />)
                        })}
                        {/* {!comments.length && <p className='text-gray-900 font-normal'>Be the first to comment!</p>} */}
                        {/* <div className='flex justify-center items-center my-3' onClick={() => { loadMore(10, page) }}>
                        <button data-tooltip-target="tooltip-default" type="button">
                            <svg width="24px" height="24px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="#687580"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z" fill="#687580"></path> </g></svg>
                        </button>
                        <div id="tooltip-default" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                            Tooltip content
                            <div className="tooltip-arrow" data-popper-arrow></div>
                        </div>
                    </div> */}
                        {user?.data ? <div className={`flex w-full   mt-4  ${showCommentBox ? 'fixed  bottom-0  left-0 md:left-[5.08%] bg-gray-50 w-full py-4 px-5 md:w-[61.6%]  md:pl-16 md:py-4 ' : ""}`}>
                            {user.data?.profileCoverPhotos.length > 0 ? <img
                                className="my-auto mr-4 w-[50px] h-[50px] rounded-full"
                                src={user?.data?.profileCoverPhotos[0]?.url}
                                alt="" /> : <UserAvatar />}
                            <div className=' flex py-0 flex-col  w-[98%] md:w-full pl-1 -mr-3 md:-mr-1 rounded-xl border-2 border-gray-300'>
                                {selectedtoReply.id ? <><div className='text-gray-500 py-3 mx-4 text-sm md:text-base flex justify-between'>
                                    {selectedtoReply.name ? `Reply to ${selectedtoReply?.name}` : ''}
                                    <button className='h-3 mx-1' onClick={() => setSelectedReply({ name: "", id: "" })}><CloseIcon /></button>
                                </div><hr className='mt-2 mb-2 ' /></> : ''}
                                <div className=" flex  items-center justify-between ">
                                    <textarea onChange={(e) => {
                                        // setTimeout(() => {
                                        setComment(e.target.value)
                                        // }, 300)
                                    }} disabled={!user.data} value={commentInput} ref={commentInputRef} className="text-sm md:text-base w-full bg-transparent border-none focus:outline-none  h-[70%] pl-3 p-1 resize-none" placeholder={user.data ? "Write your comment..." : "Please login to post a comment"}></textarea>
                                    <button disabled={!Boolean(commentInput.trim())} onClick={(e) => { addComment(commentInput.trim() || "", selectedtoReply.id ? selectedtoReply.id : eventId, selectedtoReply?.name ? '2' : '1') }} type="submit" className="text-md pr-4">
                                        <svg className='w-8 md:w-auto h-7 md:h-8 ' fill={Boolean(commentInput.trim()) ? "#0284C7" : "#6b7280"} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24.00 24.00" xmlSpace="preserve" width="26px" height="26px" stroke="#6b7280" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.336"></g><g id="SVGRepo_iconCarrier">  <g id="surface1"> <path d="M2,3v7.8L18,12L2,13.2V21l20-9L2,3z"></path> </g> <rect className="st0 fill-none" width="24" height="24"></rect> </g></svg>
                                    </button>
                                </div>
                            </div>
                        </div> : ""}
                    </article>
                    {/* {optionOpen.open ? <DialogBox overrideClass='w-1/2 
                !p-2 md:!p-2' onClose={() => { }} isOpen={optionOpen.open}>
                    <div className=" w-full flex justify-center flex-col">
                        <button onClick={() => {
                            setConfirmOpen(true)
                            setOption({ ...optionOpen, open: false })
                        }} className="block  px-4 py-2 text-sm capitalize text-red-500 font-semibold">
                            Delete
                        </button>
                        <hr className='px-4' />
                        <button onClick={() => (setOption({ _id: "", open: false, parent: "" }))} className="block px-4 py-2 text-sm capitalize text-gray-700 ">
                            Cancel
                        </button>
                    </div>

                </DialogBox> : ""} */}
                    <ConfirmDialog onClose={() => {
                        setOption({ _id: "", open: false, parent: "" })
                    }} isOpen={optionOpen.open} text='Are you sure you want to delete this comment ?' title='Delete Comment' action={() => { deleteComment(optionOpen._id, optionOpen.parent) }} actionName='Delete' > </ConfirmDialog>
                </div >
            </section >

        </>
    )
}

export default Comment