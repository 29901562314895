import React from 'react'
import ReactDOM from 'react-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import DialogBox from './DialogBox'
import { editUserData } from '../services/userApi/user';
import { AppDispatch } from '../store/store';
import { enthnicityList, propmtQ } from '../contants/formConstants';

type OverlayProps = {
    children: React.ReactNode;
    promptOverrideClass?: string;
    zIndexClass?: string;
};
const Overlay = (props: OverlayProps) =>
    ReactDOM.createPortal(
        <div className=' absolute insect-0 top-4' style={{ zIndex: "1000", position: "absolute" }}>
            <div style={{ zIndex: "1000", position: "relative", height: "100%", width: "100%" }}>{props.children}</div>
        </div >,
        document.getElementById('overlay-modal-root') || document.body
    );


function PopupForm(props: any) {
    const userData = props.userData
    const dispatch = useDispatch<AppDispatch>()

    const editProfile = async (values: any, info = true) => {
        const formData = new FormData();
        try {
            values.promptedQues = JSON.stringify(propmtQ.map((q: any, i: number) => {
                q.answer = values[`question${i + 1}`]
                return q
            }))

            let timestamp = ''
            if (values?.dob) {
                values.dob = new Date(values.dob).getTime() as any
            }
            Object.keys(values).forEach(key => {
                timestamp && formData.append("dob", new Date(timestamp).getTime().toString())
                formData.append(key, values[key]);
            });
            formData.append("userType", '1')
            const resp = await dispatch(editUserData(formData))
            infoForm.resetForm()
            if (resp?.success === true) toast.success("Profile Updated Successfully")
            props.closePopup()
        }
        catch (e) {
            props.closePopup()
            console.log(e)
        }
    }
    const infoForm = useFormik({
        initialValues: {
            dob: userData.dob ? new Date(userData.dob).toISOString().split('T')[0] : '',
            showPrompted: userData.showPrompted || 0,
            ethnicity: userData.ethnicity, gender: userData.gender, city: userData.city, state: userData?.state || "", country: userData.country,
            question1: userData?.promptedQues[0]?.answer || '', question2: userData?.promptedQues[1]?.answer || '', question3: userData?.promptedQues[2]?.answer || '',
            question4: userData?.promptedQues[4]?.answer || '', question5: userData?.promptedQues[5]?.answer || '', question6: userData?.promptedQues[6]?.answer || '',
        },
        onSubmit: async (values, { setFieldTouched }) => {
            const questions = ['question1', 'question2', 'question3', 'question4', 'question5', 'question6'];
            questions.forEach(question => setFieldTouched(question));
            await editProfile(values)
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            city: Yup.string().required('city is required'),
            state: Yup.string().required('state is required'),
            gender: Yup.string().required('gender is required'),
            ethnicity: Yup.string().required('ethnicity is required'),
            country: Yup.string().required('country is required'),
            dob: Yup.string().required('dob is required'),
            question1: Yup.string().notRequired(),
            question2: Yup.string().notRequired(),
            question3: Yup.string().notRequired(),
            question4: Yup.string().notRequired(),
            question5: Yup.string().notRequired(),
            question6: Yup.string().notRequired(),
        }).test('question1', 'You must answer at least two questions', (obj: any) => {
            const questions = ['question1', 'question2', 'question3', 'question4', 'question5', 'question6'];
            let count = 0;
            for (let i = 0; i < questions.length; i++) {
                console.log(obj[questions[i]], "qustions of i")
                if (obj[questions[i]]) {
                    count++;
                }
                console.log(count, "count")
                if (count >= 2) {
                    return true;
                }
            }
            console.log(count, "count")
            throw new Yup.ValidationError('You must answer at least two questions', null, 'question1')
        })
    })
    console.log(infoForm.values, "values", infoForm.errors, infoForm.touched)
    const { errors } = infoForm
    return (
        <Overlay>
            <DialogBox withClose overrideClass='md:w-[600px] lg:w-[800px] ' overrideMainClass='!items-start overflow-auto' isOpen={true} onClose={props.closePopup}>
                < div className='flex flex-col item-center justify-center'  >
                    <form onSubmit={infoForm.handleSubmit}>
                        <div className='flex flex-col item-center justify-center'>
                            <h5 className='text-1xl md:text-2xl font-bold text-center'> Please Enter the Details to Checkout </h5>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4'>
                                <div className=''>
                                    <p className='text-gray-500 pb-1'> Enter city
                                        <span className='text-red-500'> *</span>

                                    </p>
                                    <input type='text'
                                        className='bg-gray-100 px-4 py-2 w-full'
                                        placeholder='enter city'
                                        name='city'
                                        value={infoForm.values?.city}
                                        onChange={infoForm.handleChange}
                                    // }
                                    />
                                    {
                                        errors?.city && <span className='text-sm text-red-500'>City is required</span>
                                    }
                                </div>
                                <div className=''>
                                    <p className='text-gray-500 pb-1'> Enter state
                                        <span className='text-red-500'> *</span>
                                    </p>
                                    <input type='text'
                                        className='bg-gray-100 px-4 py-2 w-full'
                                        placeholder='enter state'
                                        name='state'
                                        value={infoForm.values?.state}
                                        onChange={infoForm.handleChange}
                                    />
                                    {
                                        errors?.state && <span className='text-sm text-red-500'> State is required</span>
                                    }
                                </div>
                                <div className=''>
                                    <p className='text-gray-500 pb-1'> Enter Country
                                        <span className='text-red-500'> *</span>
                                    </p>
                                    <input type='text'
                                        className='bg-gray-100 px-4 py-2 w-full'
                                        placeholder='enter country'
                                        name='country'
                                        value={infoForm.values?.country}
                                        onChange={infoForm.handleChange}
                                    />
                                    {
                                        errors?.country && <span className='text-sm text-red-500'> Country is required</span>
                                    }
                                </div>
                                <div className=' '>
                                    <p className='text-gray-500 pb-1'> Select date of birth   <span className='text-red-500'> *</span></p>
                                    <input type='date'
                                        className="bg-gray-100 outline-none px-4 py-2 w-full"
                                        value={infoForm.values?.dob}
                                        name='dob'
                                        onChange={infoForm.handleChange}
                                    // min={dateRange?.maxDate}
                                    // max={dateRange?.minDate}
                                    />
                                    {infoForm.touched.dob && infoForm.errors.dob && (
                                        <span className='text-red-500 text-sm'>{infoForm.errors.dob}</span>
                                    )}
                                </div>
                            </div>

                            <div className='my-4'>
                                <p className='text-gray-500 pb-1'> Select gender
                                    <span className='text-red-500'> *</span>
                                </p>
                                <div className='flex gap-4 flex-wrap'>
                                    <div>
                                        <button type='button' className={infoForm.values?.gender?.toString() === '1' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                            onClick={(e) => infoForm.setFieldValue('gender', 1)}
                                        >
                                            <p>Male </p>
                                            {
                                                infoForm.values?.gender?.toString() === '1' && <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </span>
                                            }
                                        </button>
                                    </div>
                                    <div>
                                        <button type='button' className={infoForm.values?.gender?.toString() === '2' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                            onClick={(e) => infoForm.setFieldValue('gender', 2)}
                                        >
                                            <p>Female </p>
                                            {
                                                infoForm.values?.gender?.toString() === '2' && <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </span>
                                            }

                                        </button>
                                    </div>
                                    <div>
                                        <button type='button' className={infoForm.values?.gender?.toString() === '3' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                            onClick={(e) => infoForm.setFieldValue('gender', 3)}

                                        >
                                            <p>   Nonbinary or Genderqueer </p>
                                            {
                                                infoForm.values?.gender?.toString() === '3' && <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </span>
                                            }

                                        </button>
                                    </div>
                                    <div>
                                        <button type='button' className={infoForm.values?.gender?.toString() === '4' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                            onClick={(e) => infoForm.setFieldValue('gender', 4)}
                                        >
                                            <p>Do not wish to share</p>
                                            {
                                                infoForm.values?.gender?.toString() === '4' && <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </span>
                                            }

                                        </button>
                                    </div>
                                </div>
                                {errors?.gender && <span className='text-sm text-red-500'> Gender is required</span>}
                            </div>
                            <div className='w-full my-4'>
                                <p className='text-gray-500 pb-1'>What is your ethnicity ?
                                    <span className='text-red-500'> *</span>
                                </p>
                                <div className='flex  flex-wrap gap-3 py-2'>
                                    {
                                        enthnicityList?.map((item: any, i: any) => (
                                            <div>
                                                <button type='button' className={infoForm.values?.ethnicity === `${item}` ? 'border px-4 py-2 rounded-md  bg-blue-500 text-white items-center flex gap-2'
                                                    : 'border border-blue-500  bg-white px-4 py-2 rounded-md text-blue-500 hover:bg-blue-500 hover:text-white'
                                                }
                                                    onClick={() => {
                                                        infoForm.setFieldValue('ethnicity', item)
                                                    }}
                                                >
                                                    {item}

                                                </button>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    errors?.ethnicity && <span className='text-sm text-red-500'> Ethnicity is required</span>
                                }
                            </div>
                            <div className='w-full my-4'>
                                <p className='text-gray-500 pb-1'>Prompt Questions Answers
                                    <span className='text-red-500'> *</span>
                                </p>
                                {
                                    errors?.question1 && <span className='text-sm text-red-500'>Please fill at least two answer of prompted questions </span>
                                }
                                <div className='w-full py-2'>
                                    {
                                        propmtQ.map((item: any, i: any) => (
                                            <div className='px-4 py-2 border-dashed border-2 border-blue-400 rounded-sm mb-4' key={i}>
                                                <p className='text-gray-600'>{item?.question}</p>
                                                {
                                                    i === 0 ?
                                                        <input type='text'
                                                            className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                            placeholder='Enter answer'
                                                            value={infoForm.values.question1}
                                                            onChange={infoForm.handleChange}
                                                            name='question1'
                                                        //     setPromptAns({ ...propmtAns, ans1: e.target.value })
                                                        // }
                                                        // }
                                                        />
                                                        :
                                                        i === 1 ?
                                                            <input type='text'
                                                                className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                placeholder='Enter answer'
                                                                value={infoForm.values.question2}
                                                                onChange={infoForm.handleChange}
                                                                name='question2'
                                                            />
                                                            :
                                                            i === 2 ?
                                                                <input type='text'
                                                                    className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                    placeholder='Enter answer'
                                                                    value={infoForm.values.question3}
                                                                    onChange={infoForm.handleChange}
                                                                    name='question3'

                                                                />
                                                                :
                                                                i === 3 ?
                                                                    <input type='text'
                                                                        className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                        placeholder='Enter answer'
                                                                        value={infoForm.values.question4}
                                                                        onChange={infoForm.handleChange}
                                                                        name='question4'


                                                                    // }
                                                                    />
                                                                    :
                                                                    i === 4 ?
                                                                        <input type='text'
                                                                            className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                            placeholder='Enter answer'
                                                                            value={infoForm.values.question5}
                                                                            onChange={infoForm.handleChange}
                                                                            name='question5'

                                                                        // }
                                                                        />
                                                                        :
                                                                        <input type='text'
                                                                            className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                            placeholder='Enter answer'
                                                                            value={infoForm.values.question6}
                                                                            onChange={infoForm.handleChange}
                                                                            name='question6'
                                                                        />
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        </div>
                        <div className='p-2 flex items-center text-sm'>
                            <input type='checkbox'
                                className='h-[20px] w-[20px]'
                                checked={infoForm.values.showPrompted || false}
                                onChange={(e) => infoForm.setFieldValue('showPrompted', Number(e.target.checked))}
                            />
                            <p className='pl-4 text-gray-600'>I Want To Hide My Answers From Other Users</p>
                        </div>
                        <div className='w-full flex justify-center'>
                            <button
                                className='w-full text-center rounded-md font-bold center text-white bg-sky-600 hover:bg-sky-700 px-4 py-3 mt-8'
                                type='submit'
                            > Submit</button>
                        </div >
                    </form>

                </div>
            </DialogBox>
        </Overlay >
    )
}


export default PopupForm