import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const ratingSlice = createSlice({
  name: 'rating',
  initialState: null as number | null,
  reducers: {
    setRating: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
  },
});

export const { setRating } = ratingSlice.actions;

export default ratingSlice.reducer;
