export const enthnicityList = [
    'American Indian', 'East Asian', 'Middle Eastern', 'South Asian', 'Black/African Descent', 'Hispanic/Latino', 'Pacific Islander', 'White/Caucasian', 'Prefer Not To Say', 'Other'
]
export const propmtQ =
[
    {
        questionNumber: 1,
        question: 'My favorite(s) drinks include',
        answer: ''
    },
    {
        questionNumber: 2,
        question: 'If I was the DJ I would play nonstop…. (Ex: Genre, Artists, Song)',
        answer: ''
    },
    {
        questionNumber: 3,
        question: 'I love an event that serves ____ food(s)?',
        answer: ''
    },
    {
        questionNumber: 4,
        question: 'To ensure that I have a fun time out there must be….',
        answer: ''
    },
    {
        questionNumber: 5,
        question: 'A good event or party must have….',
        answer: ''
    },
    {
        questionNumber: 6,
        question: 'My vibe is typically….',
        answer: ''
    },

] as any