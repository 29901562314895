import yovi from "../assests/logo/YOVI_LOGO_FINAL.png";
import appleIcon from "../assests/icon/apple.svg";
import fb from "../assests/icon/facebook-f.svg";
import linkedIn from "../assests/icon/linkedin-in.svg";
import tiktok from "../assests/icon/tiktok.svg";
import instagram from "../assests/icon/instagram.svg"
import { NavLink } from "react-router-dom";

const Footer = ({ isEventPage }: any) => {
  const downloadApp = () => {
    window.open("https://apps.apple.com/in/app/yovi-your-vibe/id1598304858", "_blank");
  }
  return (
    <div
      className={`w-full pb-20 px-4 py-3 md:pt-8 sm:px-8 ${isEventPage ? "mb-32" : ''}`}
      style={{ backgroundColor: "#003761", opacity: "0.88" }}
    >
      <div className="flex flex-col xl:flex-row sm:justify-between items-center p-5 pb-0 sm:p-0 ">
        <div className="flex items-center w-full sm:w-auto justify-between">
          <div className="flex flex-col justify-items-start sm:flex-row sm:items-center">
            <div className="flex flex-row items-center bg-white rounded-lg h-16 w-16 sm:h-24 sm:w-24 m-2">
              <img src={yovi} alt="yovi-icon" className="p-2 h-18 sm:h-24" />
            </div>
            <div className="sm:ml-4 text-start text-white font-medium">
              <p className="text-sm md:text-lg text-center text-white font-medium sm:ml-8" >info@findyovi.com</p>
            </div>
          </div>


          <div className=" flex sm:hidden flex-col items-center  lg:items-start text-white text-sm  sm:mt-0">
            <h3 className="text-xs font-medium">
              Download YOVI
            </h3>
            <div onClick={downloadApp} className="flex flex-row bg-black w-[95px] p-1 h-10  mt-2 rounded-md border-2 border-gray-400 justify-between items-center">
              <img src={appleIcon} alt="apple-icon" className="w-6" />
              <div className="text-[9px] leading-[11px]">
                Download on
                App Store
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap p-5 pb-0 md:flex md:flex-row justify-center items-center font-medium text-xs md:text-[16px] text-white  my-3 gap-4 md:gap-8">
          <NavLink to="/aboutus" className=" max-h-20">
            About Us
          </NavLink>
          <NavLink to="/user" className="max-h-20">
            YOVI User
          </NavLink>
          <NavLink to="/event" className="">
            YOVI Event Organizer
          </NavLink>
          <NavLink to="/business" className=" md:m-0">
            YOVI For Business
          </NavLink>
          <NavLink to="/support" className=" md:m-0">
            Support
          </NavLink>
        </div>

        <div className="hidden sm:flex flex-col items-center  lg:items-start text-white text-sm mt-4 sm:mt-0">
          <h3 className="text-lg sm:text-2xl font-medium">
            Download YOVI
          </h3>
          <div onClick={downloadApp} className="flex flex-row bg-black w-36 p-1 h-14 sm:h-14 mt-4 rounded-md border-2 border-gray-400 justify-between items-center">
            <img src={appleIcon} alt="apple-icon" className="w-10" />
            <div className="mx-auto">
              <p className="text-xs">Download on</p>
              <p className="text-lg">App Store</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse   sm:flex-row items-center justify-between sm:mt-8 border-t-2 border-gray-400">
        <p className="text-white text-sm mt-4 sm:mt-0 lg:pl-2">
          Copyright © 2024 Yovi | All Rights Reserved
        </p>
        <div className="flex gap-2  py-3 sm:mt-0 mx-2">
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61550025093009">
            <img
              src={fb}
              alt="facebook-icon"
              className="w-10 h-10 bg-white rounded-lg p-2"
            />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@yovi.your.vibe?_t=8j5uwQcboze&_r=1">
            <img
              src={tiktok}
              alt="tiktok-icon"
              className="w-12 h-10 bg-white rounded-lg p-2"
            />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/yoviapp/">
            <img
              src={instagram}
              alt="instagram-icon"
              className="w-12 h-10 bg-white rounded-lg p-2"
            />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/99885776/admin/feed/posts/">
            <img
              src={linkedIn}
              alt="linkedin-icon"
              className="w-12 h-10 bg-white rounded-lg p-2"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
