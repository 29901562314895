import React, { useEffect, useState, useRef, useMemo } from 'react'
import theme from '.././assests/images/blogin.png'
import login from '.././assests/images/login.png'
import BasePhoneNumberInput from '.././components/BasePhoneNumberInput'
import { Link, useNavigate } from 'react-router-dom'
import { apiConstant } from '../contants/apiContants'
import { postReq } from '../services/apiCall'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/Loader'
import LazyLoad from 'react-lazy-load';


function LogIn() {
    const [otp, setOtp] = useState({
        1: '',
        2: '',
        3: '',
        4: ''
    })

    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ] as any;
    const [phone, setPhone] = useState({
        countryCode: '',
        mobile: ''
    })
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isResend, setIsResend] = useState(false)
    const [time, setTime] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        number: '',
        otp: ''
    });
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search);
    const selectedEvent = params.get('selectedevent')

    useEffect(() => {
        if (isSubmitted) {
            inputRefs[0]?.current?.focus();
            setTime(60)
        }

    }, [isSubmitted]);

    useEffect(() => {
        let interval = null as any;
        if (time > 0 && isSubmitted) {
            interval = setInterval(() => {
                setTime(time - 1);
            }, 1000);
        }
        else {
            // setIsResend(false)
            setTime(0)
            clearInterval(interval)
        }
        return () => clearInterval(interval); // Clear the interval on unmount
    }, [time, isResend]);


    useMemo(() => {
        if (phone?.countryCode !== '1') {
            setTimeout(() => {
                setPhone({ ...phone, mobile: phone?.countryCode })
            }, 100)
        }
    }, [phone?.countryCode])

    const handleInput = (event: any, index: any) => {
        if (event.target.value.length > 0 && index < inputRefs.length - 1) {
            inputRefs[index] = event.target.value
            inputRefs[index + 1].current.focus();

        }
        else {
            inputRefs[index] = event.target.value
            if (index > 0 && event.target.value.length === 0) {
                inputRefs[index - 1].current.focus();
            }

        }
        if (index === 0) {
            setOtp({ ...otp, 1: event.target.value })
        }
        if (index === 1) {
            setOtp({ ...otp, 2: event.target.value })
        }
        if (index === 2) {
            setOtp({ ...otp, 3: event.target.value })
        }
        if (index === 3) {
            setOtp({ ...otp, 4: event.target.value })
        }


    }
    const handlePhone = (val: any, code: any) => {
        setPhone({ ...phone, countryCode: code?.dialCode, mobile: val })
    }

    const handlResendOtp = () => {
        setIsResend(true)
        setTime(60)
        handleSendOtp(true)
    }
    const checkError = (name: any, val: any, ccode: any) => {
        let err = validateForm(name, val, ccode)
        setErrors(err)
    }
    function isValidNumber(number: any) {
        // Check if the number is exactly 10 digits long
        if (number.toString().length !== 10) {
            return false;
        }

        // Check if all digits are not 0
        if (/^0{10}$/.test(number.toString())) {
            return false;
        }

        // Number is valid
        return true;
    }
    const validateForm = (name: any, val: any, ccode: any) => {
        const error = {} as any;
        let rawNumber = val.slice(ccode?.dialCode.length)
        if (!rawNumber) {
            error.number = "Phone Number is required";
        }
        else if (rawNumber.length > 0) {
            let validNumber = isValidNumber(rawNumber)
            if (validNumber) {
                error.number = "";
            }
            else {
                error.number = "Phone Number is invalid";
            }
        }
        if ((!otp[1] || !otp[2] || !otp[3] || !otp[4]) && isSubmitted) {
            error.otp = "Enter valid OTP";
        }

        return error;
    };
    const handleSendOtp = (send: boolean) => {
        setOtp({
            1: '',
            2: '',
            3: '',
            4: ''
        })
        inputRefs[0] = ''
        inputRefs[1] = ''
        inputRefs[2] = ''
        inputRefs[3] = ''

        let data = {
            mobile: phone?.mobile?.slice(phone?.countryCode.length),
            countryCode: phone?.countryCode
        }
        // console.log(isResend)
        sendOtp(data, send)

    }
    const handlVerifyOtp = () => {
        let finalOtp = `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`
        let data = {
            userType: 1,
            otp: finalOtp,
            // otp: '9876',
            type: 0,
            countryCode: phone?.countryCode,
            mobile: phone?.mobile?.slice(phone?.countryCode.length)
        }
        setTime(0)
        verifyOtp(data)
    }
    const sendOtp = (num: any, resend: boolean) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/sendOtp`
        postReq(url, num)
            .then((res) => {
                // console.log('res::;', res)
                setLoading(false)
                if (res?.data?.statusCode) {
                    let msg
                    msg = res?.data?.responseData?.message

                    if (resend) {
                        msg = 'Resend otp sent successfully'
                    }
                    toast.success(msg);
                    setIsSubmitted(true)

                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg)
                }

            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }

    const verifyOtp = (otpv: any) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/verifyOtp`
        postReq(url, otpv)
            .then((res) => {
                // console.log('res:verify otp:;', res)
                setLoading(false)
                if (res?.data?.statusCode && res?.data?.responseData?.accessToken) {
                    let token = res?.data?.responseData?.accessToken
                    localStorage.setItem('token', token)
                    let msg = res?.data?.responseData?.message
                    toast.success(msg);
                    if (selectedEvent) navigate(`/event/${selectedEvent}`)
                    else navigate('/')
                    setOtp({
                        1: '',
                        2: '',
                        3: '',
                        4: ''
                    })
                }
                else {
                    if (res?.data?.statusCode) {
                        let msg = 'sorry,this user does not exist..please sign up !'
                        toast.error(msg);
                        setOtp({
                            1: '',
                            2: '',
                            3: '',
                            4: ''
                        })
                        setIsSubmitted(false)
                    }
                    else {
                        let msg = res?.data?.error?.responseMessage
                        toast.error(msg);
                    }


                }
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }


    return (
        <div className='w-full'>
            <div className='flex flex-col md:flex-row'>
                <div className='w-full md:w-[70%]'>
                    <div className='w-full hidden md:block md:h-screen bg-gray-300  overflow-hidden'>
                        <LazyLoad threshold={0.95}>
                            <img src={theme} alt='theme' className='w-full hidden md:block md:h-screen' />
                        </LazyLoad>
                    </div>

                </div>
                {
                    isSubmitted ?
                        <div className='w-full md:w-[30%] p-6' >
                            <p className='font-bold hover:cursor-pointer' onClick={() => setIsSubmitted(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                </svg>
                            </p>

                            <div className='flex justify-center items-center w-full h-[90vh] text-center'>
                                {
                                    loading ?
                                        <Loader />
                                        :

                                        <div className='w-full md:w-[80%]'>
                                            <p className='text-1xl md:text-2xl font-bold'>
                                                Enter your verification code.
                                            </p>
                                            <p className='text-gray-500 my-3'>
                                                Enter the verification code that was sent to you, +{phone.mobile}
                                            </p>
                                            <div className='w-full flex justify-center items-center my-4'>
                                                <img src={login} alt='login' />
                                            </div>
                                            <div className='flex gap-6 justify-center'>
                                                {inputRefs.map((inputRef: any, index: any) => (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        ref={inputRef}
                                                        value={index === 0 ? otp[1] : index === 1 ? otp[2] : index === 2 ? otp[3] : index === 3 ? otp[4] : ''}
                                                        maxLength={1}
                                                        onChange={(event) => handleInput(event, index)}
                                                        className='h-[50px] w-[50px] bg-gray-100 text-center outline-none border focus:border-blue-600 rounded-md shadow-lg'

                                                    />
                                                ))}
                                            </div>


                                            {
                                                errors?.otp && <span className='text-sm text-red-500'> {errors?.otp}</span>
                                            }

                                            {
                                                (time > 0) ?
                                                    <p className='pt-6 text-1xl md:text-2xl text-blue-400'>00:{time} </p>
                                                    : null
                                            }
                                            <p className='text-gray-500 py-4 mt-4'>
                                                Didn’t you received any code? <br />
                                                <button
                                                    disabled={time > 0}
                                                    className={time === 0 ? 'text-blue-500 cursor-pointer font-bold hover:underline' : 'text-blue-300'}
                                                    onClick={handlResendOtp}> Resend a new code
                                                </button>
                                            </p>
                                            {

                                            }
                                            <button
                                                disabled={(otp[1] && otp[2] && otp[3] && otp[4]) ? false : true}
                                                className={(otp[1] && otp[2] && otp[3] && otp[4]) ?
                                                    'w-full border rounded-md bg-sky-600 hover:bg-sky-700 text-white px-4 py-3 mt-8 font-bold' :
                                                    'w-full border rounded-md bg-blue-300 text-white px-4 py-3 mt-8  font-bold'
                                                }
                                                onClick={handlVerifyOtp}
                                            >
                                                Verify
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                        :

                        <div className='w-full md:w-[30%] p-6' >
                            <Link to='/'>
                                <p className='font-bold hover:cursor-pointer'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                        className="w-6 h-6 hover:bg-gray-100 rounded-full">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                    </svg>
                                </p>
                            </Link>

                            <div className='flex justify-center items-center w-full h-[90vh] text-center'>
                                {
                                    loading ?
                                        <Loader />
                                        :
                                        <div className='w-full md:w-[80%]'>
                                            <p className='text-2xl font-bold'>{params.get('isfromcheckout') ? 'Please login here to purchase YOVI tickets' : ' Welcome to Login'}</p>

                                            <p className='text-2xl my-4'>
                                                Enter your phone number.
                                            </p>
                                            <p className='text-gray-500'>
                                                We’ll send you a verification code to your given phone number.
                                            </p>
                                            <div className='w-full flex justify-center items-center my-4'>
                                                <img src={login} alt='login' />
                                            </div>

                                            <p className='text-gray-500 py-4 text-left'>
                                                Enter phone number
                                            </p>
                                            <BasePhoneNumberInput
                                                val={phone?.mobile}
                                                setval={handlePhone}
                                                err={checkError}
                                            />
                                            {
                                                errors?.number && <span className='text-sm text-red-600'> {errors?.number}</span>
                                            }

                                            <button
                                                disabled={phone?.mobile.slice(phone.countryCode?.length).length === 10 ? false : true}
                                                className={
                                                    phone?.mobile.slice(phone.countryCode?.length).length === 10 ?
                                                        'w-full border rounded-md bg-sky-600 hover:bg-sky-700 text-white px-4 py-3 mt-10 font-bold'
                                                        :
                                                        'w-full border rounded-md bg-blue-300 text-white px-4 py-3 mt-10 font-bold'
                                                }

                                                onClick={() => handleSendOtp(false)}
                                            > Continue</button>
                                            <div className='mt-6'>
                                                <span className='text-gray-500'>
                                                    if you don't have account please
                                                </span>
                                                <Link to={`/sign-up` + (selectedEvent ? `?selectedevent=${selectedEvent}` : '') + (params.get('isfromcheckout') ? `&isfromcheckout=1` : '')}>
                                                    <span className='text-sky-500 pl-2 text-1xl font-bold hover:text-sky-700 cursor-pointer hover:underline'>
                                                        Sign Up
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </div>
            <ToastContainer />

        </div >
    )
}

export default LogIn