import React, { useState } from 'react';
import { enthnicityList } from '../contants/formConstants';


const DemographicForm = (props: any) => {
    const { formik, secondStepBtn } = props

    const [propmtQ, setPromptQ] = useState(
        [
            {
                questionNumber: 1,
                question: 'My favorite(s) drinks include',
                answer: ''
            },
            {
                questionNumber: 2,
                question: 'If I was the DJ I would play nonstop…. (Ex: Genre, Artists, Song)',
                answer: ''
            },
            {
                questionNumber: 3,
                question: 'I love an event that serves ____ food(s)?',
                answer: ''
            },
            {
                questionNumber: 4,
                question: 'To ensure that I have a fun time out there must be….',
                answer: ''
            },
            {
                questionNumber: 5,
                question: 'A good event or party must have….',
                answer: ''
            },
            {
                questionNumber: 6,
                question: 'My vibe is typically….',
                answer: ''
            },

        ] as any
    )
    const [dateRange, setDateRange] = useState({
        minDate: '',
        maxDate: ''
    })

    const checkdisabled = () => {
        const formikErrorsEmpty = Object.values(formik.errors).every((value) => value === "" || value === undefined);
        const touchedEmptly = Object.values(formik.touched).every((value) => value === false || value === undefined);
        return formikErrorsEmpty && !touchedEmptly
    };
    console.log(formik.values.prompted, "prompted")
    return (
        <div>
            <p className="py-2 text-1xl md:text-2xl md:py-4">Demographic Details</p>

            <div className='my-4'>
                <p className='text-gray-500 pb-1'> Select gender
                    <span className='text-red-500'> *</span>
                </p>
                {formik.touched.gender && formik.errors.gender && (
                    <span className='text-sm text-red-500 mb-2 py-2'>{formik.errors.gender}</span>
                )}
                <div className='flex gap-4 flex-wrap py-1'>

                    <div>
                        <button type='button' className={formik.values?.gender?.toString() === '1' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                            onClick={(e) => formik.setFieldValue("gender", 1)}
                        >
                            <p>Male </p>
                            {
                                formik.values?.gender === '1' && <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            }
                        </button>
                    </div>
                    <div>
                        <button type='button' className={formik.values?.gender?.toString() === '2' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                            onClick={(e) => formik.setFieldValue('gender', 2)}
                        >
                            <p>Female </p>
                            {
                                formik.values?.gender === '2' && <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            }

                        </button>
                    </div>
                    <div>
                        <button type='button' className={formik.values?.gender?.toString() === '3' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                            onClick={(e) => formik.setFieldValue("gender", 3)}
                        >
                            <p>   Nonbinary or Genderqueer </p>
                            {
                                formik.values?.gender === '3' && <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            }

                        </button>
                    </div>
                    <div>
                        <button type='button' className={formik.values?.gender?.toString() === '4' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                            onClick={(e) => formik.setFieldValue("gender", 4)}
                        >
                            <p>Do not wish to share</p>
                            {
                                formik.values?.gender?.toString() === '4' && <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </span>
                            }

                        </button>
                    </div>

                </div>

            </div>
            <div className='py-2'>
                <label htmlFor="city" className='text-gray-500 pb-1'>Enter city</label>
                <span className='text-red-500'> *</span>
                <input
                    placeholder='Enter city'
                    type='text'
                    id="city"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    className="bg-gray-100 outline-none px-4 py-2 w-full"

                />
                {formik.touched.city && formik.errors.city && (
                    <span className='text-red-500 text-sm'>{formik.errors.city}</span>
                )}
            </div>
            <div className='py-2'>
                <label htmlFor="state" className='text-gray-500 pb-1'>Enter state</label>
                <span className='text-red-500'> *</span>

                <input
                    placeholder='Enter state'
                    type='text'
                    id="state"
                    name="state"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    className="bg-gray-100 outline-none px-4 py-2 w-full"

                />
                {formik.touched.state && formik.errors.state && (
                    <span className='text-red-500 text-sm'>{formik.errors.state}</span>)}
            </div>
            <div className='py-2'>
                <label htmlFor="country" className='text-gray-500 pb-1'>Enter Country</label>
                <span className='text-red-500'> *</span>
                <input
                    placeholder='Enter country'
                    type='text'
                    id="country"
                    name="country"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    className="bg-gray-100 outline-none px-4 py-2 w-full"

                />
                {formik.touched.country && formik.errors.country && (
                    <span className='text-red-500 text-sm'>{formik.errors.country}</span>
                )}
            </div>
            <div className='py-2 '>
                <p className='text-gray-500 pb-1'> Select date of birth   <span className='text-red-500'> *</span></p>
                <input type='date'
                    className="bg-gray-100 outline-none px-4 py-2 w-full"
                    value={formik.values?.dob}
                    name='dob'
                    onChange={formik.handleChange}
                    min={dateRange?.maxDate}
                    max={dateRange?.minDate}
                />
                {formik.touched.dob && formik.errors.dob && (
                    <span className='text-red-500 text-sm'>{formik.errors.dob}</span>
                )}
            </div>


            <div className='w-full my-4'>
                <p className='text-gray-500 pb-1'>What is your ethnicity ?
                    <span className='text-red-500'> *</span>
                </p>
                {formik.touched.ethnicity && formik.errors.ethnicity && (
                    <span className='tex-sm text-red-500'>{formik.errors.ethnicity}</span>)
                }
                <div className='flex  flex-wrap gap-3 py-2'>
                    {
                        enthnicityList?.map((item: any, i: any) => (
                            <div>

                                <button type='button' className={formik.values?.ethnicity === `${item}` ? 'border px-4 py-2 rounded-md  bg-blue-500 text-white items-center flex gap-2'
                                    : 'border border-blue-500  bg-white px-4 py-2 rounded-md text-blue-500 hover:bg-blue-500 hover:text-white'
                                }
                                    onClick={() => {
                                        formik.setFieldValue('ethnicity', item)
                                    }
                                    }
                                >
                                    {item}
                                </button>
                            </div>
                        ))
                    }

                </div>

            </div>
            <div className='w-full my-4'>

                <p className='text-gray-500 pb-1'>Prompt Questions Answers <span className='text-red-500'> *</span></p>
                {formik.touched.question1 && formik.errors.question1 && (
                    <span className="text-sm text-red-500">{formik.errors.question1}</span>
                )}
                {
                    propmtQ?.map((item: any, i: any) => (

                        <div className='py-3'>

                            <label htmlFor={`question${i}`} className='text-gray-500 pb-1'>{item.question}</label><br />
                            <input
                                className="bg-gray-100 outline-none px-4 py-2 w-full"
                                type="text"
                                id={`question${i + 1}`}
                                name={`question${i + 1}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values[`question${i + 1}`]}
                                placeholder="Enter answer"
                            />

                        </div>
                    ))
                }
            </div>
            <div className='p-2 flex items-center text-sm'>
                <input type='checkbox'
                    className='h-[20px] w-[20px]'
                    checked={formik.values.prompted || false}
                    onChange={(e) => formik.setFieldValue('prompted', e.target.checked)}
                />
                <p className='pl-4 text-gray-600'>I Want To Hide My Answers From Other Users</p>
            </div>
            <div className='py-2'>
                <button ref={secondStepBtn} onClick={() => {
                    setTimeout(() => {
                        formik.setTouched({ ...formik.touched, ethnicity: true, city: true, country: true, dob: true, state: true, question1: true, gender: true })
                    }, 500)
                    formik.validateForm();
                    if (!checkdisabled()) return
                    if (formik.isValid) {
                        formik.handleSubmit();
                    } else {
                        return;
                    }
                }} hidden type="button">Submit</button>
            </div>

        </div >
    );
};

export default DemographicForm;
