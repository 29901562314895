import React, { useEffect, useRef, useState, useMemo } from 'react'
import theme from '.././assests/images/blogin.png'
import { Link, useNavigate } from 'react-router-dom'
import { CameraIcon } from '../assests/icon/customIcon'
import { apiConstant } from '../contants/apiContants'
import { postReq, postReqParam } from '../services/apiCall'
import BasePhoneNumberInput from '../components/BasePhoneNumberInput'
import { ToastContainer, toast } from 'react-toastify';
import login from '.././assests/images/login.png'
import Loader from '../components/Loader'
import LazyLoad from 'react-lazy-load';
import { isValidNumber } from '../utils/commonFunction'
import { enthnicityList } from '../contants/formConstants'



function SignUp() {
    const [loading, setLoading] = useState(false)
    const [isOTP, setIsOTP] = useState(false)
    const [isTC, setIsTC] = useState(false)
    const [isResend, setIsResend] = useState(false)
    const [time, setTime] = useState(0)
    const [showPrompted, setshowPrompted] = useState(false)
    const [otp, setOtp] = useState({
        1: '',
        2: '',
        3: '',
        4: ''
    })
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ] as any;

    const [phone, setPhone] = useState({
        countryCode: '',
        mobile: ''
    })
    const params = new URLSearchParams(window.location.search);
    const additonal = params.get('additionalInfo');
    const selectedEvent = params.get('selectedevent')
    const [propmtAns, setPromptAns] = useState(
        {
            ans1: '',
            ans2: '',
            ans3: '',
            ans4: '',
            ans5: '',
            ans6: ''
        }
    )
    const [propmtQ, setPromptQ] = useState(
        [
            {
                questionNumber: 1,
                question: 'My favorite(s) drinks include',
                answer: ''
            },
            {
                questionNumber: 2,
                question: 'If I was the DJ I would play nonstop…. (Ex: Genre, Artists, Song)',
                answer: ''
            },
            {
                questionNumber: 3,
                question: 'I love an event that serves ____ food(s)?',
                answer: ''
            },
            {
                questionNumber: 4,
                question: 'To ensure that I have a fun time out there must be….',
                answer: ''
            },
            {
                questionNumber: 5,
                question: 'A good event or party must have….',
                answer: ''
            },
            {
                questionNumber: 6,
                question: 'My vibe is typically….',
                answer: ''
            },

        ] as any


    )
    const [dateRange, setDateRange] = useState({
        minDate: '',
        maxDate: ''
    })
    const [data, setData] = useState({
        fname: '',
        lname: '',
        gender: additonal ? '1' : '',
        dob: '',
        state: '',
        city: '',
        country: '',
        ethnicity: '',
        email: '',
        address: ''
    })
    const [preview, setPreview] = useState('');
    const [preview1, setPreview1] = useState('');
    const [errors, setErrors] = useState({
        name: '',
        number: '',
        email: '',
        dob: '',
        propmt: '',
        ethnicity: '',
        state: '',
        city: '',
        country: '',
        pic: ''
    });


    const navigate = useNavigate()

    useEffect(() => {
        handeDateRange()
        if (isOTP) {
            inputRefs[0].current.focus();
            setTime(60)
        }
    }, [isOTP])

    useEffect(() => {
        let interval = null as any;
        if (time > 0 && isOTP) {
            interval = setInterval(() => {
                setTime(time - 1);
            }, 1000);
        }
        else {
            // setIsResend(false)
            setTime(0)
            clearInterval(interval)
        }
        return () => clearInterval(interval); // Clear the interval on unmount
    }, [time, isResend]);

    useMemo(() => {
        if (phone?.countryCode !== '1') {
            setTimeout(() => {
                setPhone({ ...phone, mobile: phone?.countryCode })
            }, 100)
        }
    }, [phone?.countryCode])

    const handleInput = (event: any, index: any) => {
        if (event.target.value.length > 0 && index < inputRefs.length - 1) {
            inputRefs[index] = event.target.value
            inputRefs[index + 1].current.focus();

        }
        else {
            inputRefs[index] = event.target.value
            if (index > 0 && event.target.value.length === 0) {
                inputRefs[index - 1].current.focus();
            }
        }
        if (index === 0) {
            setOtp({ ...otp, 1: event.target.value })
        }
        if (index === 1) {
            setOtp({ ...otp, 2: event.target.value })
        }
        if (index === 2) {
            setOtp({ ...otp, 3: event.target.value })
        }
        if (index === 3) {
            setOtp({ ...otp, 4: event.target.value })
        }


    }
    console.log(errors, "errors+++", isOTP)
    const handlResendOtp = () => {
        setIsResend(true)
        setTime(60)
        handleSendOtp(true)
    }
    const checkNumber = (name: any, val: any, ccode: any) => {
        let obj = {
            name, val, ccode
        }
        checkError(obj)

    }
    const checkError = (e: any) => {
        let err = validateForm(e)
        if (e?.name === 'fname') {
            setErrors({ ...errors, name: err.name })

        }
        if (e?.name === 'email') {
            setErrors({ ...errors, email: err.email })
        }
        if (e?.name === 'phone') {
            setErrors({ ...errors, number: err.number })
        }
        if (e?.name === 'city') {
            setErrors({ ...errors, city: err.city })
        }
        if (e?.name === 'state') {
            setErrors({ ...errors, state: err.state })

        }
        if (e?.name === 'country') {
            setErrors({ ...errors, country: err.country })
        }
        if (e?.name === 'ethnicity') {
            setErrors({ ...errors, ethnicity: err.ethinicity })

        }
        if (e?.name === 'propmt') {
            setErrors({ ...errors, propmt: err.propmt })

        }
        if (e?.name === 'dob') {
            setErrors({ ...errors, dob: err.dob })

        }

    }
    const handeDateRange = () => {
        const currentDate = new Date();
        let d = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()
        let m = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1
        let y = currentDate.getFullYear()
        setDateRange({
            ...dateRange,
            minDate: `${y - 18}-${m}-${d}`,
            maxDate: `${y - 100}-${m}-${d}`
            // minDate:"2005-03-01",
            // maxDate:'1923-03-30'
        })
    }
    const handlePhone = (val: any, code: any) => {
        setPhone({ ...phone, countryCode: code?.dialCode, mobile: val })
        // console.log(dateRange)
    }
    const checkEmailExist = (val: string) => {
        let dataParam = {
            userType: 1,
            email: val,
            // countryCode: val? " ": phone?.countryCode,
            // mobile:val? "": phone?.mobile.slice(phone?.countryCode.length)
        }
        if ((!errors.email && data?.email)) {
            checkMobileAndEmailExist(dataParam)
        }
    }
    const checkNumberExist = (val: string) => {
        let dataParam = {
            userType: 1,
            // email: val,
            countryCode: phone?.countryCode,
            mobile: phone?.mobile.slice(phone?.countryCode.length)
        }
        if (!errors.number && phone.mobile) {
            checkMobileAndEmailExist(dataParam)
        }
    }
    const handleImageChange = (event: any) => {
        const selectedFile = event.target.files[0];
        const previewURL = URL.createObjectURL(selectedFile);
        setPreview(previewURL);
        setPreview1(selectedFile)
        if (previewURL) {
            setErrors({ ...errors, pic: '' })
        }
    };
    const handleSendOtp = (val: boolean) => {
        setOtp({
            1: '',
            2: '',
            3: '',
            4: ''
        })
        let count = 0
        if (propmtAns?.ans1) {
            count += 1
        }
        if (propmtAns?.ans2) {
            count += 1
        }
        if (propmtAns?.ans3) {
            count += 1
        }
        if (propmtAns?.ans4) {
            count += 1
        }
        if (propmtAns?.ans5) {
            count += 1
        }
        if (propmtAns?.ans6) {
            count += 1
        }
        if (!preview1) {
            setErrors({
                ...errors,
                pic: 'Profile picture is required'
            })
            return
        }
        else if (!data?.fname) {
            setErrors({
                ...errors,
                name: 'First name is required'
            })
            return
        }
        else if (!data?.email) {
            setErrors({
                ...errors,
                email: 'Email is required'
            })
            return
        }
        else if (!phone?.mobile) {
            setErrors({
                ...errors,
                number: 'Phone number is required'
            })
            return

        }
        else if (!data?.dob) {
            setErrors({
                ...errors,
                dob: 'Date of birth is required'
            })
            return
        }
        if (additonal) {
            if (!data?.city) {
                setErrors({
                    ...errors,
                    city: 'City is required'
                })
                return
            }
            else if (!data?.state) {
                setErrors({
                    ...errors,
                    state: 'State is required'
                })
                return
            }
            else if (!data?.country) {
                setErrors({
                    ...errors,
                    country: 'Country is required'
                })
                return
            }
            else if (!data?.ethnicity) {
                setErrors({
                    ...errors,
                    ethnicity: 'Ethnicity is required'
                })
                return
            }
            else if (count < 2) {
                setErrors({
                    ...errors,
                    propmt: 'Please fill at least two answer'
                })
                return
            }
        }
        if (!errors.number && phone?.mobile) {
            console.log("inotp send")
            let data1 = {
                mobile: phone.mobile.slice(phone.countryCode?.length),
                countryCode: phone?.countryCode
            }
            sendOtp(data1, val)
        }

    }

    const validateForm = (e: any) => {
        const error = {} as any
        if (e.name === 'fname') {
            if (!e.value) {
                error.name = " First name is required";
            }
            else if (!/^[A-Za-z]+$/.test(e.value)) {
                error.name = " First name is invalid ";
            }
        }
        if (e.name === 'email') {
            if (!e.value) {
                error.email = "Email is required";
            } else if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(e.value)) {
                error.email = "Email is invalid";
            }
        }
        if (e.name === 'phone') {
            let rawNumber = e?.val.slice(e?.ccode?.dialCode.length)
            if (!rawNumber) {
                error.number = "Phone number is required";
            }
            else {
                let validNumber = isValidNumber(rawNumber)
                if (validNumber) {
                    error.number = "";
                }
                else {
                    error.number = "Phone number is invalid";
                }

            }
        }
        if (additonal) {
            if (e?.name === 'city') {
                if (!e.value) {
                    error.city = `City is required`;
                }
                else if (!/^[A-Za-z]+s*/.test(e.value)) {
                    error.city = " City is invalid ";
                }

            }
            if (e?.name === 'state') {
                let ename = 'state'
                if (!e.value) {
                    error[ename] = `State is required`;
                }
                else if (!/^[A-Za-z]+s*/.test(e.value)) {
                    error.state = " State is invalid ";
                }


            }
            if (e?.name === 'country') {
                let ename = 'country'
                if (!e.value) {
                    error[ename] = `Country is required`;
                }
                else if (!/^[A-Za-z]+s*/.test(e.value)) {
                    error.country = " Country  is invalid ";
                }
            }
            if (e?.name === 'ethnicity') {
                let ename = 'ethnicity'
                if (!e.value) {
                    error[ename] = `Ethnicity is required`;
                }
                else {
                    error[ename] = ''
                }

            }
            if (e?.name === 'propmt') {
                let ename = 'propmt'
                if (!e.value) {
                    error[ename] = `Prompt is required`;
                }
                else {
                    error[ename] = ''
                }
            }
        }
        if (e?.name === 'dob') {
            let ename = 'dob'
            if (!e.value) {
                error[ename] = `Dob is required`;
            }
            else {
                //console.log(e.value)
                let y = e.value.split('-')[0]
                let m = e.value.split('-')[1]
                let d = e.value.split('-')[2]
                let checkY = (y >= dateRange?.maxDate?.split('-')[0] && y <= dateRange?.minDate?.split('-')[0])
                let checkM = (m >= dateRange?.maxDate?.split('-')[1] && m <= dateRange?.minDate?.split('-')[1])
                let checkD = (d >= dateRange?.maxDate?.split('-')[2] && d <= dateRange?.minDate?.split('-')[2])
                console.log(dateRange)
                if (e.value > dateRange.minDate) {
                    error[ename] = `Invalid  dob`;
                }
                else if (e.value < dateRange.maxDate) {
                    error[ename] = `Invalid  dob`;
                }

            }
        }
        return error;
    };
    const handlVerifyOtp = () => {
        let finalOtp = `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`
        let data = {
            userType: 1,
            otp: finalOtp,
            // otp: '9876',
            type: 0,
            countryCode: phone?.countryCode,
            mobile: phone?.mobile.slice(phone.countryCode?.length)
        }
        setTime(0)
        verifyOtp(data)
    }
    const sendOtp = (num: any, resend: boolean) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/sendOtp`
        postReq(url, num)
            .then((res) => {
                // console.log('res::;', res)
                setLoading(false)
                if (res?.data?.statusCode) {
                    let msg = res?.data?.responseData?.message
                    if (resend) {
                        msg = 'Resend otp sent successfully'
                    }
                    toast.success(msg);
                    setIsOTP(true)
                }

            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }
    const checkMobileAndEmailExist = (dat: any) => {

        let url = `${apiConstant.BASE_URL}/user/isEmailMobileExist`
        postReq(url, dat)
            .then((res) => {
                // console.log('check email/mobile status:;', res)
                if (res?.data?.statusCode) {
                    // let msg = res?.data?.error?.responseMessage
                    // setErrors({
                    //     ...errors,number:'Number already exist'
                    // })
                    // if(dat?.email){
                    //     setErrors({
                    //         ...errors,email:msg
                    //     })
                    // }
                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    if (dat?.mobile) {
                        setErrors({
                            ...errors, number: msg
                        })
                    }


                    if (dat?.email) {
                        setErrors({
                            ...errors, email: msg
                        })
                    }

                    toast.warning(msg);
                }
            })
            .catch((err: any) => {
                console.log('err', err)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }

    const verifyOtp = (otp: any) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/verifyOtp`
        postReq(url, otp)
            .then((res) => {
                // console.log('res:verify otp:;', res)
                if (res?.data?.statusCode && res?.data?.responseData?.accessToken) {
                    let token = res?.data?.responseData?.accessToken
                    localStorage.setItem('token', token)
                    setLoading(false)
                    let msg = res?.data?.responseData?.message
                    toast.success(msg);
                    if (selectedEvent) navigate(`/event/${selectedEvent}`)
                    else navigate('/')
                    setOtp({
                        1: '',
                        2: '',
                        3: '',
                        4: ''
                    })
                }
                else {
                    if (res?.data?.statusCode) {
                        handleSignup()

                    }
                    else {
                        setLoading(false)
                        let msg = res?.data?.error?.responseMessage
                        toast.error(msg);
                    }
                }
            })
            .catch((err: any) => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 500)

            })
    }
    const handleSignup = () => {

        let ansArr = Object.values(propmtAns);
        let promptqA = propmtQ.map((item: any, i: any) => {
            return {
                ...item, answer: ansArr[i]
            }
        })
        let timestamp = ''
        if (data?.dob) {
            timestamp = new Date(data?.dob).getTime() as any
        }


        const formData = new FormData();
        formData.append("deviceId", '645dfdd')
        formData.append("deviceToken", '645dfdd')
        formData.append("platform", '3')
        formData.append("userType", '1')
        formData.append("socialType", '4')
        formData.append("firstName", data?.fname)
        formData.append("lastName", data?.lname)
        formData.append("countryCode", phone.countryCode)
        formData.append("mobile", phone.mobile?.slice(phone.countryCode?.length))
        formData.append("profileCover", preview1)

        formData.append("gender", data?.gender)
        timestamp && formData.append("dob", timestamp)
        formData.append("city", data?.city)
        formData.append("state", data?.state)
        formData.append("country", data?.country)
        formData.append("promptedQues", JSON.stringify(promptqA))
        formData.append("ethnicity", data?.ethnicity)
        formData.append("email", data?.email)
        formData.append("address", data?.address)
        formData.append("showPrompted", showPrompted ? '1' : '0')
        // console.log(data?.ethnicity)


        if (phone?.mobile && phone?.countryCode) {
            signup(formData)

        }
        else {
            alert('Phone number is required')
        }

    }
    const Conditionalspan = () => {
        return additonal ? <span className='text-red-500'> *</span> : null
    }
    const signup = (data: any) => {
        // setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/registration`
        postReqParam(url, data)
            .then((res) => {
                setLoading(false)
                if (res?.data?.statusCode && res?.data?.responseData?.accessToken) {
                    let token = res?.data?.responseData?.accessToken
                    localStorage.setItem('token', token)
                    let msg = res?.data?.responseData?.message
                    toast.success(msg)
                    if (selectedEvent) navigate(`/event/${selectedEvent}`)
                    else navigate('/')
                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg)
                    setIsOTP(false)
                }
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000);

            })
    }


    return (
        <div className='w-full'>
            <div className='flex flex-col md:flex-row'>
                <div className='w-full md:w-[70%]'>
                    <div className='w-full hidden md:block md:h-screen bg-gray-300  overflow-hidden'>
                        <LazyLoad threshold={0.95}>
                            <img src={theme} alt='theme' className='w-full hidden md:block md:h-screen' />
                        </LazyLoad>
                    </div>

                </div>
                {
                    isOTP ?
                        <div className='w-full md:w-[40%] p-6'  >
                            <p className='font-bold hover:cursor-pointer' onClick={() => setIsOTP(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                </svg>
                            </p>
                            <div className='flex justify-center items-center w-full h-[90vh] text-center'>
                                {
                                    loading ?
                                        <Loader />
                                        :

                                        <div className='w-full md:w-[80%]'>
                                            <p className='text-1xl md:text-2xl font-bold'>
                                                Enter your verification code.
                                            </p>
                                            <p className='text-gray-500 my-3'>
                                                Enter the verification code that was sent to you, +{phone.mobile}
                                            </p>
                                            <div className='w-full flex justify-center items-center my-4'>
                                                <img src={login} alt='login' />
                                            </div>
                                            <div className='flex justify-center gap-4'>
                                                {inputRefs.map((inputRef: any, index: any) => (
                                                    <input
                                                        key={index}
                                                        type="text"
                                                        ref={inputRef}
                                                        value={index === 0 ? otp[1] : index === 1 ? otp[2] : index === 2 ? otp[3] : index === 3 ? otp[4] : ''}
                                                        maxLength={1}
                                                        onChange={(event) => handleInput(event, index)}
                                                        className='h-[50px] w-[50px] bg-gray-100 text-center outline-none border focus:border-blue-600 rounded-md shadow-lg'

                                                    />
                                                ))}


                                            </div>

                                            {
                                                (time > 0) ?
                                                    <p className='pt-6 text-1xl md:text-2xl text-blue-400'>00:{time} </p>
                                                    : null
                                            }
                                            <p className='text-gray-500 py-4 mt-4'>
                                                Didn’t you received any code? <br />
                                                <button
                                                    disabled={time > 0}
                                                    className={time === 0 ? 'text-blue-500 cursor-pointer font-bold hover:underline' : 'text-blue-300'}
                                                    onClick={handlResendOtp}> Resend a new code
                                                </button>
                                            </p>
                                            <button
                                                disabled={(otp[1] && otp[2] && otp[3] && otp[4]) ? false : true}
                                                className={(otp[1] && otp[2] && otp[3] && otp[4]) ?
                                                    'w-full border rounded-md bg-sky-600 hover:bg-sky-700 text-white px-4 py-3 mt-8 font-bold' :
                                                    'w-full border rounded-md bg-blue-300 text-white px-4 py-3 mt-8  font-bold'
                                                }

                                                onClick={handlVerifyOtp}> Verify</button>
                                        </div>
                                }

                            </div>
                        </div>
                        :
                        <div className='w-full md:w-[40%] p-6'  >

                            <Link to='/'>
                                <p className='hover:cursor-pointer' >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                    </svg>

                                </p>
                            </Link>
                            <p className='text-1xl md:text-2xl font-bold text-center'> {params.get('isfromcheckout') ? 'Please sign up here to purchase YOVI tickets' : `Welcome to Sign Up`}</p>
                            {
                                loading ?
                                    <Loader />
                                    :

                                    <div className='my-4'>

                                        <div className='max-h-[600px] md:max-h-[70vh] overflow-scroll overflow-x-hidden px-8'>
                                            <div className='flex items-start mt-8'>
                                                <div className='border-dashed border-2 border-gray-300 h-[100px] w-[100px]  flex justify-center items-center'>
                                                    {preview ?
                                                        <div>
                                                            <img src={preview} alt="Profile pic" className='h-[100px] w-[100px]' />
                                                        </div>
                                                        :
                                                        <div className=''>
                                                            <label htmlFor="fileInput" className='w-[100px] cursor-pointer'><CameraIcon /> </label>
                                                            <input type="file" id='fileInput' accept='image/*' onChange={handleImageChange} style={{ display: 'none' }} />
                                                        </div>

                                                    }
                                                </div>
                                                {
                                                    preview ?
                                                        <span
                                                            className='hover:cursor-pointer '
                                                            style={{ marginLeft: '-13px', marginTop: '-8px' }}
                                                            onClick={() => {
                                                                setPreview('')
                                                                setPreview1('')
                                                            }} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-5 h-5 bg-blue-500 rounded-full">
                                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                            </svg>
                                                        </span>
                                                        :
                                                        <><span className='hover:cursor-pointer' style={{ marginLeft: '-12px', marginTop: '-6px' }}>
                                                            <label htmlFor="fileInput" className='w-[100px] cursor-pointer'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-5 h-5 bg-blue-500 rounded-full">
                                                                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                                                                </svg>
                                                            </label>
                                                            <input type="file" id='fileInput' accept='image/*' onChange={handleImageChange} style={{ display: 'none' }} />
                                                        </span>
                                                            <span className='text-red-500 ml-1 -mt-2'> *</span></>

                                                }
                                            </div>
                                            {
                                                errors.pic && <span className='text-sm text-red-500 py-1'> {errors.pic}</span>
                                            }
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
                                                <div className=''>
                                                    <p className='text-gray-500 pb-1'> Enter First Name <span className='text-red-500'> *</span> </p>
                                                    <input type='text'
                                                        className='bg-gray-100 px-4 py-2 w-full'
                                                        placeholder='enter first name'
                                                        value={data?.fname}
                                                        name='fname'
                                                        onChange={(e) => {
                                                            setData({ ...data, fname: e.target.value })
                                                            checkError(e.target)
                                                        }
                                                        }
                                                    />
                                                    {
                                                        errors?.name && <span className='text-sm text-red-500'> {errors?.name}</span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    <p className='text-gray-500 pb-1'> Enter Last Name</p>
                                                    <input type='text'
                                                        className='bg-gray-100 px-4 py-2 w-full'
                                                        placeholder='enter last name'
                                                        value={data?.lname}
                                                        onChange={(e) => setData({ ...data, lname: e.target.value })}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='text-gray-500 pb-1'> Enter city
                                                        {/* <span className='text-red-500'> *</span> */}
                                                        <Conditionalspan />
                                                    </p>
                                                    <input type='text'
                                                        className='bg-gray-100 px-4 py-2 w-full'
                                                        placeholder='enter city'
                                                        name='city'
                                                        value={data?.city}
                                                        onChange={(e) => {
                                                            checkError(e.target)
                                                            setData({ ...data, city: e.target.value })
                                                        }
                                                        }
                                                    />
                                                    {
                                                        errors?.city && <span className='text-sm text-red-500'>{errors?.city}</span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    <p className='text-gray-500 pb-1'> Enter state
                                                        {/* <span className='text-red-500'> *</span> */}
                                                        <Conditionalspan />
                                                    </p>
                                                    <input type='text'
                                                        className='bg-gray-100 px-4 py-2 w-full'
                                                        placeholder='enter state'
                                                        name='state'
                                                        value={data?.state}
                                                        onChange={(e) => {
                                                            checkError(e.target)
                                                            setData({ ...data, state: e.target.value })
                                                        }
                                                        }
                                                    />
                                                    {
                                                        errors?.state && <span className='text-sm text-red-500'> {errors?.state}</span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    <p className='text-gray-500 pb-1'> Enter Country
                                                        {/* <span className='text-red-500'> *</span> */}
                                                        <Conditionalspan />
                                                    </p>
                                                    <input type='text'
                                                        className='bg-gray-100 px-4 py-2 w-full'
                                                        placeholder='enter country'
                                                        name='country'
                                                        value={data?.country}
                                                        onChange={(e) => {

                                                            checkError(e.target)
                                                            setData({ ...data, country: e.target.value })
                                                        }
                                                        }
                                                    />
                                                    {
                                                        errors?.country && <span className='text-sm text-red-500'> {errors?.country}</span>
                                                    }
                                                </div>

                                                <div className=''>
                                                    <p className='text-gray-500 pb-1'> Enter Email <span className='text-red-500'> *</span></p>
                                                    <input type='email'
                                                        className='bg-gray-100 px-4 py-2 w-full'
                                                        placeholder='enter email'
                                                        value={data?.email}
                                                        name='email'
                                                        onBlur={() => {
                                                            // checkError()
                                                            checkEmailExist(data?.email)
                                                        }
                                                        }
                                                        onChange={(e) => {
                                                            setData({ ...data, email: e.target.value })
                                                            checkError(e.target)

                                                        }

                                                        }
                                                    />
                                                    {
                                                        errors?.email && <span className='text-sm text-red-500'> {errors?.email}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className='my-4'>
                                                <p className='text-gray-500 pb-1'> Select gender
                                                    {/* <span className='text-red-500'> *</span> */}
                                                    <Conditionalspan />
                                                </p>
                                                <div className='flex gap-4 flex-wrap'>
                                                    <div>
                                                        <button className={data?.gender === '1' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                                            onClick={(e) => setData({ ...data, gender: '1' })}
                                                        >
                                                            <p>Male </p>
                                                            {
                                                                data?.gender === '1' && <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                    </svg>
                                                                </span>
                                                            }
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button className={data?.gender === '2' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                                            onClick={(e) => setData({ ...data, gender: '2' })}
                                                        >
                                                            <p>Female </p>
                                                            {
                                                                data?.gender === '2' && <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                    </svg>
                                                                </span>
                                                            }

                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button className={data?.gender === '3' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                                            onClick={(e) => setData({ ...data, gender: '3' })}
                                                        >
                                                            <p>   Nonbinary or Genderqueer </p>
                                                            {
                                                                data?.gender === '3' && <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                    </svg>
                                                                </span>
                                                            }

                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button className={data?.gender === '4' ? 'border px-4 py-1 rounded-md  bg-blue-500 text-white items-center flex gap-2' : 'border bg-gray-200 px-4 py-1 rounded-md text-gray-600 hover:bg-blue-500 hover:text-white'}
                                                            onClick={(e) => setData({ ...data, gender: '4' })}
                                                        >
                                                            <p>Do not wish to share</p>
                                                            {
                                                                data?.gender === '4' && <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                    </svg>
                                                                </span>
                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex justify-between flex-wrap gap-4'>
                                                <div className=''>
                                                    <p className='text-gray-500 pb-1'> Select date of birth <span className='text-red-500'> *</span></p>
                                                    <input type='date'
                                                        className='bg-gray-100 px-4 py-2 w-full'
                                                        value={data?.dob}
                                                        name='dob'
                                                        onChange={(e) => {
                                                            checkError(e.target)
                                                            setData({ ...data, dob: e.target.value })
                                                        }}

                                                        min={dateRange?.maxDate}
                                                        max={dateRange?.minDate}
                                                    />
                                                    {
                                                        errors?.dob && <span className='text-sm text-red-500'> {errors?.dob}</span>
                                                    }
                                                </div>
                                                <div className=''>
                                                    <p className='text-gray-500 pb-2'>
                                                        Enter phone number <span className='text-red-500'> *</span>
                                                    </p>
                                                    <BasePhoneNumberInput
                                                        val={phone?.mobile}
                                                        setval={handlePhone}
                                                        err={checkNumber}
                                                        duplicate={checkNumberExist}
                                                    />
                                                    {
                                                        errors?.number && <span className='text-sm text-red-500'> {errors?.number}</span>
                                                    }
                                                </div>
                                            </div>

                                            <div className='w-full my-4'>
                                                <p className='text-gray-500 pb-1'>What is your ethnicity ?
                                                    {/* <span className='text-red-500'> *</span> */}
                                                    <Conditionalspan />
                                                </p>
                                                <div className='flex  flex-wrap gap-3 py-2'>
                                                    {
                                                        enthnicityList?.map((item: any, i: any) => (
                                                            <div>

                                                                <button className={data?.ethnicity === `${item}` ? 'border px-4 py-2 rounded-md  bg-blue-500 text-white items-center flex gap-2'
                                                                    : 'border border-blue-500  bg-white px-4 py-2 rounded-md text-blue-500 hover:bg-blue-500 hover:text-white'
                                                                }
                                                                    onClick={() => {
                                                                        setData({ ...data, ethnicity: `${item}` })
                                                                        setErrors({
                                                                            ...errors,
                                                                            ethnicity: ''
                                                                        })
                                                                    }
                                                                    }
                                                                >
                                                                    {item}

                                                                </button>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    errors?.ethnicity && <span className='text-sm text-red-500'> {errors?.ethnicity}</span>
                                                }
                                            </div>
                                            <div className='w-full my-4'>
                                                <p className='text-gray-500 pb-1'>Prompt Questions Answers
                                                    {/* <span className='text-red-500'> *</span> */}
                                                    <Conditionalspan />
                                                </p>
                                                {
                                                    errors?.propmt && <span className='text-sm text-red-500'> {errors?.propmt}</span>
                                                }
                                                <div className='w-full py-2'>
                                                    {
                                                        propmtQ.map((item: any, i: any) => (
                                                            <div className='px-4 py-2 border-dashed border-2 border-blue-400 rounded-sm mb-4' key={i}>
                                                                <p className='text-gray-600'>{item?.question}</p>
                                                                {
                                                                    i === 0 ?
                                                                        <input type='text'
                                                                            className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                            placeholder='Enter answer'
                                                                            value={propmtAns.ans1}
                                                                            onChange={(e) => {
                                                                                setTimeout(() => {
                                                                                    if (e.target.value.length > 2) {
                                                                                        setErrors({
                                                                                            ...errors,
                                                                                            propmt: ''
                                                                                        })
                                                                                    }

                                                                                }, 500);

                                                                                setPromptAns({ ...propmtAns, ans1: e.target.value })
                                                                            }
                                                                            }
                                                                        />
                                                                        :
                                                                        i === 1 ?
                                                                            <input type='text'
                                                                                className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                                placeholder='Enter answer'
                                                                                value={propmtAns.ans2}
                                                                                onChange={(e) => {

                                                                                    setTimeout(() => {
                                                                                        if (e.target.value.length > 2) {
                                                                                            setErrors({
                                                                                                ...errors,
                                                                                                propmt: ''
                                                                                            })
                                                                                        }

                                                                                    }, 500);
                                                                                    setPromptAns({ ...propmtAns, ans2: e.target.value })
                                                                                }
                                                                                }
                                                                            />
                                                                            :
                                                                            i === 2 ?
                                                                                <input type='text'
                                                                                    className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                                    placeholder='Enter answer'
                                                                                    value={propmtAns.ans3}
                                                                                    onChange={(e) => {
                                                                                        setTimeout(() => {
                                                                                            if (e.target.value.length > 2) {
                                                                                                setErrors({
                                                                                                    ...errors,
                                                                                                    propmt: ''
                                                                                                })
                                                                                            }

                                                                                        }, 500);
                                                                                        setPromptAns({ ...propmtAns, ans3: e.target.value })
                                                                                    }

                                                                                    }
                                                                                />
                                                                                :
                                                                                i === 3 ?
                                                                                    <input type='text'
                                                                                        className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                                        placeholder='Enter answer'
                                                                                        value={propmtAns.ans4}
                                                                                        onChange={(e) => {
                                                                                            setTimeout(() => {
                                                                                                if (e.target.value.length > 2) {
                                                                                                    setErrors({
                                                                                                        ...errors,
                                                                                                        propmt: ''
                                                                                                    })
                                                                                                }

                                                                                            }, 500);
                                                                                            setPromptAns({ ...propmtAns, ans4: e.target.value })
                                                                                        }

                                                                                        }
                                                                                    />
                                                                                    :
                                                                                    i === 4 ?
                                                                                        <input type='text'
                                                                                            className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                                            placeholder='Enter answer'
                                                                                            value={propmtAns.ans5}
                                                                                            onChange={(e) => {
                                                                                                setTimeout(() => {
                                                                                                    if (e.target.value.length > 2) {
                                                                                                        setErrors({
                                                                                                            ...errors,
                                                                                                            propmt: ''
                                                                                                        })
                                                                                                    }

                                                                                                }, 500);
                                                                                                setPromptAns({ ...propmtAns, ans5: e.target.value })
                                                                                            }

                                                                                            }
                                                                                        />
                                                                                        :
                                                                                        <input type='text'
                                                                                            className='outline-none py-2 px-4 bg-gray-100 text-gray-700 w-full'
                                                                                            placeholder='Enter answer'
                                                                                            value={propmtAns.ans6}
                                                                                            onChange={(e) => {
                                                                                                setTimeout(() => {
                                                                                                    if (e.target.value.length > 2) {
                                                                                                        setErrors({
                                                                                                            ...errors,
                                                                                                            propmt: ''
                                                                                                        })
                                                                                                    }

                                                                                                }, 500);
                                                                                                setPromptAns({ ...propmtAns, ans6: e.target.value })


                                                                                            }
                                                                                            }
                                                                                        />
                                                                }

                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                                <div className='p-2 flex items-center'>
                                                    <input type='checkbox'
                                                        className='h-[20px] w-[20px]'
                                                        checked={showPrompted}
                                                        onChange={(e) => setshowPrompted(e.target.checked)}
                                                    />
                                                    <p className='pl-4 text-gray-600'>I Want To Hide My Answers From Other Users</p>
                                                </div>
                                                <div className='p-2 flex items-center'>
                                                    <input type='checkbox'
                                                        className='h-[20px] w-[20px]'
                                                        checked={isTC}
                                                        onChange={(e) => setIsTC(e.target.checked)}
                                                    />
                                                    <p className='pl-4 text-gray-600'>
                                                        <a className='cursor-pointer text-sky-600 hover:text-sky-500' href={apiConstant.TC} target='_blank'>Terms and Conditions </a>
                                                        & <a className='cursor-pointer text-sky-600 hover:text-sky-500 mr-2'
                                                            href={apiConstant.PRIVACY} target='_blank'>Privacy and Policy </a>
                                                        I Agree
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-full flex justify-center'>
                                            <button
                                                disabled={(errors?.name || errors?.number || errors?.email || !isTC) ? true : false}
                                                className=
                                                {
                                                    (errors?.name || errors?.number || errors?.email || !isTC) ?
                                                        'w-[85%] text-center rounded-md font-bold  text-white bg-blue-300  px-4 py-3 mt-8'
                                                        :
                                                        'w-[85%] text-center rounded-md font-bold  text-white bg-sky-600 hover:bg-sky-700 px-4 py-3 mt-8'
                                                }
                                                onClick={() => handleSendOtp(false)}> Next</button>
                                        </div>
                                        <div className='mt-6 text-center'>
                                            <span className='text-gray-500'>
                                                if you have already account please
                                            </span>
                                            <Link to={`/login` + (selectedEvent ? `?selectedevent=${selectedEvent}` : '') + (params.get('isfromcheckout') ? `&isfromcheckout=1` : '')}>
                                                <span className='text-sky-500 pl-2 text-1xl font-bold hover:text-sky-700 cursor-pointer hover:underline'>
                                                    Login
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                            }
                        </div>
                }
            </div>
            <ToastContainer />
        </div >
    )
}

export default SignUp