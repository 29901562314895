import { createSlice,PayloadAction } from '@reduxjs/toolkit';

interface State {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
};

// Create a slice for managing the data state
const userSlice = createSlice({
  name: 'user',
  initialState,
  //method-1 : without using createAsyncThunk reducer
  reducers: {
    fetchDataRequest: (state) => {
      state.loading=true
    },
    fetchDataError: (state,action) => {
      state.error=action.payload
      state.loading=false
    },
    fetchDataSuccess: (state, action:PayloadAction<any>) => {
      state.data = action.payload;
      state.loading=false
      state.error=null
    },
    logoutUser: (state) => {
      return {
        ...state,
        data: null,
        loading: false
      };
    }
  }
});

export const { fetchDataRequest, fetchDataError, fetchDataSuccess ,logoutUser} = userSlice.actions;

export default userSlice.reducer;
