import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import banner from '.././assests/images/banner.png'
import selectionLogo from '.././assests/logo/selection_logo.png'
import LazyLoad from 'react-lazy-load';
import { BackIcon } from '../assests/icon/customIcon'
import { AppDispatch } from '../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../store/features/user/userSelectors'
import { fetchUserData } from '../services/userApi/user'
import { apiConstant } from '../contants/apiContants';
import { getIsViewTicket, getisselectedEventrequireallInfo } from '../store/features/event/eventSelector';
import { logoutUser } from '../store/features/user/userSlice';
import Navbar from './Navbar';
import { setIsViewTicket } from '../store/features/event/eventSlice';

function Header(props: any) {
  //const { banner } = props;
  const [isToken, setIsToken] = useState('')
  const [openCard, setOpenCard] = useState(false)
  const [userData, setUserData] = useState({} as any)
  const dispatch = useDispatch<AppDispatch>()
  const userDataStore = useSelector(getUserData);
  const navigate = useNavigate()
  const path = useLocation().pathname
  const { eventId } = useParams()
  const [isMobile, setIsMobile] = useState(false)
  const isSelectedEventRequireAlldata = useSelector(getisselectedEventrequireallInfo)
  const isViewTickets = useSelector(getIsViewTicket)
  useEffect(() => {
    let getToken = localStorage.getItem('token')
    if (getToken) {
      setIsToken(getToken)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    console.log('isToken', userDataStore.data)
    if (isToken && userDataStore.data === null) {
      dispatch(fetchUserData())
    }
  }, [isToken])

  useEffect(() => {
    let datafromRedux = userDataStore
    if (datafromRedux?.data && isToken) {
      setUserData(datafromRedux?.data)
    }
  }, [isToken, userDataStore])

  const handleLogOut = () => {
    localStorage.removeItem('token')
    dispatch(logoutUser())
    setIsToken('')
    if (path === '/my-tickets') {
      navigate('/')
    }
  }
  console.log(userData, 'userData')
  return (
    <div className="relative">
      <div className="relative ">
        <div className="w-full  bg-opacity-50 h-[250px] md:h-[52vh] min-h-[380px]  bg-gray-300 flex justify-center items-center overflow-hidden ">
          {props?.bg ? (
            <>
              <LazyLoad threshold={0.95} className="relative wh-100">
                <img src={props?.bg} alt="banner" className="fit_img" />
              </LazyLoad>
              {/* {
                props?.live === '2' &&
                <div className='absolute left-4 md:left-6 bottom-4  md:bottom-12 flex flex-col items-center gap-y-3'>
                <p> <WaitLineIcon /></p>
                <p className='text-white'>  <DolarIcon /> </p>
                <p> <DreesCodeIcon /></p>
                <p><MusicIcon /> </p>
                <p className='px-2 bg-red-500 text-white rounded-sm'> Live</p>
                </div>
              }  */}
            </>
          ) : (
            <div className="relative w-full h-full">
              <LazyLoad threshold={0.95} className="wh-100">
                <img src={props.banner} alt="banner" className="fit_img " />
              </LazyLoad>
              <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>
          )}
        </div>
      </div>
      {
        isToken ? (
          <div>
            <div className="absolute  top-0 flex justify-between items-center w-full md:py-2 px-6 opacity-100 md:opacity-[88%]" style={{ backgroundColor: "#003761" }}>
              <div className="flex items-center gap-3">
                {path !== "/" && (
                  <div
                    className="font-bold text-white cursor-pointer"
                    onClick={() => isViewTickets ? dispatch(setIsViewTicket(false)) : navigate("/")}
                  >
                    <BackIcon />
                  </div>
                )}

                <div>
                  <img
                    src={selectionLogo}
                    alt="logo"
                    className="h-[28px] w-[56px]  md:h-[48px] md:w-[100px] "
                  />
                </div>
              </div>
              {!isMobile && <Navbar token={isToken} />}
              <div className="flex justify-end items-center">
                {
                  isMobile ? '' : <button>
                    <span className=" bg-[#0076CD] text-sm lg:text-base px-2 py-1 lg:px-5 lg:py-3 rounded-lg hover:bg-[#005480] text-white hover:duration-200 drop-shadow-sm mr-5"
                      onClick={() => navigate("/my-tickets")}
                    >
                      MY TICKETS
                    </span>
                  </button>
                }
                <div>
                  <div
                    className="cursor-pointer"
                    onClick={() => setOpenCard(!openCard)}
                  >
                    {userData?.profileCoverPhotos?.length > 0 ? (
                      <LazyLoad threshold={0.95}>
                        <img
                          className="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full justify-end mx-auto"
                          src={userData?.profileCoverPhotos[0]?.url}
                          alt="dp"
                        />
                      </LazyLoad>
                    ) : (
                      <div className="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full mx-auto bg-gray-300 border shadow-lg flex justify-center items-center">
                        <p>
                          {" "}
                          {userData?.firstName?.split("")[0]?.toUpperCase() ||
                            " "}{" "}
                          {userData?.lastName?.split("")[0]?.toUpperCase() || " "}
                        </p>
                      </div>
                    )}
                    {openCard && (
                      <div className="border z-50 text-gray-600 text-sm md:text-1xl rounded-md bg-gray-100 shadow-xl py-2 md:py-4 mt-4 md:mt-8 absolute top-4 md:top-12 right-6 md:right-10 cursor-pointer">
                        <Link to="/my-tickets">
                          <p
                            onClick={() => setOpenCard(!openCard)}
                            className="pb-1 md:pt-2 px-4 hover:bg-gray-200"
                          >
                            <Link to="/my-tickets"> My Tickets </Link>
                          </p>
                        </Link>

                        <Link to="/user-profile">
                          <p
                            onClick={() => setOpenCard(!openCard)}
                            className="pb-1 md:pt-2 px-4 hover:bg-gray-200"
                          >
                            <Link to="/user-profile"> Edit Profile </Link>
                          </p>
                        </Link>

                        <p className="pb-1 md:py-2 px-4 hover:bg-gray-200">
                          <a href={apiConstant.PRIVACY} target="_blank">
                            Privacy
                          </a>
                        </p>
                        <p className="pb-1 md:py-2 px-4 hover:bg-gray-200">
                          <a href={apiConstant.GUIDELINE} target="_blank">
                            Guidelines
                          </a>
                        </p>
                        <p className="pb-1 md:py-2 px-4 hover:bg-gray-200">
                          <a href={apiConstant.SAFETYCENTER} target="_blank">
                            {" "}
                            Safty center/tips
                          </a>
                        </p>

                        <p
                          onClick={handleLogOut}
                          className="pb-1 md:py-2 px-4 hover:bg-gray-200"
                        >
                          {" "}
                          Logout
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {isMobile && <Navbar token={isToken} />}
              </div >
            </div >
          </div >
        ) : (
          <div className="absolute top-0 w-full z-50 md:px-4 opacity-100 md:opacity-[88%]" style={{ backgroundColor: "#003761" }}>
            <div className="flex justify-between items-center px-2 lg:px-8  md:py-4">
              <div className="flex items-center gap-3">
                {path !== "/" && (
                  <div
                    className="font-bold text-white cursor-pointer"
                    onClick={() => navigate("/")}
                  >
                    <BackIcon />
                  </div>
                )}
                <div>
                  <img
                    src={selectionLogo}
                    alt="logo"
                    className="h-[24px] w-[56px]  md:h-[64px] md:w-[115px] md:px-5 md:py-3"
                  />
                </div>
              </div>

              {!isMobile && <Navbar token={isToken} />}

              {!isMobile && <div className="flex gap-2 lg:gap-6 flex-col lg:flex-row ">
                <button onClick={() => {
                  const isuserSelectedTicket = document.getElementById('payment-title')
                  navigate("/login" + (eventId ? `?selectedevent=${eventId}` : '') + (isuserSelectedTicket ? `&isfromcheckout=1` : ''))
                }}>
                  <span className="cursor-pointer bg-white text-[#0076CD] text-sm lg:text-base px-2 py-1 lg:px-5 lg:py-3 rounded-lg hover:bg-[#005480] hover:duration-200 drop-shadow-sm">
                    Login
                  </span>
                </button>
                <button onClick={() => {
                  const isuserSelectedTicket = document.getElementById('payment-title')
                  navigate("/sign-up" + (eventId ? `?selectedevent=${eventId}` : '') + (isuserSelectedTicket ? `&isfromcheckout=1` : '') + (isuserSelectedTicket && isSelectedEventRequireAlldata ? '&additionalInfo=1' : ''))
                }}>
                  <span className="cursor-pointer bg-[#0076CD] text-sm lg:text-base px-2 py-1 lg:px-5 lg:py-3 rounded-lg hover:bg-[#005480] text-white hover:duration-200 drop-shadow-sm">
                    Sign Up
                  </span >
                </button >
              </div >}
              {isMobile &&


                <Navbar token={isToken} />}
            </div >
          </div >
        )
      }


      {
        props?.name ? (
          <div className="absolute top-1/2 mt-4 md:mt-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-xl md:text-5xl font-medium">
            <p className="pt-4 md:p-0 font-medium font-display md:text-base  text-center text-sm mt-2.5 md:mt-20">{props?.header}</p>
            <p className=" md:p-3 text-center  font-medium"> {props?.name}</p>
          </div>
        ) : (
          <div className="absolute bottom-12 left-20 text-white text-1xl md:text-7xl">
            <p>
              {" "}
              TOP <span className="font-bold"> EVENTS</span>
            </p>
            <p className=""> AT YOUR LOCATION</p>
          </div>
        )
      }
    </div >
  );
}

export default Header