import moment from "moment";
import CryptoJS from 'crypto-js';

export const convertTimeStampToDateTime = (dt: Date) => {
    if (dt) {
        const date = dt;
        const options = {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        } as any;

        const formattedDate = date.toLocaleString('en-US', options)
        // Output: "Sun, July 10, 8:00 PM"
        return formattedDate
    }
}

export const convertTimestampToDate = (time: Date) => {
    const date = time;
    const options = {
        year: 'numeric', month: 'long', day: 'numeric'
    } as any;
    let d = new Date(date.toLocaleString('en-US', { timeZone: 'GMT' }))
    const formattedDate = d.toLocaleString('en-US', options);  // Output: "Sun, July 10, 
    return formattedDate
}

export const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours() as any;
    let minutes = now.getMinutes() as any;
    // Add leading zero if needed
    hours = (hours < 10 ? '0' : '') + hours;
    minutes = (minutes < 10 ? '0' : '') + minutes;
    // console.log(`${hours}${minutes}`)
    return `${hours}${minutes}`;

}

export const getCurrentDateTimestamp = () => {
    const now = new Date()
    const utcMileSecond = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    const timestamp = utcMileSecond
    return timestamp
}

export function convertLocalDateToUTCIgnoringTimezone(date: Date) {
    const timestamp = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        // date.getHours(),
        // date.getMinutes(),
        // date.getSeconds(),
        // date.getMilliseconds(),
    );

    return timestamp;
}

export function convertUTCToLocalDateIgnoringTimezone(utcDate: Date) {
    return new Date(
        utcDate.getUTCFullYear(),
        utcDate.getUTCMonth(),
        utcDate.getUTCDate(),
        // utcDate.getUTCHours(),
        // utcDate.getUTCMinutes(),
        // utcDate.getUTCSeconds(),
        // utcDate.getUTCMilliseconds(),
    );
}

export function isValidNumber(number: any) {
    if (number.toString().length !== 10) {
        return false;
    }
    if (/^0{10}$/.test(number.toString())) {
        return false;
    }
    // if(/^[0-9]*$/.test(number)){
    //     return false
    // }
    return true;
}

export const convertTimeStampToDateTimeforTicket = (dt: any) => {
    if (dt) {
        const timestamp = dt;
        const date = new Date(timestamp);

        const options = {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        } as any;

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

        // Output: "Sun, July 10, 8:00 PM"
        return formattedDate
    }
}

export const convertDateStringforEventPage = (date:string,time:string)=>{
    const normalizeUTCDate = moment.utc(date);
    const Date = normalizeUTCDate.format('ddd, MMMM D');
    const Time = moment(time || 0, 'HHmm').format('hh:mm A')
    return `${Date} at ${Time} `
}



// Function to encode a user ID
export function encodeUserId(userId:string) {
    const cipherText = CryptoJS.AES.encrypt(String(userId), 'y0Vi', {
        mode: CryptoJS.mode.ECB,
        keySize: 128 / 32
      }).toString();
      // Replace '+' with '-', '/' with '_', and '=' with '', globally
      const urlSafeCipherText = cipherText.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
      return urlSafeCipherText;
  }
  
  // Function to decode a user ID
 export function decodeUserId(urlSafeBase64:string) {
    // Replace '-' with '+', '_' with '/', and pad with '=', globally
  // Replace '-' with '+', '_' with '/', and pad with '=', globally
  const cipherText = urlSafeBase64.replace(/-/g, '+').replace(/_/g, '/').padEnd(urlSafeBase64.length + (4 - urlSafeBase64.length % 4), '=');
  const bytes = CryptoJS.AES.decrypt(cipherText, 'y0Vi', {
    mode: CryptoJS.mode.ECB,
    keySize: 128 / 32
  });
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return (originalText);
  }