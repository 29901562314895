
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LocationIcon } from '../assests/icon/customIcon';
import LazyLoad from 'react-lazy-load';
import { convertTimestampToDate } from '../utils/commonFunction';

interface EventCardProps {
  item: {
    startDate: string;
    ticketArray: any[];
    venueData: {
      profileCoverPhotos: { thumbUrl: string }[];
      publicBusinessName: string;
    };
    eventName: string;
    eventImage: string;
    address: string;
    eventLocationTitle: string;
    _id: string;
  };
  tab: number;
}

function EventCard({ item, tab }: EventCardProps) {
  const cd = item.startDate;
  const ticketArray = item.ticketArray;
  const navigate = useNavigate();

  const calculatePrice = (ticketArray: any[]) => {
    let minAmount = 0;
    let maxAmount = 0;

    if (ticketArray.length > 0) {
      minAmount = ticketArray[0]?.ticketAdmissionAmount || 0;
      maxAmount = ticketArray[0]?.ticketAdmissionAmount || 0;

      for (let i = 1; i < ticketArray.length; i++) {
        const ticketAdmissionAmount = ticketArray[i]?.ticketAdmissionAmount;
        if (ticketAdmissionAmount !== null) {
          if (ticketAdmissionAmount < minAmount) {
            minAmount = ticketAdmissionAmount;
          }
          if (ticketAdmissionAmount > maxAmount) {
            maxAmount = ticketAdmissionAmount;
          }
        }
      }
    }

    return {
      minPrice: minAmount,
      maxPrice: maxAmount
    };
  };

  const handleEventDetails = (id: string) => {
    navigate(`event/${id}`);
  };

  const { minPrice, maxPrice } = calculatePrice(ticketArray);

  return (
    <div className='rounded-md shadow-md hover:shadow-2xl mt-3 cursor-pointer' onClick={() => handleEventDetails(item._id)}>
      <div className='flex items-center gap-3 bg-gray-50 p-1'>
        {item.venueData.profileCoverPhotos.length > 0 ? (
          <div className='w-[40px] h-[40px] md:w-[65px] md:h-[65px] rounded-full bg-gray-200'>
            <LazyLoad threshold={0.95}>
              <img className="w-[40px] h-[40px] md:w-[65px] md:h-[65px] rounded-full mx-auto object-cover" src={item.venueData.profileCoverPhotos[0]?.thumbUrl} alt="dp" />
            </LazyLoad>
          </div>
        ) : (
          <div className="w-[40px] h-[40px] md:w-[80px] md:h-[80px] rounded-full mx-auto bg-gray-200 border shadow-lg flex justify-center items-center">
            <p> {item.eventName?.split('')[0]?.toUpperCase() || ' '}</p>
          </div>
        )}
        <p className='texr-1xl md:text-2xl font-bold pb-2 truncate max-w-[300px] md:max-w-[400px]'>{item.eventName}</p>
      </div>

      <div className='w-full h-[250px] md:h-[300px] bg-gray-200 flex justify
              center items-center overflow-hidden relative '>
        <LazyLoad threshold={0.95} className='wh-100'>
          <img
            src={item.eventImage}
            className='fit_img'
            alt='event img'
          />
        </LazyLoad>
      </div>

      <div className='p-4 md:6 bg-gray-50'>
        <p className='texr-1xl md:text-2xl pb-2 truncate max-w-[300px] md:max-w-[500px]'>{item.venueData.publicBusinessName}</p>

        <div className='text-gray-500 pb-2 flex'>
          <LocationIcon />
          <span className='pl-3 truncate max-w-[300px] md:max-w-[500px]'>{item.address}</span>
        </div>

        <div className='text-gray-500 pb-2 border-b-2 flex'>
          <LocationIcon />
          <span className='pl-3'>{item.eventLocationTitle}</span>
        </div>

        <div className='flex justify-between'>
          <p className='text-gray-500 py-2'>Event Start Date: <span className='text-black'>{convertTimestampToDate(new Date(cd))}</span></p>
          <p className='text-gray-500 py-2'>
            <span className='font-bold text-black text-1xl'>
              {minPrice === maxPrice && minPrice === 0
                ? 'FREE'
                : minPrice === maxPrice
                  ? `$${minPrice.toFixed(2)}`
                  : `$${minPrice.toFixed(2)} - $${maxPrice.toFixed(2)}`}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
