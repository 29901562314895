import axios from "axios";
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import yovi from "../../assests/logo/YOVI_LOGO_FINAL.png";
// import cancelIcon from "../../assests/icon/cancel-icon.svg";
import { CloseIcon } from "../../assests/icon/customIcon"
import star from "../../assests/icon/yellow-star.png";
import { useDispatch, useSelector, } from "react-redux";
import { setRating } from "../../store/features/ios/appmeta";
import { getRating } from "../../store/features/ios/appmetaSelector";
import { getUserData } from "../../store/features/user/userSelectors";

const IosLink = ({ buttonText, urlType }: any) => {
  let token = localStorage.getItem("token");
  const { data } = useSelector(getUserData);
  const [isVisible, setIsVisible] = useState(false);
  const averageRating = useSelector(getRating)
  const dispatch = useDispatch();
  const appId = process.env.REACT_APP_ITUNES_API_ID;;
  const popupvisibility = Cookies.get('showiosPopup') === 'false' ? false : true;
  const fetchAppStoreData = async () => {
    try {
      const response = await axios.get(
        `https://itunes.apple.com/lookup?id=${appId}`
      );
      const rating = response.data.results[0]?.averageUserRating ?? 0;
      dispatch(setRating(rating));
    } catch (error) {
      console.error("Error fetching App Store data:", error);
    }
  };

  // if (averageRating === null) {
  //   fetchAppStoreData();
  // }


  // useEffect(() => {
  //   if (averageRating === null) {
  //     fetchAppStoreData();
  //   }
  // }, [averageRating]);

  useEffect(() => {

    const checkVisibility = () => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isSmallScreen = window.innerWidth <= 768;
      setIsVisible(isIOS && isSmallScreen);
    };
    const timer = setTimeout(() => {
      checkVisibility();
    }, 5000);
    window.addEventListener("orientationchange", checkVisibility);
    window.addEventListener("resize", checkVisibility);
    return () => {
      window.removeEventListener("orientationchange", checkVisibility);
      clearTimeout(timer)
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);




  const CreateLink = async (urlType: string) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const domain = process.env.REACT_APP_ENV === 'prod' ? "findyovi.com" : "stag.dingapp.net";
    console.log(domain, "domain")
    let link;
    if (urlType === "ticketedEvents") {
      link = `https://${domain}?action=ticketedEvents`;
    } else if (urlType === "suggestedConnections") {
      link = `https://${domain}?action=suggestedConnections&isSuggest=1`;
    } else {
      return;
    }
    if (token && data?.userId) {
      link += `&token=${token}&userId=${data.userId}&mobile=${data.mobile}`;
    }


    if (token && data?.userId) {
      link += `&token=${token}&userId=${data.userId}&mobile=${data.mobile}`;
    }


    try {
      const response = await axios.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
        {
          dynamicLinkInfo: {
            domainUriPrefix: "https://findyovi.page.link",
            link,
            iosInfo: {
              iosBundleId: "com.dingllc.ding",
              // iosFallbackLink: "https://apps.apple.com/in/app/yovi-your-vibe/id1598304858",
              iosAppStoreId: appId,
            },
            navigationInfo: {
              enableForcedRedirect: true,
            },
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(
        "Dynamic link created successfully:",
        response.data.shortLink
      );
      const generatedLink = response.data.shortLink;
      //console.log(generatedLink);
      window.location.href = generatedLink;
    } catch (error) {
      console.error("Error creating dynamic link:", error);
    }
  };
  const CancelIcon = () => {
    Cookies.set('showiosPopup', 'false', { expires: 1 / 3 });
    setIsVisible(false);
  };
  if (!isVisible || !popupvisibility) return null
  return (isVisible && popupvisibility && (<div className="shadow-2xl opacity-100 fixed bottom-0 left-0  w-full z-50 rounded-tl-lg rounded-tr-lg" >
    <div className="bg-white py-3 justify-center flex flex-row gap-[2%] w-screen ios-link-container">
      <div className="bg-white  flex items-center w-12 h-12  -ml-5">
        <img src={yovi} alt="yovi-icon" className="p" />
      </div>

      <div className="flex  justify-center align-middle mt-2 ">
        <div>
          <h3 className="text-whit font-semibold text-sm ">Yovi mobile app</h3>
          {/* {averageRating && (
              <p className="flex mr-11 py-1">
                {Array.from({ length: Math.round(averageRating) }, (_, index) => (
                  <img
                    src={star}
                    key={index}
                    className="w-4 h-4"
                    alt={`star-${index}`}
                  />
                ))}
              </p>
            )} */}
          <p className=" text-xs font-normal">
            {urlType === "ticketedEvents"
              ? "Purchase Tickets and access YOVI "
              : "Connect with similar YOVI users"}

          </p>
        </div>
        <div className="pt-1 ml-3">
          <button
            className="bg-blue-500 text-white font-medium text-[13px] py-2 px-2.5 rounded-2xl"
            onClick={() => CreateLink(urlType)}
          >
            {buttonText}
          </button>
        </div>
      </div>

      <div className="flex items-start">
        <div onClick={CancelIcon} className="w-5 absolute right-[0.8%] h-3 bottom-7">
          <CloseIcon height="15px" width="15px" />
          {/* <img
            src={cancelIcon}
            alt="cancel-icon"
            className="w-6 absolute right-3 h-5 bottom-6"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      </div>
    </div>
  </div >))
}
export default IosLink
