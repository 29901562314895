import moment from 'moment'
import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

function Replies({ reply, addLike, userId, setOption, replyLength, index, loadReplies, totalReply }: any) {
    const [isLiked, setIsLiked] = React.useState(reply.isLiked)
    const [isLoading, setIsLoading] = useState(false);
    const [likeLoading, setLikeLoading] = useState(false)
    const [loadError, setLoadError] = useState(false);
    const [page, setPage] = useState(1)
    const userImage = reply?.userData?.profileCoverPhotos[0]?.url
    const userName = reply?.userData?.businessUser ? reply.userData.businessName : reply?.userData?.firstName + reply?.userData?.lastName
    const lastReply = useRef<HTMLDivElement>(null)
    const observer = useRef<IntersectionObserver>();
    const like = async (status = 0) => {
        setLikeLoading(true)
        try {
            const resp = await addLike({ likedTo: reply._id, eventId: reply._id, status, commentType: 2, type: 2 })
            console.log(resp, "resppppone")
            if (resp === 1) {
                setIsLiked((prev: boolean) => !prev)
            }
        } finally {
            setLikeLoading(false)
        }
    }
    useEffect(() => {
        setIsLiked(reply.isLiked)
    }, [reply.isLiked])

    const loadMore = async () => {
        setIsLoading(true)
        try {
            const resp = await loadReplies(page)
            if (resp === 1) setPage((prev) => prev++)
            else setLoadError(true)
        } catch (error) {
            setLoadError(true)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isLoading || loadError) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && totalReply > replyLength) {
                // loadComments(limit, page)
                loadMore()
                console.log("intersecting")
            }
        });

        if (lastReply.current) {
            observer.current.observe(lastReply.current);
        }
    }, [isLoading, page, loadError]);

    console.log(setOption, "function", reply?.commentId, reply.likes)
    return (
        <div className='ml-16' id={reply?._id}>
            <div className="flex pt-4  justify-between items-center ">
                <div className="flex items-center">
                    <p className="inline-flex items-center mr-3 text-md text-gray-900 font-semibold">  {userImage ? <img
                        className="mr-4 w-[50px] h-[50px] rounded-full"
                        src={userImage}
                        alt="" />
                        : <div className="mr-4  w-[50px] h-[50px] bg-gray-500 rounded-full flex items-center justify-center">
                            <span className="text-lg font-semibold text-gray-900">{userName[0]?.toUpperCase()}</span>
                        </div>}
                        <div>
                            {userName}
                            <p className="text-xs font-normal  text-gray-500 ml-[2px]">
                                {moment(reply?.commentDate).fromNow()}
                            </p>
                        </div></p>
                </div>
                <div className=' text-center flex'>

                    {isLiked ?
                        <button
                            className=" md:static md:inline-flex items-center p-2 text-sm font-medium text-center text-gray-500   rounded-lg hover:bg-gray-100  focus:outline-none focus:ring-gray-50   "
                            onClick={() => like(0)}
                            disabled={likeLoading}
                            type="button">
                            <svg fill="#ff0000" height="16px" width="16px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M372.87,33.391c-46.903,0-90.88,23.598-116.87,62.152c-25.99-38.555-69.967-62.152-116.87-62.152 C62.413,33.391,0,95.804,0,172.522c0,37.935,14.164,73.011,39.88,98.76l200.38,200.804c4.207,4.207,9.794,6.522,15.74,6.522 s11.532-2.315,15.74-6.521l200.314-200.772C497.815,245.522,512,210.435,512,172.522C512,95.804,449.587,33.391,372.87,33.391z"></path> </g> </g> </g></svg>
                        </button> :
                        <button
                            className=" md:static md:inline-flex items-center p-2 text-sm font-medium text-center text-gray-500   rounded-lg hover:bg-gray-100  focus:outline-none focus:ring-gray-50   "

                            type="button"
                            onClick={() => like(1)}
                            disabled={likeLoading}
                        >
                            <svg fill="#6b7280" height="16px" width="16px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 511.996 511.996" xmlSpace="preserve" stroke="#6b7280"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M467.01,64.373c-29.995-29.995-69.299-44.988-108.612-44.988c-36.779,0-73.259,13.662-102.4,39.919 c-29.15-26.257-65.621-39.919-102.4-39.919c-39.313,0-78.618,14.993-108.612,44.988c-59.981,59.981-59.981,157.235,0,217.225 L255.998,492.61L467.01,281.598C526.991,221.609,526.991,124.363,467.01,64.373z M448.919,263.49L255.998,456.403L63.085,263.499 c-49.903-49.911-49.903-131.115,0-181.018c24.175-24.175,56.32-37.487,90.513-37.487c31.206,0,60.399,11.563,83.695,31.889 l18.705,17.485l18.714-17.493c23.296-20.318,52.489-31.889,83.695-31.889c34.193,0,66.33,13.312,90.513,37.487 C498.831,132.375,498.822,213.587,448.919,263.49z"></path> </g> </g> </g></svg>
                        </button>
                    }
                    <p className='py-2'> {reply.likes ? reply.likes : ''}  </p>
                </div>
                {/* <button
                    onClick={() => setOption(comment._id)}
                    hidden={comment.userData._id !== isLogged?._id}
                    className=" md:static md:inline-flex items-center p-2 text-sm font-medium text-center text-gray-500   rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50 "
                >
                    <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 12H18.01M12 12H12.01M6 12H6.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z" stroke="#687280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </button> */}
            </div><pre className="font-sans text-gray-500 flex  items-center mt-6 font-normal ml-1">{reply?.comment}</pre><div className="flex items-center mt-4 space-x-4">
                {/* <p className="text-sm text-gray-500  ml-16">
                    {moment(reply?.commentDate).fromNow().replace('ago', '')}
                </p> */}
                <button hidden={!reply.likes} className='  text-gray-500 text-sm font-semibold cursor-default mr-4'>
                    {reply.likes ? `${reply.likes} ${reply.likes > 1 ? 'people' : 'person'} found this useful` : ''}
                </button>
                {/* <button className=' ml-16  text-gray-500 text-sm font-normal hover:underline'>
                    {reply.likes ? `${reply.likes} Likes` : ''}
                </button> */}
                {reply.userData?._id === userId && <button
                    onClick={() => setOption(reply._id, reply.commentId)}
                    hidden={reply.userData?._id !== userId}
                    className="md:static md:inline-flex font-semibold items-center p-0 text-sm  text-center text-gray-500   rounded-lg hover:bg-gray-10;0 hover:underline focus:underline "
                >
                    Delete
                    {/* <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 12H18.01M12 12H12.01M6 12H6.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z" stroke="#687280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> */}
                </button>}
            </div>
            {replyLength - 1 === index && <div ref={lastReply} />}
        </div >
    )
}

export default Replies