export const appRoute={
    Login:'/login',
    Signup:'/sign-up',
    UserDetails:'/user-profile',
    EventDetails:'/event/:eventId',
    MyTickets:'/my-tickets',
    MyTicket:'/my-tickets/:ticketId',
    TicketDetails:'/my-tickets/:eventId',
    SingleTicket:'/ticket/',
    Profile:'/profile/:id?',
    About:'/aboutus',
    YoviUser:'/user',
    YoviEventCreator:'/eventcreator',
    YoviBusiness:'/business',
    YoviSupport:'/support',
    EventOrganizer:'/event'
}