import Footer from "../../components/Footer";
import Header from "../../components/Header";
import yoviUserBanner from "../../assests/images/Top Photo for new tabs.png";
import YuviSocial from "../../assests/images/YUVI Elevating.png";
import userCard1 from "../../assests/images/UserCard1.png";
import userCard2 from "../../assests/images/UserCard2.png";
import userCard3 from "../../assests/images/Purchase Tickets YOVI USER.png";
import userCard4 from "../../assests/images/Connecting With Others YOVI USER.png";
import userCard5 from "../../assests/images/View The Vibes YOVI User.png";
import userCard6 from "../../assests/images/Share The Vibes YOVI USER.png";
import UserPhone1 from "../../assests/images/UserPhone1.png";
import UserPhone2 from "../../assests/images/UserPhone2.png";
import UserPhone3 from "../../assests/images/UserPhone3.png";
import { useState } from "react";

const YoviUser = () => {
  const [shareVibes, setShareVibes] = useState(false);
  const [viewVibes, setViewVibes] = useState(false);

  const handleShareVibes = () => {
    setShareVibes(!shareVibes);
  };
  const handleViewVibes = () => {
    setViewVibes(!viewVibes);
  };
  return (
    <div>
      <Header banner={yoviUserBanner} name="YOVI: Elevating Social Experiences" header="Yovi User" />
      <div className=" grid grid-cols-1 md:grid-cols-12 my-10 sm:mx-10 lg:mx-auto md:px-16">

        <div className="p-3 lg:mr-14 text-justify flex-col md:col-span-7 mb-8 md:mb-0">
          <h3 className="text-2xl sm:text-3xl text-center md:text-left pb-6 font-medium">
            Empowering Social Engagement and Connection
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left ">
            YOVI is an app for people seeking social experiences through social
            businesses and events. Users can browse participating businesses and
            events to receive real-time data about the crowd, music, dress code,
            cover charge, current wait time, and the overall vibe from people
            who are at those businesses or events. In addition YOVI users have
            the opportunity within the platform to connect with other users who
            attend the same or similar social venues & events as them.
          </p>
        </div>
        <div className="flex  justify-end md:col-span-5">
          <img src={YuviSocial} alt="" className="p-3 w-[516px] h-60 md:h-[310px]" />
        </div>
      </div>
      <div className="md:mt-10 w-full bg-[#EAF3F9]">
        <div className="col-span-1  py-12">
          <div className="w-full md:py-20 p-10 lg:p-0 flex flex-col items-center">
            <h3 className="font-medium text-2xl sm:text-3xl my-4 text-center">
              Check Out Our Features
            </h3>
            {/* <p className="text-lg text-center">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos eaque
              non voluptate aliquid, corporis dolorum debitis aspernatur
              similique totam itaque.
            </p> */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-12 xl:mx-20">
              <div className="flex flex-col">
                <img src={userCard1} alt="" />
                <h3 className="text-2xl sm:text-3xl my-4 font-medium">Find Social Venues & Events</h3>
                <p className="text-sm sm:text-lg">
                  Discover and receive essential details for your favorite
                  social venues & events in the city of your choice. See
                  comments, ratings and likes from other YOVI users as well.
                </p>
              </div>
              <div className="flex flex-col">
                <img src={userCard2} alt="" />
                <h3 className="text-2xl sm:text-3xl my-4 font-medium">Post Photos and Videos</h3>
                <p className="text-sm sm:text-lg">
                  Having a good time out and wanting others to see. YOVI allows
                  you the opportunity to post photos and videos at your favorite
                  venues and events for your YOVI connections to see
                </p>
              </div>
              <div className="flex flex-col">
                <img src={userCard3} alt="" />
                <h3 className="text-2xl sm:text-3xl my-4 font-medium">Purchase Tickets YOVI USER</h3>
                <p className="text-sm sm:text-lg">
                  See an event you want to attend? Feel free to purchase tickets
                  directly off of the YOVI mobile or web version.
                </p>
              </div>
              <div className="flex flex-col">
                <img src={userCard5} alt="" />
                <h3 className="text-2xl sm:text-3xl my-4 font-medium">View The Vibes</h3>
                <p className="text-sm sm:text-lg">
                  Ever wanted to know what the current vibes were at a venue or
                  event before you go? Now you can check and see on YOVI! Before
                  you go to a venue or event get on YOVI and check the vibes. On
                  YOVI at your favorite venues and events you can see in real
                  time the current
                  <br />
                  {viewVibes && (
                    <div className="pl-5">
                      <ul className="list-disc">
                        <li>wait line </li>
                        <li>crowd levels </li>
                        <li>cover charge</li>
                        <li>music being played </li>
                        <li>dress code </li>
                        <li>overall vibe </li>
                        <li>any other important comments</li>
                      </ul>
                    </div>
                  )}
                  <span className="text-blue-500 cursor-pointer" onClick={handleViewVibes}>
                    {viewVibes ? "See less" : "See more"}
                  </span>
                </p>
              </div >
              <div className="flex flex-col">
                <img src={userCard4} alt="" />
                <h3 className="text-2xl sm:text-3xl my-4 font-medium">Connect With YOVI Users</h3>
                <p className="text-sm sm:text-lg">
                  YOVI allows you the opportunity to connect with other YOVI
                  users who attend the same or similar venues & events as you
                  through our rewind, shared vibes and suggested connections
                  features.
                </p>
              </div>
              <div className="flex flex-col">
                <img src={userCard6} alt="" />
                <h3 className="text-2xl sm:text-3xl my-4 font-medium">Share The Vibes</h3>
                <p className="text-sm sm:text-lg">
                  When you arrive at a social venue or event quickly share the
                  vibes so other YOVI users know the vibes there. YOVI allows
                  you to quickly and accurately share the current
                  <br />
                  {shareVibes && (
                    <div className="pl-5">
                      <ul className="list-disc">
                        <li>wait line </li>
                        <li>crowd levels </li>
                        <li>cover charge</li>
                        <li>music being played </li>
                        <li>dress code </li>
                        <li>overall vibe </li>
                        <li>any other important comments</li>
                      </ul>
                    </div>
                  )}
                  <span className="text-blue-500 cursor-pointer" onClick={handleShareVibes}>
                    {shareVibes ? "See less" : "See more"}
                  </span>
                </p>
              </div >
            </div >
          </div >
        </div >
      </div >
      <div className="grid grid-cols-1 lg:grid-cols-2 my-10 md:my-20 lg:mx-20 ">
        <div className="my-auto ">
          <h3 className="text-2xl sm:text-3xl mb-4 text-center lg:text-left font-medium">
            Socialize Smarter
          </h3>
          <p className="text-center text-lg sm:text-xl lg:text-left">
            Real-time insights and connections for unforgettable social
            experiences.
          </p>
        </div>
        <div className="grid grid-cols-3 gap-1 sm:gap-4 mx-auto">
          <img src={UserPhone1} alt="" className="sm:h-96 h-48" />
          <img src={UserPhone2} alt="" className="sm:h-96 h-48" />
          <img src={UserPhone3} alt="" className="sm:h-96 h-48" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default YoviUser;
