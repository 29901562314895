import { createSlice,PayloadAction } from '@reduxjs/toolkit';

interface State {
  data: any;
  loading: boolean;
  error: string | null;
  replies:any,
  page:number
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
replies:{},
page:1
};

// Create a slice for managing the data state
const commentSlice = createSlice({
  name: 'commentList',
  initialState,
  //method-1 : without using createAsyncThunk reducer
  reducers: {
    fetchCommentRequest: (state) => {
      state.loading=true
    },
    fetchCommentError: (state,action) => {
      state.error=action.payload
      state.loading=false
    },
    fetchCommentSuccess: (state, action:PayloadAction<any>) => {
      state.data = action.payload;
      state.loading=false
      state.error=null
    },
    addNewComment:(state,action)=>{
      state.data= [action.payload,...state.data]
      state.loading =false
    },
    addComments:(state,action)=>{
      state.data= [...state.data,...action.payload]
      state.loading =false
    },
    addReply:(state,action)=>{
      state.data[action.payload].replies++
    },
    Like:(state,action)=>{
      const index = state.data.findIndex((comment:any)=> comment._id === action.payload.id)
      action.payload.status? state.data[index].likes++ :state.data[index].likes--
      state.data[index].isLiked = action.payload.status
     },
     loadReply:(state,action)=>{
      state.replies[action.payload._id] = action.payload.data
     },
     addnewReply:(state,action)=>{
      if (!state.replies[action.payload._id]) {
        state.replies[action.payload._id] = [];
    }
      state.replies[action.payload._id] =[action.payload.data,...state.replies[action.payload._id]]
     },
     addReplies:(state,action)=>{
      state.replies[action.payload._id] =[...state.replies[action.payload._id],...action.payload.data]
     },
     incrementPage:(state,action)=>{
      state.page= action.payload
     },
    clearComments:(state)=>{
      state.data =[]
    },
    deleteComment:(state,action)=>{
      state.data = state.data.filter((comment:any)=> comment._id !== action.payload.id)

    },
    deleteReply:(state,action)=>{
      state.data[action.payload.index].replies--
      state.replies[action.payload.parent] = state.replies[action.payload.parent].filter((reply:any)=> reply._id !== action.payload.id)
    },
    likeReply:(state,action)=>{
    const index = state.replies[action.payload.commentId].findIndex((reply:any)=> reply._id === action.payload.reply)
    state.replies[action.payload.commentId][index].likes+= action.payload.value
    }
  }
});

export const { fetchCommentRequest, fetchCommentError, fetchCommentSuccess,clearComments,addComments,addNewComment,addReply,Like,incrementPage } = commentSlice.actions;
export const {addReplies,addnewReply,loadReply,deleteComment,deleteReply,likeReply} = commentSlice.actions
export default commentSlice.reducer;
