import React, { useRef, useState } from 'react'
import DialogBox from '../../components/DialogBox'
import { getReq, postReqJson, } from '../../services/apiCall'
import { apiConstant } from '../../contants/apiContants'
import ConfirmDialogBox from '../../components/ConfirmDialog'
import BasePhoneNumberInput from '../../components/BasePhoneNumberInput'
import { isValidNumber } from '../../utils/commonFunction'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { getUserData } from '../../store/features/user/userSelectors'

function TransferTicket({ setOpen, open, item, setViewTicket, index }: any) {
    console.log(open, "open")
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState({ name: "", phone: "" })
    const [showPromt, setShowPromt] = useState(false)
    const [mobile, setMobile] = useState({ mobile: "", countryCode: "" })
    const nameinputRef = useRef<HTMLInputElement>(null)
    const userDataStore = useSelector(getUserData);

    // const fetchUser = async () => {
    //     setLoading(true)
    //     try {
    //         const { data } = await getReq(`${apiConstant.BASE_URL}/user/getUserDetailsforProfile?mobile=${phone}&userType=1}`)
    //         console.log(data?.responseData, "dataaa")
    //         if (typeof data.responseData !== 'object') return

    //         // setName(data?.responseData?.userProfile?.firstName + " " + data?.responseData?.userProfile?.lastName)
    //     }
    //     catch (error) {
    //         console.log(error, "error")
    //     }
    //     finally {
    //         setLoading(false)
    //     }
    // }
    const handlePhone = (val: string, dialcode: any) => {
        setMobile({ mobile: val, countryCode: dialcode.dialCode })
    }
    const transferTicket = async () => {
        if (loading) return
        const recipientName = nameinputRef.current?.value
        const recipientPhone = mobile.mobile.slice(mobile.countryCode.length)
        const recipientCountry = mobile.countryCode
        try {
            setLoading(true)
            const { paymentId, bookingId } = item
            const resp = await postReqJson(`${apiConstant.BASE_URL}/payment/transferTicket`, { paymentId, bookingId, recipientName, recipientPhone, recipientCountry })
            if (resp?.data?.statusCode) {
                setOpen(false)
                toast.success('The ticket has been transferred successfully')
                // const updateValueAtIndex = (index: number, newValue: string) => {
                setViewTicket((prevState: any) => {
                    const newArray = [...prevState.items]; // Create a copy of the array
                    item.status = 0
                    item.recipientDetail.name = recipientName
                    newArray[index] = item;
                    return { ...prevState, items: newArray }; // Return the updated state object
                });
            }
            else {
                toast.error('Ticket transfer failed')
            }
        } catch (err) {
            toast.error('Ticket transfer failed')
        } finally {
            setOpen(false)
            setLoading(false)
        }
    }

    const validateMobile = (name: any, val: any, ccode: any) => {

        let rawNumber = val.slice(ccode?.dialCode.length)
        if (!rawNumber) {
            setErr({ ...err, phone: "Phone Number is required" })
        } else if (rawNumber === userDataStore.data.mobile) {
            setErr({ ...err, phone: "You can't transfer to your own account" })
        } else if (rawNumber.length > 0) {
            let validNumber = isValidNumber(rawNumber)
            if (validNumber) {
                setErr({ ...err, phone: '' })
            }
            else {
                setErr({ ...err, phone: "Phone Number is invalid" });
            }
        }
    }

    const transferClickHandle = () => {
        if (!mobile.mobile.length || !mobile.mobile.slice(mobile.countryCode.length).length) {
            setErr({ ...err, phone: "Mobile number is required" })
        }
        if (!nameinputRef.current?.value.length) {
            setErr({ ...err, name: "Name is required" })
            return
        }
        if (err.phone || err.name) return
        else {
            setErr({ name: "", phone: "" })
            setShowPromt(true)
        }
    }
    return (
        <DialogBox withClose onClose={() => setOpen(false)} isOpen={open}>
            <div className="w-full ">
                <p className='text-1xl md:text-2xl font-bold text-center mb-4'> Transfer Ticket </p>
                <div className="mb-4 flex flex-col md:flex-row">
                    <label htmlFor="Name" className="block text-gray-700 md:w-[55px] mt-3">Name</label>
                    <input onChange={(e) => {
                        if (e.target.value) {
                            setErr({ ...err, name: "" })
                        }
                    }} placeholder='Enter Name' ref={nameinputRef} type="text" id="Name" className=" mt-1 p-2 border border-gray-300 rounded-md md:w-3/4" />
                </div>
                {err.name && <p className="text-red-500 text-sm mt-1 ml-12 md:mb-2">{err.name}</p>}
                <div className="mb-4 flex flex-col md:flex-row">
                    <label htmlFor="phoneNumber" className="block text-gray-700 md:w-[55px] mt-3"> Phone</label>
                    {/* <input type="text" id="phoneNumber" className="mt-1 p-2 border border-gray-300 rounded-md md:w-3/4" /> */}
                    <BasePhoneNumberInput err={validateMobile} val={mobile.mobile} setval={handlePhone} override className=" bg-white   border-2 border-gray-300 rounded-md !h-10 !w-full md:!w-[252px] lg:!w-[327px]  " />
                </div>
                {err.phone && <p className=" text-red-500 text-sm mt-1 ml-12">{err.phone}</p>}
                <div className="flex justify-center">
                    <button disabled={loading || Boolean(!nameinputRef.current?.value) || Boolean(!mobile.mobile)} onClick={transferClickHandle} className="text-white px-4 py-2  flex justify-center w-3/4 bg-sky-600 hover:bg-sky-500 rounded-md mt-4 font-bold disabled:bg-gray-400">
                        Transfer
                    </button>
                </div>
            </div>
            {
                showPromt && (
                    <ConfirmDialogBox
                        title=' Confirm Ticket Transfer?'
                        text={`Are you sure you want to transfer this ticket to ${nameinputRef.current?.value} whose phone number is ${mobile.mobile.slice(mobile.countryCode.length)}? Once the transfer is complete, you will no longer have access to it`}
                        actionName='Yes'
                        action={transferTicket}
                        isOpen
                        onClose={() => setShowPromt(false)}
                    >
                        <></>
                    </ConfirmDialogBox>
                )}

        </DialogBox >

    )
}

export default TransferTicket