import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import LazyLoad from 'react-lazy-load';
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { apiConstant } from '../../contants/apiContants'
import { deleteReq, getReq, postReq, postReqJson } from '../../services/apiCall'
import Bg from './../../assests/images/bg.png'
import slogo from '../../assests/logo/selection_logo.png'
import { BackIcon, CalenderIcon, CardIcon, DeleteIcon, UserIcon } from '../../assests/icon/customIcon'
import aex from '../../assests/card_icon/americanexpress.png'
import dc from '../../assests/card_icon/dinnersclub.png'
import discover from '../../assests/card_icon/discover.png'
import jcv from '../../assests/card_icon/jcb.png'
import master from '../../assests/card_icon/mastercard.png'
import uatp from '../../assests/card_icon/uatp.png'
import union from '../../assests/card_icon/union_pay.png'
import visa from '../../assests/card_icon/visa.png'
import Loader from '../../components/Loader'
import EditIcon from '../../assests/icon/cameraicon.png'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../store/store'
import { getUserData } from '../../store/features/user/userSelectors'
import { editProfileCover, editUserData, fetchUserData } from '../../services/userApi/user'
import { isValidNumber } from '../../utils/commonFunction';
import DialogBox from '../../components/DialogBox';
import { calculateMaxDate, calculateMinDate } from '../../utils/date';
import { enthnicityList, propmtQ } from '../../contants/formConstants';
import OtpModal from './OtpModal';
import BasePhoneNumberInput from '../../components/BasePhoneNumberInput';




function UserProfile() {
    const [isToken, setIsToken] = useState('')
    const [userData, setUserData] = useState({} as any)
    const [loading, setLoading] = useState(false)
    const [dob, setDob] = useState('')
    const [existingCard, setExistingCard] = useState([]) as any
    const [cardNumber, setCardNumber] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardExpiry, setCardExpiry] = useState('') as any;
    const [cardCVC, setCardCVC] = useState('');
    const [cardNumberError, setCardNumberError] = useState('');
    const [cardNameError, setCardNameError] = useState('');
    const [cardExpiryError, setCardExpiryError] = useState('');
    const [cardCVCError, setCardCVCError] = useState('');
    const [cardType, setCardType] = useState('') as any;
    const [cardLogo, setCardLogo] = useState('') as any;
    const [isAdd, setIsAdd] = useState(false)
    const [infoEdit, setInfoEdit] = useState(false)
    const [promptEdit, setPromptEdit] = useState(false)
    const [numberChange, setNumberChange] = useState(false)
    const [isOtp, setIsOTP] = useState(false)
    const [preview, setPreview] = useState('');
    const fileRef = useRef<HTMLInputElement>(null)
    const [editLoad, setEditLoad] = useState({ info: false, prompt: false })
    const [promptAns, setPromptAns] = useState([]) as any
    const dispatch = useDispatch<AppDispatch>()
    const datafromRedux = useSelector(getUserData);
    const navigate = useNavigate()

    const [phone, setPhone] = useState({
        countryCode: "",
        mobile: "",
    });

    useEffect(() => {
        let token = localStorage.getItem('token')
        if (token) {
            if (!datafromRedux?.data) {
                dispatch(fetchUserData())
            }
            getExistingCard()
        }
    }, [isToken, localStorage.getItem('token')])

    useEffect(() => {
        if (datafromRedux?.error) {
            toast.error(datafromRedux.error);
        }
        if (datafromRedux?.data) {
            setUserData(datafromRedux?.data)
            const date = new Date(datafromRedux?.data?.dob);
            const options = { year: 'numeric', month: 'long', day: 'numeric' } as any;
            const formattedDate = date.toLocaleDateString('en-US', options);
            if (datafromRedux?.data?.dob) {
                setDob(formattedDate)
            }
        }
    }, [datafromRedux])

    const handleCardNumberChange = (event: any) => {
        let input = event.target.value
        // Remove any non-digit characters from the input
        const formattedInput = input.replace(/\D/g, '');
        // Split the input into groups of 4 digits
        const groupedInput = formattedInput.match(/.{1,4}/g);
        // Join the groups with a space separator
        const spacedInput = groupedInput ? groupedInput.join(' ') : '';

        setCardNumber(spacedInput);
        if (!validateCardNumber(spacedInput)) {
            setCardNumberError('Invalid card number');
        } else {
            setCardNumberError('');
            let getname = getCardType(input)
            setCardType(getname);
            setCardLogo(getCardLogoUrl(getname))
        }
    };
    const handleCardNameChange = (event: any) => {
        setCardName(event.target.value);
        if (!validateCardName(event.target.value)) {
            setCardNameError('Invalid card name');
        } else {
            setCardNameError('');
        }
    };
    const handleCardExpiryChange = (event: any) => {
        setCardExpiry(event.target.value);
        if (!validateCardExpiry(event.target.value)) {
            setCardExpiryError('Invalid expiry date');
        }
        else if (checkDateofExpiry(event.target.value)) {
            setCardExpiryError('The card has expired.')
        }
        else {
            setCardExpiryError('');
        }
    };

    const handleCardCVCChange = (event: any) => {
        setCardCVC(event.target.value);
        if (!validateCardCVC(event.target.value)) {
            if (event.target.value.length > 0) {
                setCardCVCError('Invalid code');
            }
            else {
                setCardCVCError('cvv is required');
            }
        } else {
            setCardCVCError('');
        }
    };
    const checkDateofExpiry = (val: string) => {
        let month = val.split('/')[0];
        let year = val.split('/')[1];
        year = `20${year}`
        const expiry = new Date(parseInt(year), parseInt(month) - 1, 0);
        const today = new Date();
        if (expiry > today) {
            //alert('The card has expired.');
            return false;
        }
        return true
    }
    const validateCardNumber = (cardNumber: any) => {
        const cardNumberRegex = /^\d{4}\s?\d{4}\s?\d{4}\s?\d{2,4}$/;
        return cardNumberRegex.test(cardNumber);
    };
    const validateCardName = (cardName: any) => {
        const regex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
        return regex.test(cardName);
    };
    const validateCardExpiry = (cardExpiry: string) => {
        const regex = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;
        return regex.test(cardExpiry);
    };
    const validateCardCVC = (cardCVC: any) => {
        const regex = /^[0-9]{3,4}$/;
        return regex.test(cardCVC);
    };
    const getCardType = (card: any) => {
        const regexVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
        const regexMastercard = /^5[1-5][0-9]{14}$/;
        const regexAmex = /^3[47][0-9]{13}$/;
        const regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
        const regexDinersClub = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
        const regexJCB = /^(?:2131|1800|35\d{3})\d{11}$/;
        const regexUATP = /^1[0-9]{14}$/;
        const regexUnionPay = /^(62|88)\d{14}$/;

        const cardNumber = card.replace(/\s/g, '');
        if (regexVisa.test(cardNumber)) {
            return 'visa';
        } else if (regexMastercard.test(cardNumber)) {
            return 'mastercard';
        } else if (regexAmex.test(cardNumber)) {
            return 'amex';
        } else if (regexDiscover.test(cardNumber)) {
            return 'discover';
        } else if (regexDinersClub.test(cardNumber)) {
            return 'dinersclub';
        } else if (regexJCB.test(cardNumber)) {
            return 'jcb';
        } else if (regexUATP.test(cardNumber)) {
            return 'uatp';
        } else if (regexUnionPay.test(cardNumber)) {
            return 'unionpay';
        } else {
            return '';
        }
    };
    const handleRemoveCard = (item: any) => {
        if (item) {
            let id = item?._id
            removeExistingCard(id)
        }
    }
    const handleAddCard = () => {
        let c = 0
        if (!cardNumber) {
            c += 1
            setCardNumberError('card number is required')
        }
        if (!cardName) {
            c += 1
            setCardNameError('card holder name is required')
        }
        if (!cardExpiry) {
            c += 1
            setCardExpiryError('card expiry date is required')
        }
        if (!cardCVC) {
            c += 1
            setCardCVCError('card cvv is required')
        }
        if (!cardCVCError && !cardExpiryError && !cardNameError && !cardNumberError && c === 0) {
            const cardNumberWithoutSpaces = cardNumber.replace(/\s/g, '');
            let data = {
                isSaveCard: 1,
                cardNumber: cardNumberWithoutSpaces,
                cardYear: cardExpiry?.split('/')[1],
                cardMonth: cardExpiry?.split('/')[0],
                cardCvv: cardCVC,
                name: cardName,
                userType: 1
            }
            addCard(data)

        }

    }
    const addCard = ((data: any) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/card/addCard`
        postReqJson(url, data)
            .then((res) => {
                setLoading(false)
                if (res?.data?.statusCode) {
                    let data = res?.data?.responseData?.response
                    toast.success(data?.message)
                    setIsAdd(!isAdd)
                    getExistingCard()
                    setCardNumber('')
                    setCardName('')
                    setCardCVC('')
                    setCardExpiry('')
                    setCardLogo('')

                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg);
                }
            })
            .catch((err) => {
                setLoading(false)
                toast.error('Ack, sorry! We’re having a server issue. Try again later')

            })
    })
    const getCardLogoUrl = (cardType: any) => {
        let cardTypeName = cardType.toLowerCase()
        switch (cardTypeName) {
            case 'visa':
                return visa
            case 'mastercard':
                return master;
            case 'amex':
                return aex;
            case 'discover':
                return discover;
            case 'dinersclub':
                return dc;
            case 'jcb':
                return jcv;
            case 'uatp':
                return uatp;
            case 'unionpay':
                return union;
            default:
                return '';
        }
    };
    const removeExistingCard = (id: any) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/card/deleteCard?stripeCardId=${id}`
        deleteReq(url)
            .then((res) => {
                setLoading(false)
                if (res?.data?.statusCode) {
                    let data = res?.data?.responseData?.response
                    //console.log('remove card res::;', data)
                    // setExistingCard(data)
                    getExistingCard()
                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg);
                }
            })
            .catch((err) => {
                setLoading(false)
                toast.error('Ack, sorry! We’re having a server issue. Try again later')

            })
    }
    const getExistingCard = () => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/card/getCard`
        getReq(url)
            .then((res) => {
                setLoading(false)
                // console.log('res event list::;', res)
                if (res?.data?.statusCode) {
                    let data = res?.data?.responseData?.response
                    //  console.log('get card res::;', data)
                    setExistingCard(data)
                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg);
                }
            })
            .catch((err) => {
                setLoading(false)
                toast.error('Ack, sorry! We’re having a server issue. Try again later')

            })
    }
    function formatDateForInput(dateString: string) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const editProfile = async (values: any, info = true) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach(key => {
                if (key === "dob") {
                    const timestamp = new Date(values?.dob).getTime().toString()
                    formData.append(key, timestamp)
                } else if (key === "mobile") {
                    formData.append("countryCode", phone.countryCode)
                    formData.append("mobile", values.mobile.slice(phone.countryCode.length))
                }
                else formData.append(key, values[key]);
            });
            formData.append("userType", '1')
            const resp = await dispatch(editUserData(formData))
            infoForm.resetForm()
            console.log(resp, "resp")
            if (resp?.success === true) toast.success("Profile Updated Successfully")
            if (info) setInfoEdit(false)
            else setPromptEdit(false)
        } catch (error) {
            console.log(error);
        }
    }
    const infoForm = useFormik({
        initialValues: {
            firstName: userData.firstName || "", lastName: userData.lastName || "", mobile: userData.countryCode + userData?.mobile || "", email: userData.email,
            dob: formatDateForInput(dob), ethnicity: userData.ethnicity, gender: userData.gender, city: userData.city, state: userData?.state || "", country: userData.country, profileCoverPhotos: null,
        },
        onSubmit: async (values) => {
            setEditLoad({ ...editLoad, info: true })
            const formvalues = values
            Object.keys(values).forEach(key => {
                if (values[key as keyof typeof values] === infoForm.initialValues[key as keyof typeof values]) {
                    delete formvalues[key as keyof typeof formvalues]
                }
            })
            // console.log(values.profileCover, "values", typeof values.profileCover)
            // if (values.profileCover) {
            //     console.log("inside value")
            //     const formdata = new FormData()
            //     formdata.append('profileCover', values.profileCover)
            //     await dispatch(editProfileCover(formdata))
            //     values.profileCover = null
            // }
            await editProfile(formvalues)
            setEditLoad({ ...editLoad, info: false })

        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            email: Yup.string().required('Required').matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Invalid email"),
            mobile: Yup.string()
                .required('Phone number is required')
                .min(10, 'Phone Number must be at least 10 characters')
                .test('isValidNumber', 'Phone number is invalid', (val) => {
                    // Additional validation logic here
                    val = val?.slice(phone?.countryCode?.length || userData.countryCode);
                    return isValidNumber(val);
                }),
            firstName: Yup.string().required('Required').matches(/^[a-zA-Z]*$/, 'First Name is Invalid'),
            dob: Yup.date().required('Required')
        })
    })
    useEffect(() => {
        if (infoForm.values.mobile !== infoForm.initialValues.mobile) {
            setNumberChange(true)
            return
        }
        setNumberChange(false)
    }, [infoForm.values.mobile, infoForm.initialValues.mobile]);
    const sendOtp = (num: any, resend: boolean) => {
        if (infoForm.errors.mobile) return
        setEditLoad({ ...editLoad, info: true })
        let url = `${apiConstant.BASE_URL}/user/sendOtp`
        postReq(url, num)
            .then((res) => {
                setEditLoad({ ...editLoad, info: false })
                if (res?.data?.statusCode) {
                    let msg = res?.data?.responseData?.message
                    if (resend) {
                        msg = 'Resend otp sent successfully'
                    }
                    toast.success(msg);
                    setIsOTP(true)
                }
            })
            .catch((err) => {
                console.log('err', err)
                setEditLoad({ ...editLoad, info: false })
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }
    const savePrompt = async () => {
        setEditLoad({ ...editLoad, prompt: true })
        const promptObj = userData.promptedQues.map((item: any, i: any) => {
            if (promptAns[i] !== undefined) return {
                ...item, answer: promptAns[i]
            }
            else return item
        })
        await editProfile({ "promptedQues": JSON.stringify(promptObj) }, false)
        setEditLoad({ ...editLoad, info: false })
    }
    const handleImageChange = (event: any) => {
        const selectedFile = event.target.files[0];
        const previewURL = URL.createObjectURL(selectedFile);
        setPreview(previewURL);
        infoForm.setFieldValue('profileCoverPhotos', selectedFile)
        infoForm.setFieldValue('isProfileEdit', 1)
    };
    console.log(infoForm.values, "values+++__", editLoad)



    const handlePhone = (val: any, code: any) => {
        setPhone({ ...phone, countryCode: code?.dialCode, mobile: val.slice(code?.dialCode.length) });
        infoForm.setFieldValue('mobile', val)
    };

    const checkNumberExist = async () => {
        let dataParam = {
            userType: 1,
            // email: val,
            countryCode: phone?.countryCode,
            mobile: phone?.mobile,
        };
        if (phone.mobile) {
            let url = `${apiConstant.BASE_URL}/user/isEmailMobileExist`;
            await postReq(url, dataParam)
                .then((res) => {
                    if (res?.data?.statusCode) {
                        sendOtp(
                            {
                                mobile: infoForm.values.mobile.slice(phone.countryCode.length),
                                countryCode: phone.countryCode,
                            },
                            false
                        );
                        setIsOTP(true);
                        return res?.data?.statusCode;
                    } else {
                        let msg = res?.data?.error?.responseMessage;
                        toast.warning(msg);
                        //   dispatch(fetchDataError(msg))
                        return 0;
                    }
                })
                .catch((err: any) => {
                    console.log("err", err);
                    setTimeout(() => {
                        toast.error(
                            "Ack, sorry! We’re having a server issue. Try again later"
                        );
                    }, 1000);
                });
        }
    };


    return (
        <div className='relative' >
            <div className='w-full relative'>
                <div className='w-full h-[20vh] md:h-[50vh]  bg-gray-300 flex justify-center items-center overflow-hidden'>
                    <LazyLoad threshold={0.95}>
                        <img src={Bg} alt='bg-images' className='w-screen h-[20vh] md:h-[50vh]' />
                    </LazyLoad>
                </div>

            </div>
            <div className='absolute top-3 md:top-10 left-6 md:left-10 overflow-hidden'>
                <div
                    className='flex items-center'
                >
                    <div
                        className='cursor-pointer text-white'
                        onClick={() => {
                            navigate('/')
                        }}>
                        <BackIcon />
                    </div>

                    <img src={slogo} alt='logo' className='h-[30px] w-[60px]  md:h-[60px] md:w-[150px] ml-4 mt-3' />
                </div>
            </div>
            <div className='absolute top-3 right-6 md:top-10 md:right-20'>
                <p className='font-bold text-white text-1xl md:text-2xl cursor-pointer mt-3' onClick={() => navigate('/my-tickets')}>
                    MY TICKETS
                </p>
            </div>

            <div className='w-full flex justify-center absolute' style={{ marginTop: '-4%' }}>
                <div className='w-full md:w-[80%] bg-gray-50 p-4 border rounded-2xl shadow-md flex  flex-col md:flex-row items-center md:items-start'>
                    <div className=' rounded-full flex justify-center items-center p-4 border-dashed border-2 border-gray-400 relative'>
                        {userData?.profileCoverPhotos?.length > 0 ?
                            <div className=" h-[100px] w-[100px] md:h-[200px] md:w-[200px] rounded-full mx-auto bg-gray-200 ">
                                <LazyLoad threshold={0.95}>
                                    <img
                                        className=" h-[100px] w-[100px] md:h-[200px] md:w-[200px] rounded-full mx-auto"
                                        src={preview ? preview : userData?.profileCoverPhotos[0]?.url}
                                        alt="dp" />
                                </LazyLoad>
                            </div>
                            :
                            <div className="h-[100px] w-[100px] md:h-[200px] md:w-[200px] rounded-full mx-auto bg-gray-100 shadow-lg flex justify-center items-center">
                                <p> {userData?.firstName?.split('')[0]?.toUpperCase() || ' '} {userData?.lastName?.split('')[0]?.toUpperCase() || ' '}</p>
                            </div>
                        }

                        {infoEdit && <button onClick={() => fileRef.current?.click()} className='absolute bg-white-100  right-0 md:right-1 bottom-1 md:bottom-12 w-20 h-6  rounded-sm text-red color-white-200 my-4 flex justify-center '>
                            <img src={EditIcon} width={window.innerWidth < 480 ? "40px" : "50px"} alt='edit' height={window.innerWidth < 480 ? "40px" : "50px"} />
                        </button>}

                    </div>
                    <div className=' mx-6 w-full md:w-[70%] mt-4'>
                        <div className='border-b-2  flex justify-between items-center'>
                            <p className='text-1xl md:text-2xl pb-2'> PERSONAL INFORMATION</p>
                            <button className='px-4 py-2 bg-sky-500 rounded-sm text-white m-2 hover:bg-sky-400' onClick={() => setInfoEdit(!infoEdit)}>Edit Info</button>
                        </div>
                        {editLoad.info ? <Loader /> : <form onSubmit={infoForm.handleSubmit}>

                            <div className='my-4 grid grid-cols-1 md:grid-cols-2 gap-4  w-full md:w-[60%]'>
                                <div >
                                    <label className='text-gray-500'> First Name</label>
                                    {infoEdit ? <> <input className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="firstName" onChange={infoForm.handleChange} value={infoForm.values.firstName} />
                                        {infoForm.errors.firstName && <p className='text-red-500 '>{`First name is ${infoForm.errors.firstName === "Required" ? "required" : "invalid"}`}</p>}</>
                                        :
                                        <p className='text-gray-600'>{userData?.firstName}</p>}
                                </div>
                                <div >
                                    <label className='text-gray-500'> Last Name</label>
                                    {infoEdit ? <input className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="lastName" onChange={infoForm.handleChange} value={infoForm?.values.lastName} /> :
                                        <p className='text-gray-600'>{userData?.lastName}</p>}
                                </div>

                                <div >
                                    <label className='text-gray-500'> Mobile Number</label>
                                    {infoEdit ?
                                        <>
                                            <BasePhoneNumberInput
                                                val={infoForm.values?.mobile}
                                                setval={handlePhone}
                                                err={() => infoForm.validateField('mobile')}
                                                className="!w-full"
                                            />
                                            {infoForm.errors.mobile && <p className='text-red-500 '>{`Phone number is ${infoForm.errors.mobile === "Required" ? "required" : "invalid"}`}</p>} </>
                                        :
                                        <p className='text-gray-600 '> +{userData?.countryCode}{userData?.mobile}</p>}
                                </div>
                                <div >
                                    <label className='text-gray-500'> Email</label>
                                    {infoEdit ? <><input className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="email" onChange={infoForm.handleChange} value={infoForm.values.email} />
                                        {infoForm.errors.email && <p className='text-red-500 '>{`Email is ${infoForm.errors.email === "Required" ? "required" : "invalid"}`}</p>} </> :
                                        <p className='text-gray-600'> {userData?.email}</p>}
                                </div>
                                <div >
                                    <label className='text-gray-500'> Date of Birth</label>
                                    {infoEdit ?
                                        <> <input type='date' min={calculateMaxDate()} max={calculateMinDate()} className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="dob" onChange={infoForm.handleChange} value={infoForm.values.dob} />
                                            {infoForm.errors.dob && <p className='text-red-500 '>Dob is required</p>} </>
                                        :
                                        <p className='text-gray-600'>{dob}</p>}
                                </div>
                                <div >
                                    <label className='text-gray-500'> Ethnicity</label>
                                    {infoEdit ?

                                        <select className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="ethnicity" onChange={infoForm.handleChange} value={infoForm.values.ethnicity} >
                                            {enthnicityList.map((opt) => <option key={opt} value={opt}>{opt}</option>)}
                                        </select> :
                                        <p className='text-gray-600'>{userData?.ethnicity}</p>}
                                </div>
                                <div >
                                    <label className='text-gray-500'> Gender</label>
                                    {infoEdit ? <select className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="gender" onChange={infoForm.handleChange} value={infoForm.values.gender}>
                                        {["Male", "Female", "Nonbinary or Genderqueer", "Do not wish to share"].map((x, i) => <option value={i + 1} key={x} selected={i + 1 === userData?.gender}> {x}</option>)}
                                    </select> :
                                        <p className='text-gray-600'>{userData?.gender === 1 ? 'Male' : userData?.gender === 2 ? 'Female'
                                            : userData?.gender === 3 ? 'Nonbinary or Genderqueer' : userData?.gender === 4 ? 'Do not wish to share' : ''}</p>}
                                </div>

                                <div >
                                    <label className='text-gray-500'> City</label>
                                    {infoEdit ? <input className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="city" onChange={infoForm.handleChange} value={infoForm.values.city} /> :
                                        <p className='text-gray-600'>{userData?.city}</p>}
                                </div>
                                <div >
                                    <label className='text-gray-500'>State</label>
                                    {infoEdit ? <input className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="state" onChange={infoForm.handleChange} value={infoForm.values?.state} /> :
                                        <p className='text-gray-600'>{userData?.state}</p>}
                                </div>
                                <div >
                                    <label className='text-gray-500'>Country</label>
                                    {infoEdit ? <input className="px-4 py-2 bg-gray-100 outline-none rounded-sm w-full" name="country" onChange={infoForm.handleChange} value={infoForm.values?.country} /> :
                                        <p className='text-gray-600'> {userData?.country}</p>}
                                </div>
                                <input hidden type='file' accept='image/*' ref={fileRef} onChange={handleImageChange} name='profileCover' />
                                {infoEdit ?
                                    numberChange ?
                                        <button
                                            disabled={!infoForm.dirty || Boolean(Object.keys(infoForm.errors)?.length)}
                                            onClick={() => {
                                                checkNumberExist()
                                            }}
                                            type='button'
                                            className='px-4 py-2 bg-sky-600 rounded-sm text-white my-4 flex justify-center w-32 ml-4 hover:bg-sky-400 disabled:bg-gray-300'
                                        > Save
                                        </button>
                                        :
                                        <button
                                            disabled={!infoForm.dirty || Boolean(Object.keys(infoForm.errors)?.length)}
                                            type='submit'
                                            className='px-4 py-2 bg-sky-600 rounded-sm text-white my-4 flex justify-center w-32 ml-4 hover:bg-sky-400 disabled:bg-gray-300'
                                        > Save
                                        </button> : ""}
                            </div>
                        </form>}

                        <div className='border-b-2 mt-6 flex justify-between items-center'>
                            <p className='text-1xl md:text-2xl pb-3'>PROMPT QUESTIONS ANSWERS</p>
                            <button className=' py-2 bg-sky-500 rounded-sm text-white m-2 hover:bg-sky-400 px-2 md:px-4' onClick={() => setPromptEdit(!promptEdit)}>Edit Answers</button>
                        </div>
                        {editLoad.prompt ? <Loader /> : <ol className='px-4'>
                            {
                                userData?.promptedQues && userData?.promptedQues.map((item: any, i: any) => (
                                    <div key={i} className='mt-3'>

                                        <div className='flex'>
                                            <p> {item?.questionNumber}</p>
                                            <div className='pl-2'>
                                                <p className='pb-1 text-gray-500'> {item?.question}</p>
                                                {promptEdit ? <input onChange={(e) => {
                                                    setTimeout(() => {
                                                        const updated = promptAns
                                                        updated[i] = e.target.value
                                                        setPromptAns(updated)
                                                    }, 600)
                                                }} className="px-4 py-2 bg-gray-100 outline-none rounded-sm  " name={item?.question} defaultValue={item?.answer} /> : <p className='text-gray-600 '>{item?.answer ? item?.answer : 'NA'}  </p>}

                                            </div>
                                        </div>
                                    </div>
                                ))

                            }
                            {promptEdit ? <button
                                onClick={savePrompt}
                                className='px-4 py-2 bg-sky-600 rounded-sm text-white my-4 flex justify-center w-32 ml-4 hover:bg-sky-400'
                            > Save
                            </button> : ""}
                        </ol>}
                        <div className='border-b-2 mt-4 flex justify-between items-center'>
                            <p className='text-1xl md:text-2xl'>MANAGE CARD</p>
                            <button className='px-4 py-2 bg-sky-500 rounded-sm text-white m-2 hover:bg-sky-400' onClick={() => setIsAdd(!isAdd)}> Add New</button>
                        </div>
                        {
                            loading ?
                                <Loader />
                                :
                                isAdd ?
                                    <div className='mt-4 flex justify-center md:justify-start'>
                                        <div className='w-full md:w-[400px]'>
                                            <div className='mb-2'>
                                                <label htmlFor="cardNumber" className='text-gray-500'>Enter card number</label>
                                                <div className='relative flex w-full bg-gray-100 items-center'>
                                                    <div className='z-50 pl-4'>
                                                        <CardIcon />
                                                    </div>
                                                    <input type="text"
                                                        id="cardNumber"
                                                        value={cardNumber}
                                                        placeholder='enter card number'
                                                        onChange={handleCardNumberChange}
                                                        className='px-4 py-2 bg-gray-100 outline-none rounded-sm'
                                                    />
                                                    <div className='absolute right-2 top-1 z-50'>
                                                        {
                                                            cardLogo && <img src={cardLogo} alt='card type' className='w-[60px] h-[30px]' />
                                                        }
                                                    </div>
                                                </div>
                                                {cardNumberError && <div className="text-red-600 text-sm">{cardNumberError}</div>}
                                            </div>
                                            <div className='mb-2 relative'>
                                                <label htmlFor="cardName" className='text-gray-500'>Enter card holder name</label> <br />
                                                <div className='relative flex w-full bg-gray-100 items-center'>
                                                    <div className='z-40 pl-4'>
                                                        <UserIcon />
                                                    </div>
                                                    <input
                                                        type="text"
                                                        id="cardName"
                                                        placeholder='enter card holder name'
                                                        value={cardName}
                                                        onChange={handleCardNameChange}
                                                        className='px-4 py-2 bg-gray-100 outline-none rounded-sm'
                                                    />
                                                </div>

                                                {cardNameError && <div className="text-red-600 text-sm">{cardNameError}</div>}
                                            </div>
                                            <div className='w-full flex flex-wrap justify-between mb-2'>
                                                <div className='w-[50%]'>
                                                    <label htmlFor="cardExpiry" className='text-gray-500'>Enter expiry date</label>
                                                    <div className='relative flex bg-gray-100 items-center'>
                                                        <div className='z-50 pl-4'>
                                                            <CalenderIcon />
                                                        </div>
                                                        <input type="text"
                                                            id="cardExpiry"
                                                            placeholder='02/28'
                                                            value={cardExpiry}
                                                            onChange={handleCardExpiryChange}
                                                            className='px-4 py-2 bg-gray-100 outline-none rounded-sm w-full'
                                                        />
                                                    </div>
                                                    {cardExpiryError && <div className="text-red-600 text-sm">{cardExpiryError}</div>}
                                                </div>
                                                <div className='w-[45%]'>
                                                    <label htmlFor="cardCvv" className='text-gray-500'>Enter cvv</label>
                                                    <div className='relative flex bg-gray-100 items-center'>
                                                        <div className='z-50 pl-4'>
                                                            <CardIcon />
                                                        </div>
                                                        <input type="text"
                                                            id="cardCvv"
                                                            placeholder='***'
                                                            value={cardCVC}
                                                            onChange={handleCardCVCChange}
                                                            className='px-4 py-2 bg-gray-100 outline-none rounded-sm w-full'
                                                        />
                                                    </div>
                                                    {cardCVCError && <div className="text-red-600 text-sm">{cardCVCError}</div>}
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => handleAddCard()}
                                                className='px-4 py-2 bg-sky-600 rounded-sm text-white my-4 flex justify-center w-full md:w-[400px] hover:bg-sky-400'
                                            > Add Card
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className='py-4'>
                                        {
                                            existingCard?.length > 0 ?
                                                existingCard.map((item: any, i: any) => (
                                                    <div className='flex pb-2 justify-between items-center' key={i}>
                                                        <div className='flex items-center gap-4 '>
                                                            <p> {i + 1}</p>
                                                            <img src={getCardLogoUrl(item?.brand)} alt='card type' className='w-[60px] h-[30px]' />
                                                            <div className=''>
                                                                <p className=''>{item?.name}</p>
                                                                <p className='text-gray-600'> **** **** **** {item?.last4}</p>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <div className='p-2 hover:bg-gray-200 rounded-full cursor-pointer text-gray-600'
                                                                onClick={() => handleRemoveCard(item)}
                                                            > <DeleteIcon /> </div>
                                                        </div>
                                                    </div>

                                                ))

                                                :
                                                <div className='w-full'>
                                                    <p className='text-center text-gray-400'> No Data Found </p>
                                                </div>
                                        }

                                    </div>
                        }
                    </div>
                </div>
            </div>
            {/* <DialogBox onClose={() => { }} isOpen={true} >
                <div className="w-450 min-h-200 max-h-auto shadow-lg rounded-2xl p-11">
                    <div className="font-semibold text-2xl text-black">You haven't saved your changes, Are you sure you want to discard this draft?</div>
                    <div className="font-normal text-base text-gray-700 mt-6">You haven't saved your changes, Are you sure you want to discard this draft?</div>
                    <div className="flex justify-center items-center mt-10 gap-4"> */}
            {/* <Button type="button" textContent={positiveActionButtonContent} />
                <Button type="button" textContent={negaviteActionButtonContent} overrideClass="cancelBtn" /> */}

            {/* </div>
                </div>
            </DialogBox> */}
            <DialogBox isOpen={isOtp} onClose={() => setIsOTP(false)} withClose={true} >
                <OtpModal onClose={() => setIsOTP(false)} onSubmit={infoForm.handleSubmit} open={isOtp} countryCode={phone.countryCode} mobile={infoForm.values.mobile} />
            </DialogBox>

            <ToastContainer />

        </div >
    )
}

export default UserProfile