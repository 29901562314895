import React, { useState, useEffect } from 'react'
import { apiConstant } from '../contants/apiContants'
import { getReq, getReqWithT } from '../services/apiCall'
import EventCard from './EventCard'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { CalenderIcon, LocationIcon, SearchIcon } from '../assests/icon/customIcon'
import Loader from '../components/Loader'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertLocalDateToUTCIgnoringTimezone, getCurrentDateTimestamp, getCurrentTime } from '../utils/commonFunction'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../store/store'
import { getEventList } from '../store/features/event/eventSelector'
import { fetchEventError, fetchEventSuccess } from '../store/features/event/eventSlice'
import IosLink from '../pages/user-profile/IosLink';

function EventList() {
    const [eventList, setEventList] = useState<any>([])
    const [venueId, SetVenueId] = useState('')
    const [token, setToken] = useState('')
    const [query, setQuery] = useState('')
    const [activeEventTab, setActiveEventTab] = useState(2)
    const [latitude, setLatitude] = useState<any>('');
    const [longitude, setLongitude] = useState<any>('');
    const [city, setCity] = useState({
        name: '',
        id: ''
    })
    const [q, setQ] = useState('')
    const [date, setDate] = useState<any>('')
    const [dateTimestamp, setDateTimestamp] = useState('') as any
    const [loading, setLoading] = useState(false)
    const [filteredEventList, setFilteredEventList] = useState([]);
    const [venueList, setVenueList] = useState<any>([]);
    const [lQuery, setLQuery] = useState('')
    const [openCard, setOpenCard] = useState(false)
    const [locationAccessMsg, setLocationAccessMsg] = useState('')
    const [showMessage, setShowMessage] = useState(false);
    const eventListStore = useSelector(getEventList);
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    useEffect(() => {
        let t = localStorage.getItem('token')
        if (t) {
            setToken(t)
        }
        if (!navigator.geolocation) {
            setShowMessage(true);
        } else {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    setLocationAccessMsg('')
                    setShowMessage(false)
                },
                error => {
                    setShowMessage(true);
                    setLocationAccessMsg('Please enable location services in your browser settings to use this for current location event.')
                }
            );
        }
    }, [])

    useEffect(() => {
        if (latitude && longitude) {
            fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
                .then(response => response.json())
                .then(data => {
                    setCity({ ...city, name: data.city });
                    if (!lQuery) {
                        setLQuery(data.city)
                    }

                })
                .catch(error => console.error(error));
        }
    }, [latitude, longitude]);

    useEffect(() => {
        if (venueId) {
            handleGetEventData(query)
        }
        else if (q) {
            handleGetEventData(q)
        }
        else if (query) {
            handleGetEventData(query)
        }
        else {
            handleGetEventData('')
        }
        localStorage.setItem('activeTab', JSON.stringify(activeEventTab))
    }, [token, activeEventTab, date, latitude, longitude, showMessage])

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            let trim = query.trim()
            if (!trim && venueId) {
                handleGetEventData('')
            }
            else if (trim) {
                handleGetEventData(trim)
            }
            else {
                handleGetEventData(q)
            }
        }, 500);
        return () => clearTimeout(debounceTimer);
    }, [query]);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            lQuery && getVenueListData(lQuery)
            if (!query && !venueId) {
                handleGetEventData(q)
            }
        }, 500);
        return () => clearTimeout(debounceTimer);
    }, [lQuery, q]);

    useEffect(() => {
        if (venueId) {
            handleGetEventData(query)
        }
    }, [venueId])



    const handleSearchChange = (event: any) => {
        setQuery(event.target.value);
    };
    const handleSearchVenueChange = (event: any) => {
        setLQuery(event.target.value);
        setQ(event.target.value)
        if (venueId) {
            SetVenueId('')
        }
    };
    const handleSelectDate = (d: any) => {
        if (d) {
            let timestamp = convertLocalDateToUTCIgnoringTimezone(d)
            // let utc=convertUTCToLocalDateIgnoringTimezone(new Date(timestamp))
            // console.log(new Date(timestamp),utc)
            setDateTimestamp(timestamp);

        } else {
            setDateTimestamp('');
        }

        setDate(d);
    }

    const handleGetEventData = (search: any) => {
        let param = {
            type: activeEventTab,
            dateFilter: dateTimestamp,
            lat: latitude,
            long: longitude,
            sortType: -1,
            squery: search,
            currentTime: getCurrentTime(),
            currentDate: getCurrentDateTimestamp()
        }
        //console.log('final param',param)
        getEventListData(param)
    }
    const handleOpenCard = () => {
        setOpenCard(!openCard)
    }
    const handleCloseCard = () => {
        setTimeout(() => {
            setOpenCard(!openCard)
        }, 500)

    }
    const handleSelectLocation = (e: any) => {
        let obj = JSON.parse(e)
        setLQuery(obj.publicBusinessName)
        SetVenueId(obj?._id)
        setOpenCard(false)
        setVenueList([])
        sessionStorage.setItem('locN', obj?.publicBusinessName)
        sessionStorage.setItem('locI', obj?._id)

    }
    const getEventListData = (param: any) => {
        let url = '';
        if (param?.dateFilter && latitude && longitude && !q) {
            url = `${apiConstant.BASE_URL}/event/getTicketedEventsForAdmin?lat=${latitude}&long=${longitude}&radius=${100}&venueId=${venueId}&type=${param.type}&currentDate=${param.currentDate}&currentTime=${param.currentTime}&dateFilter=${param?.dateFilter}&search=${param.squery}`
        }
        else if (latitude && longitude && !q) {
            url = `${apiConstant.BASE_URL}/event/getTicketedEventsForAdmin?lat=${latitude}&long=${longitude}&venueId=${venueId}&radius=${100}&type=${param.type}&currentDate=${param.currentDate}&currentTime=${param.currentTime}&search=${param?.squery}`
        }
        else if ((showMessage || q) && param?.dateFilter) {
            url = `${apiConstant.BASE_URL}/event/getTicketedEventsForAdmin?type=${param.type}&currentDate=${param.currentDate}&currentTime=${param.currentTime}&venueId=${venueId}&sortType=${param.sortType}&dateFilter=${param.dateFilter}&search=${param?.squery}`
        }
        else if (showMessage || q) {
            url = `${apiConstant.BASE_URL}/event/getTicketedEventsForAdmin?type=${param.type}&currentDate=${param.currentDate}&currentTime=${param.currentTime}&venueId=${venueId}&sortType=${param.sortType}&search=${param?.squery}`
        }
        if (url) {
            setLoading(true)
            getReq(url)
                .then((res) => {
                    setLoading(false)
                    if (res?.data?.statusCode) {
                        let data = res?.data?.responseData?.response
                        if (data) {
                            setEventList(data)
                            setFilteredEventList(data)
                            dispatch(fetchEventSuccess(data));
                        }
                    }
                    else {
                        let msg = res?.data?.error?.responseMessage
                        dispatch(fetchEventError(msg));
                        toast.error(msg);
                    }

                })
                .catch((err) => {
                    console.log('err', err)
                    setLoading(false)
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')

                })
        }
    }
    const getVenueListData = (p: any) => {
        let url = `${apiConstant.BASE_URL}/venue/getAllFeedsForAdmin?search=${p}&radius=100`
        getReq(url)
            .then((res) => {
                // console.log('res venue list::;', res?.data?.responseData)
                if (res?.data?.statusCode) {
                    let data = res?.data?.responseData?.response?.venues
                    // console.log('res venue list::;', data?.length)
                    if (data) {
                        setVenueList(data)
                    }
                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg);
                }

            })
            .catch((err) => {
                console.log('err', err)
                toast.error('Ack, sorry! We’re having a server issue. Try again later')

            })
    }
    return (
        <div id='overlay-modal-root' className='w-full  px-4 md:px-12  py-4 md:py-8'>
            <div className='flex flex-wrap-reverse  md:flex-wrap justify-between items-center mb-3 gap-4 md:gap-0 z-50 relative'>
                <div className='w-full md:w-[50%] flex gap-3 md:gap-6  justify-between md:justify-start items-center flex-wrap'>
                    <div className='text-1xl hidden md:block md:text-3xl font-bold'>
                        Events in
                    </div>
                    <div className='border px-2 py-2 bg-gray-100 rounded-md text-1xl flex items-center relative'>
                        <LocationIcon />
                        <div>
                            <input
                                type="text" value={lQuery}
                                onChange={handleSearchVenueChange}
                                onFocus={() => handleOpenCard()}
                                onBlur={() => handleCloseCard()}
                                className='px-1 md:px-4 text-gray-500 bg-gray-100 truncate w-[100px] md:w-[280px]  outline-none ml-1'
                            />

                            {
                                openCard && venueList?.length > 0 &&
                                <select
                                    size={venueList?.length + 1}
                                    onChange={(e) => handleSelectLocation(e.target.value)}
                                    className='absolute outline-none top-11 rounded-md shadow-md left-0 border px-4 py-2 bg-white w-[300px]  md:w-[360px] max-h-[200px] md:max-h-[300px] overflow-x-hidden'
                                >
                                    {
                                        venueList.map((suggestion: any) => (
                                            <option
                                                key={suggestion._id}
                                                value={JSON.stringify(suggestion)}
                                                className='cursor-pointer py-2 hover:bg-gray-100'
                                            >

                                                {suggestion?.publicBusinessName}
                                            </option>
                                        ))
                                    }
                                </select>
                            }
                        </div>
                    </div>
                    <div className='border px-2 py-2 bg-gray-100 rounded-md text-1xl flex '>
                        <div className='relative  w-[150px] md:w-[150px] flex'>
                            {
                                !date && <div className='mr-1'>
                                    <CalenderIcon />
                                </div>
                            }
                            <DatePicker
                                selected={date || ''}
                                className='cursor-pointer bg-gray-100 outline-none px-1 md:px-2 text-sm md:text-1xl text-gray-600  w-[130px] md:w-[130px]'
                                //dateFormat={'dd mm yyyy'}
                                dateFormat="d MMMM yyyy"
                                isClearable={date || ''}
                                placeholderText="select date"
                                onChange={handleSelectDate}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-[50%] flex justify-center items-center md:justify-end flex-wrap gap-4'>
                    <div className='w-full md:w-[50%] flex justify-center items-center'>
                        <div className='w-full p-1 border border-sky-600 rounded-md flex justify-between gap-4 items-center'>
                            <button
                                disabled={loading}
                                onClick={() => setActiveEventTab(2)}
                                className={
                                    activeEventTab === 2 ?
                                        'px-2 py-1  bg-sky-600  rounded-sm text-white w-[50%]'
                                        :
                                        loading ?
                                            'px-2 py-1 text-sky-300 w-[50%]'
                                            :
                                            'px-2 py-1 text-sky-600 hover:bg-sky-600 hover:text-white w-[50%]'
                                }
                            >
                                Upcoming
                            </button>
                            <button
                                onClick={() => setActiveEventTab(1)}
                                className={
                                    activeEventTab === 1 ?
                                        'px-2 py-1  bg-sky-600 rounded-sm text-white w-[50%]'
                                        :
                                        'px-2 py-1 text-sky-600  hover:bg-sky-600 hover:text-white w-[50%]'
                                }

                            >
                                Currently Ongoing
                            </button>
                        </div>
                    </div>
                    <div className='w-full md:w-[35%]'>
                        <div className='flex items-center relative'>
                            <input
                                type='text'
                                placeholder='Search here .....'
                                className='border outline-none px-4 py-2 rounded-md bg-gray-100 w-full'
                                value={query}
                                //ref={queryRef}
                                onChange={handleSearchChange}
                            />
                            <div className='bg-sky-600 p-1 rounded-md absolute right-2'>
                                <SearchIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loading ?
                    <Loader />
                    :
                    <div>
                        {
                            eventList?.length > 0 ?
                                <div className='grid grrid-cols-1 md:grid-cols-3 gap-4 md:gap-8 z-0 relative'>
                                    {
                                        filteredEventList.map((item: any, i: any) => (
                                            <div key={i}>
                                                <EventCard item={item} tab={activeEventTab} />
                                            </div>

                                        ))

                                    }

                                </div>
                                :
                                <div className='rounded-sm shadow-md h-[50vh] md:h-[30vh] bg-gray-100 flex justify-center items-center'>
                                    <p className='text-gray-500 px-6 py-4 shadow-lg bg-white'> No Event Found .......!</p>
                                </div>

                        }
                    </div>
            }
            {/* <div className="fixed bottom-0 left-0  w-full z-50 shadow-xl  rounded-full "> */}
            <IosLink buttonText="Continue" urlType="ticketedEvents" />
            {/* </div> */}
            <ToastContainer />
        </div>
    )
}

export default EventList