
import React, { useRef, useState, useEffect } from 'react'
import DialogBox from '../../components/DialogBox'
import login from '../../assests/images/login.png'
import { postReq } from '../../services/apiCall';
import { apiConstant } from '../../contants/apiContants';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CloseIcon } from '../../assests/icon/customIcon';

function OtpModal(props: any) {
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ] as any;
    const [otp, setOtp] = useState({
        1: '',
        2: '',
        3: '',
        4: ''
    })
    const [isResend, setIsResend] = useState(false)
    const [time, setTime] = useState(0)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const handleInput = (event: any, index: any) => {
        if (event.target.value.length > 0 && index < inputRefs.length - 1) {
            inputRefs[index] = event.target.value
            inputRefs[index + 1].current.focus();

        }
        else {
            inputRefs[index] = event.target.value
            if (index > 0 && event.target.value.length === 0) {
                inputRefs[index - 1].current.focus();
            }
        }
        if (index === 0) {
            setOtp({ ...otp, 1: event.target.value })
        }
        if (index === 1) {
            setOtp({ ...otp, 2: event.target.value })
        }
        if (index === 2) {
            setOtp({ ...otp, 3: event.target.value })
        }
        if (index === 3) {
            setOtp({ ...otp, 4: event.target.value })
        }
    }
    useEffect(() => {
        if (props.open) {
            inputRefs[0].current.focus();
            setTime(60)
        }
    }, [])
    const handlVerifyOtp = () => {
        let finalOtp = `${otp[1]}${otp[2]}${otp[3]}${otp[4]}`
        let data = {
            userType: 1,
            otp: finalOtp,
            // otp: '9876',
            type: 0,
            countryCode: props?.countryCode,
            mobile: props?.mobile.slice(props.countryCode?.length)
        }
        setTime(0)
        verifyOtp(data)
    }
    useEffect(() => {
        let interval = null as any;
        if (time > 0 && props.open) {
            interval = setInterval(() => {
                setTime(time - 1);
            }, 1000);
        }
        else {
            // setIsResend(false)
            setTime(0)
            clearInterval(interval)
        }
        return () => clearInterval(interval); // Clear the interval on unmount
    }, [time, isResend]);
    console.log("inside the modal")
    const verifyOtp = (otp: any) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/verifyOtp`
        postReq(url, otp)
            .then((res) => {
                // console.log('res:verify otp:;', res)
                if (res?.data?.statusCode && res?.data?.responseData?.message) {
                    setLoading(false)
                    let msg = res?.data?.responseData?.message
                    toast.success(msg);
                    setOtp({
                        1: '',
                        2: '',
                        3: '',
                        4: ''
                    })
                    props.onSubmit()
                    props.onClose()
                }
                else {
                    setLoading(false)
                    setOtp({
                        1: '',
                        2: '',
                        3: '',
                        4: ''
                    })
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg);
                    props.onClose()
                }

            })
            .catch((err: any) => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 500)

            })
    }
    const handlResendOtp = () => {
        setIsResend(true)
        setTime(60)
        handleSendOtp(true)
    }
    const sendOtp = (num: any, resend: boolean) => {
        setLoading(true)
        let url = `${apiConstant.BASE_URL}/user/sendOtp`
        postReq(url, num)
            .then((res) => {
                // console.log('res::;', res)
                setLoading(false)
                if (res?.data?.statusCode) {
                    let msg = res?.data?.responseData?.message
                    if (resend) {
                        msg = 'Resend otp sent successfully'
                    }
                    toast.success(msg);
                }

            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }
    const handleSendOtp = (val: boolean) => {
        setOtp({
            1: '',
            2: '',
            3: '',
            4: ''
        })

        sendOtp(props.number, val)

    }

    return (
        // <DialogBox onClose={props.onClose} isOpen={props.open}>
        <div className='w-full md:w-[80%] text-center'>

            <p className='text-1xl md:text-2xl font-bold'>
                Enter your verification code.
            </p>
            <p className='text-gray-500 my-3'>
                Enter the verification code that was sent to you, +{props.mobile}
            </p>
            <div className='w-full flex justify-center items-center my-4'>
                <img src={login} alt='login' />
            </div>
            <div className='flex justify-center gap-4'>
                {inputRefs.map((inputRef: any, index: any) => (
                    <input
                        key={index}
                        type="text"
                        ref={inputRef}
                        value={index === 0 ? otp[1] : index === 1 ? otp[2] : index === 2 ? otp[3] : index === 3 ? otp[4] : ''}
                        maxLength={1}
                        onChange={(event) => handleInput(event, index)}
                        className='h-[50px] w-[50px] bg-gray-100 text-center outline-none border focus:border-blue-600 rounded-md shadow-lg'

                    />
                ))}


            </div>

            {
                (time > 0) ?
                    <p className='pt-6 text-1xl md:text-2xl text-blue-400'>00:{time} </p>
                    : null
            }
            <p className='text-gray-500 py-4 mt-4'>
                Didn’t you received any code? <br />
                <button
                    disabled={time > 0}
                    className={time === 0 ? 'text-blue-500 cursor-pointer font-bold hover:underline' : 'text-blue-300'}
                    onClick={handlResendOtp}> Resend a new code
                </button>
            </p>
            <button
                disabled={(otp[1] && otp[2] && otp[3] && otp[4]) ? false : true}
                className={(otp[1] && otp[2] && otp[3] && otp[4]) ?
                    'w-full border rounded-md bg-sky-600 hover:bg-sky-700 text-white px-4 py-3 mt-8 font-bold' :
                    'w-full border rounded-md bg-blue-300 text-white px-4 py-3 mt-8  font-bold'
                }

                onClick={handlVerifyOtp}> Verify</button>
        </div>
        // </DialogBox>
    )
}

export default OtpModal