import { createSlice,PayloadAction } from '@reduxjs/toolkit';

interface State {
  data: any;
  loading: boolean;
  error: string | null;
  isselectedEventrequireallInfos:boolean,
  selectedTicket:string |number,
  isFromGuest:boolean,
  isViewTicket:boolean
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
  isselectedEventrequireallInfos:false,
  selectedTicket:'',
  isFromGuest:false,
  isViewTicket:false
};

// Create a slice for managing the data state
const eventSlice = createSlice({
  name: 'eventList',
  initialState,
  //method-1 : without using createAsyncThunk reducer
  reducers: {
    fetchEventRequest: (state) => {
      state.loading=true
    },
    fetchEventError: (state,action) => {
      state.error=action.payload
      state.loading=false
    },
    fetchEventSuccess: (state, action:PayloadAction<any>) => {
      state.data = action.payload;
      state.loading=false
      state.error=null
    },
    setEventRequireInfo:(state,action:PayloadAction<any>)=>{
     state.isselectedEventrequireallInfos=action.payload
    },
    setSelectTicket:(state,action:PayloadAction<any>)=>{
    state.selectedTicket=action.payload
    },
    setIsFromGuest:(state,action:PayloadAction<any>)=>{
    state.isFromGuest=action.payload
    },
    setIsViewTicket:(state,action) => {
      state.isViewTicket=action.payload
    },
  }
});

export const { fetchEventRequest, fetchEventError, fetchEventSuccess,setEventRequireInfo,setSelectTicket,setIsFromGuest,setIsViewTicket } = eventSlice.actions;

export default eventSlice.reducer;
