import React from "react";
import MobileImg1 from "../../assests/images/mobile 1.png";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import yoviBusiness from "../../assests/images/Top Photo for new tabs.png";
import IstIcon from "../../assests/icon/Create and Monitor Ticket Sales.svg";
import CustomerPreference from "../../assests/icon/Customer Preferences.svg";
import CashRewards from "../../assests/icon/Cash Rewards.svg";
import SMSApp from "../../assests/icon/SMS in App.svg";
import MarketDirectly from "../../assests/icon/Market Directly.svg";
import CustomerSpreadsheets from "../../assests/icon/Customer Spreadsheets.svg";
import CustomEvent from "../../assests/icon/Custom Event Organizer.svg";
import InAppTicket from "../../assests/icon/In App Ticket.svg";
import MultiplePayout from "../../assests/icon/Multiple Payouts.svg";
import LinkTracking from "../../assests/icon/Link Tracking.svg";
import CustomerDemographics from "../../assests/icon/Customer Demographics.svg";
import eventOrganizerBanner from "../../assests/images/event-organizer banner.png";

const EventOrganizer = () => {
  return (
    <>
      <Header banner={yoviBusiness} name="YOVI Event Organizer" header="Yovi Event Organizer" />
      <div className="sm:mx-24 mx-10 ">
        <h2 className="text-center text-3xl mt-16 mb-6 font-medium">
          YOVI Is a Free Product for Event Organizer- <br /> No Event Organizer
          Fees!!!
        </h2>
        <p className="text-center text-sm md:text-lg">
          YOVI serves as a social ticket platform as well where event organizers
          can save their time and money with <br /> the ability to take their
          event to new levels. Learn more about our Event Organizer features
        </p>
      </div>

      <div
        className="w-[80%] bg-green-500 h-[50vh] mx-auto my-16 relative rounded-xl"
        style={{ background: "#EAF3F9" }}
      >
        <img
          src={MobileImg1}
          alt=""
          className="object-contain h-[40vh] absolute bottom-0 left-0 right-0 mx-auto"
        />
      </div>
      <div>
        <div className="grid p-10 md:p-0 sm:grid-cols-2 gap-y-12 gap-x-28  mx-2 md:mx-10 xl:mx-[72px] lg:mx-24 mb-16">
          <div className="flex flex-row items-start ">
            <img src={IstIcon} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Create and Monitor Ticket Sales</h1>
              <p className="lg:text-lg text-sm">
                Create and upload free or paid ticketed events with the
                opportunity to monitor your sales.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={CashRewards} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Cash Rewards</h1>
              <p className="lg:text-lg text-sm">
                Receive extra cash from YOVI for selling a larger number of paid
                tickets on YOVI.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={CustomerPreference} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Customer Preferences</h1>
              <p className="lg:text-lg text-sm">
                Learn what your customers favorite drinks, music food and vibes
                are on YOVI
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={SMSApp} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">SMS In App Text Updates</h1>
              <p className="lg:text-lg text-sm">
                Communicate any updates for follow ups effectively with in app
                SMS texting to all customers
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={MarketDirectly} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Market Directly To Potential Customers</h1>
              <p className="lg:text-lg text-sm">
                Utilize YOVI Ad's to enhance your marketing efforts
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={CustomerSpreadsheets} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Customer Spreadsheets</h1>
              <p className="lg:text-lg text-sm">
                Recieve and export excel spreadsheets with customer's names and
                emails for your record
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={CustomEvent} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Custom Event Organizer Profiles</h1>
              <p className="lg:text-lg text-sm">
                Customize your event profile with photos, videos and social
                links for YOVI users to view.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={InAppTicket} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">In App Ticket Scanner</h1>
              <p className="lg:text-lg text-sm">
                Use the YOVI in app scanner to check in your customers on the
                day of your event
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={MultiplePayout} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Multiple Payouts (Stripe Integration)</h1>
              <p className="lg:text-lg text-sm">
                Payout via stripe as many times as you like. With YOVI you do
                not have to wait until after your event ends to receive your
                payouts
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={LinkTracking} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Link Tracking</h1>
              <p className="lg:text-lg text-sm">
                Generate and track multiple links for your event. See the number
                of views and cash made from each link
              </p>
            </div>
          </div>
          <div className="flex flex-row items-start">
            <img src={CustomerDemographics} alt="" className="lg:w-20  mt-2 w-20 mr-4" />
            <div>
              <h1 className="mb-2 font-medium text-xl lg:text-2xl">Customer Demographics</h1>
              <p className="lg:text-lg text-sm">
                Have the opportunity to receive customer demographics for all your events by age, gender, ethnicity, location & college/university. YOVI will also allow you to compare and track your progress between events.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-16 w-full">
        <img src={eventOrganizerBanner} alt="" className="w-full" />
      </div>
      <Footer />
    </>
  );
};

export default EventOrganizer;
