import { RootState } from "../../store";

export   const getEventList= (state: RootState) => state.event;

export const getisselectedEventrequireallInfo = (state:RootState)=> state.event.isselectedEventrequireallInfos

export const getSelectedTicket = (state:RootState)=> state.event.selectedTicket

export const getIsFromGuest = (state:RootState)=> state.event.isFromGuest

export const getIsViewTicket = (state:RootState)=> state.event.isViewTicket