import React, { useEffect, useState } from "react";
import { apiConstant } from "../../contants/apiContants";
import { getReq } from "../../services/apiCall";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import TicketCard from "../../components/TicketCard";
import { BackIcon, LocationIcon } from "../../assests/icon/customIcon";
import { useNavigate, useParams } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import Barcode from "react-barcode";
import bg from "../../assests/images/ticket_bg.png";
import tickect from "../../assests/images/ticket.png";
import banner from "../../assests/images/banner.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Keyboard, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { getIsViewTicket } from "../../store/features/event/eventSelector";
import { setIsViewTicket } from "../../store/features/event/eventSlice";
import TransferTicket from "./TransferTicket";
import { Share } from "../../assests/icon/customIcon"

function BookingBarcode(props: any) {
  const { bookingId, w, h } = props;
  return <Barcode value={bookingId} height={h} width={w} displayValue={true} />;
}

function Ticket({ item, viewTicket, setShowShare, index }: any) {
  const convertTimeStampToDateTime = (dt: any) => {
    if (dt) {
      const timestamp = dt;
      const date = new Date(timestamp);

      const options = {
        weekday: "short",
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      } as any;
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );
      // Output: "Sun, July 10, 8:00 PM"
      return formattedDate;
    }
  };
  const isPast = new Date(viewTicket?.eventData?.endDateTimeStamp) < new Date()

  return <div className={`w-full flex justify-center items-center relative z-0 `}>
    <LazyLoad threshold={0.95}>
      <img src={bg} alt="ticket" className={item.status === 1 ? 'h-600px sm-h-full' : `h-[450px] sm:h-full`} />
    </LazyLoad>
    <div
      className="absolute text-white flex justify-center  flex-col items-center z-40 top-10"
      style={{ marginTop: "6px" }}
    >
      {item.isShared ? (
        <div className="bg-white mb-2  text-black my-1 text-sm p-0.5 px-2 font-semibold flex  text-center max-w-[18rem]">
          <Share />
          <p className="mt-0.5 break-words text-center ">Recieved {item.sharedUserName ? `from ${item.sharedUserName}` : `ticket`}</p>
        </div>
      ) : (
        ""
      )}
      < div className="h-[60px] w-[60px] sm:h-[70px] sm:w-[70px] rounded-full flex justify-center items-center bg-gray-200 mb-4 overflow-hidden">
        <LazyLoad threshold={0.95} className="wh-100">
          <img
            src={viewTicket?.eventData?.eventImage}
            alt="event img"
            className="fit_img"
          />
        </LazyLoad>
      </div>
      <p className="font-bold pb-2 sm:pb-4 text-1xl sm:text-2xl truncate max-w-[250px] sm:max-w-[300px] text-center">
        {viewTicket?.eventData?.eventName}{" "}
      </p>
      <div className="text-center pb-2 max-w-[240px] truncate text-sm sm:text-xl flex gap-2 items-center">
        <div className="w-[25px]">
          <img src={tickect} alt="ticket" />
        </div>
        <p> {item.ticketArray?.ticketTitle} </p>
      </div>
      <p className="pb-2 sm:pb-4 text-sm text-ellipsis max-w-[180px] sm:max-w-[300px] text-center">
        {" "}
        {convertTimeStampToDateTime(
          viewTicket?.eventData?.startDateTimeStamp
        )}{" "}
        -{" "}
        {convertTimeStampToDateTime(
          viewTicket?.eventData?.endDateTimeStamp
        )}{" "}
      </p>
      <div className="pb-2 sm:pb-4 flex text-white ">
        <LocationIcon />
        <span className="pl-1 text-ellipsis max-w-[240px] sm:max-w-[300px] text-sm text-center">
          {" "}
          {viewTicket?.eventData?.address}{" "}
        </span>
      </div>
      {item?.status ? <div>
        <p className="text-center sm:py-2">
          {" "}
          {`${item?.quantity} Ticket${item?.quantity > 1 ? 's' : ''}`}
        </p>
        <p className="text-center sm:pb-4 text-1xl font-bold">
          {" "}
          {item?.bookingId}
        </p>
        <div className="my-2 rounded-sm">
          <BookingBarcode
            bookingId={item.bookingId}
            w={1.3}
            h={20}
          />
        </div>
        <>
          <p className="text-sm sm:mt-4 text-center ">
            {" "}
            *Please show this ticket
          </p>
          {isPast || item.quantity > 1 || item.isShared ? '' : <button
            hidden={isPast || item.quantity > 1 || item.isShared}
            onClick={() => setShowShare({ open: true, item, index })} className="w-[80%] mx-auto flex justify-center bg-white py-1 text-sm rounded-md font-semibold text-blue-500 mt-2">Share Ticket</button>}</>
      </div> : <div className="text-center max-w-[300px] font-bold text-xl mt-4 break-words" style={{ wordWrap: 'break-word' }}>
        The Ticket is Transferred {item?.recipientDetail.name ? 'to ' + item?.recipientDetail.name : ''}
      </div>}
    </div>
  </div>
}


function MyTickets() {
  const [ticketByEvent, setTicketByEvent] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewTicket, setViewTicket] = useState({}) as any;
  const [showShare, setShowShare] = useState({ open: false, item: {}, index: -1 })
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const dispatch = useDispatch();
  const isViewTicket = useSelector(getIsViewTicket)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize()
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  useEffect(() => {
    let t = localStorage.getItem("token");
    if (t) {
      setToken(t);
    }
  }, []);
  useEffect(() => {
    if (token) {
      getMyTickets();
    }
  }, [token]);

  const handleViewTicket = (ticketdata: any) => {
    if (ticketdata) {
      setViewTicket(ticketdata);
      dispatch(setIsViewTicket(true))
    }
  };
  const handleBack = () => {
    if (isViewTicket) {
      dispatch(setIsViewTicket(false))
    } else {
      navigate("/");
    }
  };


  const getMyTickets = () => {
    setLoading(true);
    let url;
    if (ticketId) {
      url = `${apiConstant.BASE_URL}/payment/getTicketDetails?userType=1&ticketId=${ticketId}`;
    } else {
      url = `${apiConstant.BASE_URL}/payment/getMyTickets?userType=1`;
    }
    getReq(url)
      .then((res) => {
        setLoading(false);
        if (res?.data?.statusCode) {
          let dataResponse = []
          let response;
          if (ticketId) {
            response = res?.data?.responseData?.response
            dataResponse.push(response)
          } else {
            response = res?.data?.responseData?.response?.result;
            dataResponse.push(...response)
          }
          let eventList: any = {};

          for (let item of dataResponse) {
            const eventId = item.eventsData._id;
            if (!eventList[eventId]) {
              eventList[eventId] = [];
            }
            eventList[eventId].push(item);
          }
          const eventTicket: any = Object.entries(eventList).map(([eventId, items]) => ({
            _id: eventId,
            items: items,
            eventData: eventList[eventId][0].eventsData
          }));
          setTicketByEvent(eventTicket)
        } else {
          let msg = res?.data?.error?.responseMessage;
          toast.error(msg);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error("Ack, sorry! We’re having a server issue. Try again later");
      });
  };

  return (
    <div>
      <div className="hidden md:block">
        <Header name="MY TICKETS" banner={banner} />
      </div>
      <div className="flex md:hidden px-4 mt-4 items-center">
        <div onClick={() => handleBack()}>
          <BackIcon />
        </div>
        <p className="text-center w-full font-bold text-1xl">
          {isViewTicket ? "Ticket" : "My Tickets"}{" "}
        </p>
      </div>
      <div className="w-full  px-4 md:px-12  py-4 md:py-8">
        {loading ? (
          <Loader />
        ) : (
          <div>
            {isViewTicket ? (
              <div className="w-full flex justify-center items-center">
                {viewTicket?.items?.length > 0 && (
                  isMobile ?
                    <Swiper
                      slidesPerView={isMobile ? 1 : 'auto'}
                      grabCursor={true}
                      navigation={true}
                      modules={[Navigation]}
                      spaceBetween={20}
                      initialSlide={0}

                      className="mySwiper flex md:grid sm:grid-cols-1 gap-x-4 md:gap-x-8 gap-y-4 mt-16"
                    >
                      {viewTicket?.items.map((item: any, index: number) => {
                        return (<SwiperSlide id={index + 'index'} className="md:relative md:!w-auto" >
                          <Ticket index={index} viewTicket={viewTicket} item={item} setShowShare={setShowShare} />
                        </SwiperSlide>)
                      })}
                    </Swiper > : <div className="grid md:grid-cols-2  xl:grid-cols-3 gap-4 items-center place-content-center h-full">
                      {viewTicket?.items.map((item: any, index: number) => {
                        return <div className="min-h-[650px]">
                          <Ticket index={index} viewTicket={viewTicket} item={item} setShowShare={setShowShare} />
                        </div>
                      })}
                    </div>
                )}
              </div>
            ) : (
              <>
                {ticketByEvent?.length > 0 ? (
                  <div
                    className={`grid grid-cols-1  gap-4 md:gap-8 z-0 relative ${ticketByEvent?.length === 1 ? "md:grid-cols-1 " : "md:grid-cols-3"
                      }`}
                  >
                    {ticketByEvent.map((item: any, i: any) => (
                      <div key={i} className="max-w-lg">
                        <TicketCard
                          item={item}
                          eventData={item?.eventsData}
                          // isViewTicket={isViewTicket}
                          clickCard={handleViewTicket}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="rounded-sm shadow-md h-[50vh] md:h-[30vh] bg-gray-100 flex justify-center items-center">
                    <p className="text-gray-500 px-6 py-4 shadow-lg bg-white">
                      {" "}
                      No Tickets Found .......!
                    </p>
                  </div>
                )}

              </>
            )}
            {showShare && <TransferTicket setViewTicket={setViewTicket} setOpen={setShowShare} open={showShare.open} item={showShare.item} index={showShare.index} />}
          </div>
        )}
        <ToastContainer />
      </div>
    </div >
  );
}

export default MyTickets;
