import Footer from "../../components/Footer";
import Header from "../../components/Header";
import yoviEventCreator from "../../assests/images/Top Photo for new tabs.png";

const YoviEventCreator = () => {
  return (
    <div>
      <Header banner={yoviEventCreator} />
      <Footer />
    </div>
  );
};

export default YoviEventCreator;
