// async function that handles the API request
import { apiConstant } from '../../contants/apiContants';
import { fetchDataError, fetchDataRequest, fetchDataSuccess } from '../../store/features/user/userSlice';
import { getReq, postReqJson, postReqParam } from '../apiCall';

export const fetchUserData = () => {
  return async (dispatch: any) => {
    let url = `${apiConstant.BASE_URL}/user/getUserDetail?userType=1`
    try {
      dispatch(fetchDataRequest());
      const res = await getReq(url);
      if (res?.data?.statusCode) {
        dispatch(fetchDataSuccess(res?.data?.responseData?.userProfile));
      }
      else {
        let msg = res?.data?.error?.responseMessage
        dispatch(fetchDataError(msg));
      }

    } catch (error) {
      dispatch(fetchDataError('Ack, sorry! We’re having a server issue. Try again later'));
    }
  };
};

export const editUserData = (data:any)=> {
  return async (dispatch:any)=>{
    let url = `${apiConstant.BASE_URL}/user/editProfile`
    try {
      dispatch(fetchDataRequest());
      const res = await postReqParam(url,data);
      if (res?.data?.statusCode) {
        dispatch(fetchDataSuccess(res?.data?.responseData?.userProfile));
        return {success:true}
      }
      else {
        let msg = res?.data?.error?.responseMessage
        dispatch(fetchDataError(msg));
      }
    } catch (error) {
      dispatch(fetchDataError('Ack, sorry! We’re having a server issue. Try again later'));
    }
  }
}

export const editProfileCover = (data:any)=>{
  return async (dispatch:any)=>{
    let url = `${apiConstant.BASE_URL}/user/profileCover`
    try {
      dispatch(fetchDataRequest());
      const res = await postReqParam(url,data);
      if (res?.data?.statusCode) {
        return {success:true}
      }
      else {
        let msg = res?.data?.error?.responseMessage
        dispatch(fetchDataError(msg));
      }
    } catch (error) {
      dispatch(fetchDataError('Ack, sorry! We’re having a server issue. Try again later'));
    }
  }
}
