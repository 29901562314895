import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { postReq } from '../services/apiCall'
import { apiConstant } from '../contants/apiContants'
import { useDispatch } from 'react-redux'
import { fetchDataSuccess } from '../store/features/user/userSlice'

function LoginPopup({ mobile, setOpen, setCurrentStep, selectedTicketIndex }: any) {
    const [phoneNumber, setPhoneNumber] = useState(mobile.mobile)
    const [otp, setOtp] = useState('')
    const [showOtpInput, setShowOtpInput] = useState(false)
    const [showResendOtp, setShowResendOtp] = useState(false)
    const [disableResendOtp, setDisableResendOtp] = useState(false) // Add state to disable the "Resend OTP" button
    const [timer, setTimer] = useState(60) // Set the initial timer value to 60 seconds
    const dispatch = useDispatch()
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null
        if (showResendOtp) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1)
            }, 1000)
        }
        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [showResendOtp])
    useEffect(() => {
        setShowOtpInput(false)
    }, [mobile])
    const sendOtp = (num: any, resend: boolean) => {
        let url = `${apiConstant.BASE_URL}/user/sendOtp`
        postReq(url, num)
            .then((res) => {
                // console.log('res::;', res)

                if (res?.data?.statusCode) {
                    let msg
                    msg = res?.data?.responseData?.message

                    if (resend) {
                        msg = 'Resend otp sent successfully'
                    }
                    toast.success(msg);

                }
                else {
                    let msg = res?.data?.error?.responseMessage
                    toast.error(msg)
                }

            })
            .catch((err) => {
                console.log('err', err)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)
            })
    }
    useEffect(() => {
        if (timer === 0) {
            setDisableResendOtp(false) // Enable the "Resend OTP" button when the timer reaches 0
        }
    }, [timer])

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value)
    }

    const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOtp(e.target.value)
    }

    const handleSendOtp = () => {
        setShowOtpInput(true)
        setShowResendOtp(true)
        setDisableResendOtp(true) // Disable the "Resend OTP" button when the "Send OTP" button is clicked
        setTimer(60) // Reset the timer to 60 seconds
        sendOtp({ mobile: mobile.mobile.slice(mobile.countryCode.length), countryCode: mobile.countryCode }, false)
    }
    console.log('mobile', mobile, phoneNumber, "phoneNumber")
    const handleResendOtp = () => {
        // Handle the logic for resending OTP here
        setDisableResendOtp(true) // Disable the "Resend OTP" button when it is clicked
        setTimer(60) // Reset the timer to 60 seconds
        sendOtp(mobile, true)
    }
    const verifyOtp = (otpv: any) => {
        let url = `${apiConstant.BASE_URL}/user/verifyOtp`
        postReq(url, otpv)
            .then((res) => {
                // console.log('res:verify otp:;', res)
                if (res?.data?.statusCode && res?.data?.responseData?.accessToken) {
                    let token = res?.data?.responseData?.accessToken
                    if (selectedTicketIndex !== -1 || selectedTicketIndex !== undefined) {
                        const existingQueryParams = new URLSearchParams(window.location.search);
                        existingQueryParams.set('selected', selectedTicketIndex.toString());
                        const newUrl = `${window.location.pathname}?${existingQueryParams.toString()}`;
                        window.history.replaceState(null, '', newUrl);
                    }
                    localStorage.setItem('token', token)
                    let msg = "Guest registration successful"
                    //  res?.data?.responseData?.message
                    dispatch(fetchDataSuccess(res?.data?.responseData?.userProfile));
                    toast.success(msg, { toastId: 'guest_login' });
                    setOpen(false)
                    setCurrentStep(2)
                }
                else {
                    if (res?.data?.statusCode) {
                        let msg = 'sorry,this user does not exist..please sign up !'
                        toast.error(msg);
                        setOtp('')
                    }
                    else {
                        let msg = res?.data?.error?.responseMessage
                        toast.error(msg);
                    }


                }
            })
            .catch((err) => {
                console.log('err', err)
                setTimeout(() => {
                    toast.error('Ack, sorry! We’re having a server issue. Try again later')
                }, 1000)

            })
    }


    const handlVerifyOtp = () => {
        let data = {
            userType: 1,
            otp: otp,
            type: 0,
            countryCode: mobile?.countryCode,
            mobile: mobile?.mobile?.slice(mobile?.countryCode.length)
        }
        setTimer(0)
        verifyOtp(data)
    }

    return (
        <div className="w-full">
            <p className='text-1xl md:text-2xl font-bold text-center mb-4'> Please Login to Continue  </p>
            <div className="mb-4 flex flex-col md:flex-row">
                <label htmlFor="phoneNumber" className="block text-gray-700 md:w-[55px] mt-3"> Mobile</label>
                <input readOnly type="text" id="phoneNumber" value={"+" + mobile.mobile} onChange={handlePhoneNumberChange} className="mt-1 p-2 border border-gray-300 rounded-md md:w-3/4" />
            </div>
            {showOtpInput && (
                <div className="mb-4 flex flex-col md:flex-row">
                    <label htmlFor="otp" className="block text-gray-700 md:w-[55px] mt-3">OTP</label>
                    <input type="text" id="otp" value={otp} onChange={handleOtpChange} className="mt-1 p-2 border border-gray-300 rounded-md md:w-3/4" />
                </div>
            )}

            <div className="flex flex-col md:flex-row justify-center text-center">
                {!showOtpInput && (
                    <button className="text-white px-4 py-2 w-full md:w-auto bg-sky-600 hover:bg-sky-500 rounded-md mt-4 font-bold disabled:bg-gray-400" onClick={handleSendOtp}>
                        Send OTP
                    </button>
                )}

                {showResendOtp && (
                    <p className='text-gray-500 py-4 mt-4'>
                        Didn’t you receive any code? <br />
                        <a
                            href="#"
                            className={disableResendOtp ? 'text-blue-300' : 'text-blue-500 cursor-pointer font-bold hover:underline'}
                            onClick={handleResendOtp}
                            style={{ textDecoration: 'none' }}
                        >
                            {disableResendOtp ? `Resend OTP (${timer}s)` : 'Resend a new code'}
                        </a>
                    </p>
                )}
            </div>

            {showOtpInput && (
                <button onClick={handlVerifyOtp} className="text-white px-4 py-2 w-full bg-sky-600 hover:bg-sky-500 rounded-md mt-4 font-bold disabled:bg-gray-400">
                    Submit
                </button>
            )}
        </div>
    )
}

export default LoginPopup