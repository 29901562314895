import Footer from "../../components/Footer";
import Header from "../../components/Header";
import yoviSupport from "../../assests/images/Top Photo for new tabs.png";
import QOpen from "../../assests/icon/QOpen.svg";
import QClose from "../../assests/icon/QClose.svg";
import { useState } from "react";
import { postReq } from "../../services/apiCall";
import { apiConstant } from "../../contants/apiContants";
import { ToastContainer, toast } from "react-toastify";

interface FormData {
  name: string;
  phone: string;
  email: string;
  question: string;
}

interface FormErrors {
  name?: string;
  phone?: string;
  email?: string;
  question?: string;
}

const YoviSupport = () => {
  const [isVisible, setIsVisible] = useState(Array(5).fill(false));

  const toggleVisibility = (index: number) => {
    const newVisibility = [...isVisible];
    newVisibility[index] = !newVisibility[index];
    setIsVisible(newVisibility);
  };
  const [formData, setFormData] = useState<FormData>({
    name: "",
    phone: "",
    email: "",
    question: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (value) (setErrors({
      ...errors,
      [name]: "",
    }))
    setFormData({
      ...formData,
      [name]: value,
    });
    if (!value) {
      setErrors({
        ...errors,
        [name]: "Please enter your " + name,
      })
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      // You can handle form submission logic here
      console.log(formData);
      // Reset form after successful submission
      setFormData({
        name: "",
        phone: "",
        email: "",
        question: "",
      });
      let url = `${apiConstant.BASE_URL}/user/contact-us`;
      postReq(url, formData)
        .then((res) => {
          // console.log('res::;', res)
          if (res?.data?.statusCode) {
            console.log(res.data);
            let msg;
            msg = res?.data?.responseData?.message;
            toast.success("Thank you for contacting us. We will get back to you soon.");
          } else {
            let msg = res?.data?.error?.responseMessage;
            toast.error(
              "Ack, sorry! We’re having a server issue. Try again later"
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
          setTimeout(() => {
            toast.error(
              "Ack, sorry! We’re having a server issue. Try again later"
            );
          }, 1000);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data: FormData): FormErrors => {
    const errors: FormErrors = {};

    if (!data.name.trim()) {
      errors.name = "Please enter your name";
    }

    if (!data.question.trim()) {
      errors.question = "Please enter your question or struggle with YOVI";
    }

    if (!data.email.trim()) {
      errors.email = "Please enter your email address";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }

    if (!data.phone.trim()) {
      errors.phone = "Please enter your phone number";
    } else if (!/^\+?\d{10,}$/.test(data.phone)) {
      errors.phone = "Phone number is invalid";
    }

    return errors;
  };

  const data = [
    {
      title: " Is YOVI available to use now?",
      description:
        "Yes! YOVI is officially on the app store! You can also purchase tickets to YOVI events through our web application!",
    },
    {
      title: " Who all can use YOVI?",
      description:
        "YOVI is available for anyone 18 and up. YOVI is targeting individuals who like to engage in social settings & events as well as event organizers & businesses looking to put on events. YOVI is a completely free product!!",
    },
    {
      title: " Do I have to pay to Download YOVI?",
      description: "No! YOVI is a completely free product to Download and use!",
    },
    {
      title:
        " As an event creator, when should I expect to receive my pay outs?",
      description:
        "As an event creator you should expect to receive your pay outs from events within 4-5 business days of your cash out.",
    },
    {
      title: "Are my payments secure with YOVI?",
      description: "Yes! YOVI collects all payment through Stripe integration!",
    },
    {
      title: "How much is the YOVI fee?",
      description:
        "Our standard YOVI fee for tickets is $1.10 + 3.6 % for customers buying tickets on our platform",
    },
  ];

  return (
    <div>
      <Header banner={yoviSupport} name="YOVI Support Hub" header="Yovi Support" />
      <div className="flex flex-col items-center">
        <h3 className="text-center text-3xl mt-16 mb-6 font-medium">YOVI Support Form</h3>
        <p className="text-center lg:w-[50%] w-[85%] mb-16 text-sm sm:text-lg">
          YOVI takes customer service very serious. We want all our customers to
          have a smooth process on our platform. If you have any troubles please
          feel out our YOVI support form and a team member will get back to you
          as soon as possible.
        </p>
        <div className="grid grid-cols-1 bg-[#FBFBFB] p-8 border-2 rounded-lg border-[#E8E8E8]">
          <form onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mb-4">
              <div className="w-full">
                <label htmlFor="name" className="w-full text-lg">
                  Name
                </label>
                <input
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  className="border-black-300 border-2 p-3 w-full rounded-md h-12 mt-2"
                  style={{ outline: 'none' }}
                />
                {errors.name && <p className="text-red-500 text-base">{errors.name}</p>}
              </div>
              <div className=" w-full">
                <label htmlFor="Name" className="w-full text-lg">
                  Phone number
                </label>
                <input
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  placeholder="Enter your phone number"
                  onChange={handleChange}
                  className="border-black-300 border-2 w-full p-3 rounded-md h-12 mt-2"
                />
                {errors.phone && <p className="text-red-500 text-base">{errors.phone}</p>}
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="Name" className="w-full text-lg">
                Email
              </label>
              <input
                value={formData.email}
                onChange={handleChange}
                name="email"
                placeholder="Enter your email address"
                type="text"
                className="w-full border-black-300 p-3 border-2 rounded-md h-12 mt-2"
              />
              {errors.email && <p className="text-red-500 text-base">{errors.email}</p>}
            </div>
            <div className="my-4">
              <label htmlFor="Name" className=" text-lg w-full">
                Current question or struggle with YOVI
              </label>
              <textarea
                name="question"
                value={formData.question}
                onChange={handleChange}
                id=""
                placeholder="Enter your question or struggle with YOVI"
                cols={30}
                rows={5}
                className="w-full p-3 border-black-300 border-2 rounded-md mt-2"
              />
              {errors.question && (
                <p className="text-red-500 text-base">{errors.question}</p>
              )}
            </div>
            <button className="w-40 h-14 bg-[#0076CD] text-white text-lg rounded-lg">
              Submit
            </button>
          </form>
          <p className="mt-3 text-base font-medium"> We will reach out to you in 24 hours or less
          </p>
        </div>
      </div>
      <div className="md:mt-10 w-full bg-[#EAF3F9]">
        <div className="col-span-1">
          <div className="w-full md:py-20 p-4 flex flex-col items-center">
            <h3 className=" text-3xl mb-10 text-center font-medium">
              Frequently asked questions
            </h3>
            <p className="w-[80%] md:w-[50%] sm:text-lg text-sm text-center pb-10">
              Welcome to the YOVI FAQ section! Find answers to common questions,
              troubleshoot issues, and optimize your event planning experience.
              Can't find what you need? Reach out to our support team for help.
              Let's dive in!
            </p>
            {data.map((item, index) => (
              <div
                key={index}
                className="  grid-cols-12 bg-white p-4 rounded-[30px] m-3 md:px-8 w-full md:w-[50%] grid gap-4"
              >
                <div className=" col-span-10 sm:col-span-11 bg-green w-full">
                  <h3
                    className={
                      isVisible[index]
                        ? "pb-3 text-base md:text-xl  font-semibold"
                        : "text-base md:text-xl font-semibold"
                    }
                  >
                    {item.title}
                  </h3>
                  <p
                    className="sm:text-lg text-sm"
                    style={{ display: isVisible[index] ? "block" : "none" }}
                  >
                    {item.description}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1 my-auto">
                  {isVisible[index] ? (
                    <img
                      src={QClose}
                      onClick={() => toggleVisibility(index)}
                      alt=""
                      className=" hover:cursor-pointer"
                    />
                  ) : (
                    <img
                      src={QOpen}
                      onClick={() => toggleVisibility(index)}
                      alt=""
                      className=" hover:cursor-pointer"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default YoviSupport;
