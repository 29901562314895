import React, { useEffect, useState } from 'react'
import { LocationIcon } from '../assests/icon/customIcon'
import LazyLoad from 'react-lazy-load';
import bg from '../assests/images/ticket_bg.png'
import ticket from '../assests/images/ticket.png'
import Barcode from 'react-barcode';
import { convertTimeStampToDateTimeforTicket as convertTimeStampToDateTime } from '../utils/commonFunction';

function BookingBarcode(props: any) {
    const { bookingId, w, h } = props
    return (
        <Barcode
            value={bookingId}
            height={h}
            width={w}
            displayValue={true}
        />
    );
}
export const Card = (props: any) => {
    console.log(props.item, "item")
    const [isMobile, setIsMobile] = useState(window.innerWidth < 460);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 460);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run this effect only once after initial render


    const { item, single } = props
    const eventData = item.eventId
    return <div className={`w-full ${single ? '' : 'hidden'} sm:flex justify-center items-center`}>
        <div className='w-full flex justify-center items-center relative z-0'>
            <LazyLoad threshold={0.95}>
                <img
                    src={bg}
                    alt='ticket'
                />
            </LazyLoad>
            <div className='absolute text-white flex justify-center  flex-col items-center z-40 top-10' style={{ marginTop: '20px' }}>
                <div className='h-[70px] w-[70px] rounded-full flex justify-center items-center bg-gray-200 mb-4 overflow-hidden'>
                    <LazyLoad threshold={0.95} className='wh-100'>
                        <img src={eventData?.eventImage} alt='profile' className='fit_img' />
                    </LazyLoad>
                </div>
                <p className='font-bold pb-2 sm:pb-2 text-1xl sm:text-2xl truncate max-w-[250px] sm:max-w-[300px] text-center'>{eventData?.eventName} </p>
                <div className='text-center pb-2 text-1xl truncate max-w-[280px] flex gap-2 items-center'>
                    <div className='w-[25px]'>
                        <img src={ticket} alt='ticket' />
                    </div>

                    <p> {item?.ticketArray?.ticketTitle} </p>
                </div>
                <p className='pb-2 sm:pb-4 text-sm text-ellipsis max-w-[240px] sm:max-w-[300px] text-center'> {convertTimeStampToDateTime(eventData?.startDateTimeStamp)} - {convertTimeStampToDateTime(eventData?.endDateTimeStamp)}  </p>
                <div className='pb-2 sm:pb-4 flex text-white '>
                    <LocationIcon />
                    <span className='pl-1 text-ellipsis max-w-[240px] sm:max-w-[300px] text-sm text-center'>  {eventData?.address} </span>
                </div>
                {item?.status ? <div>
                    <p className='text-center text-xs sm:text-sm sm:pb-2'> {`${item?.quantity} Ticket${item?.quantity > 1 ? 's' : ''}`}</p>

                    <p className='text-center pb-4 text-1xl sm:text-2xl font-bold'> {item?.bookingId}</p>
                    <div className='mb-2 rounded-sm'>
                        <BookingBarcode bookingId={item?.bookingId} w={isMobile ? 1.4 : 1.8} h={isMobile ? 35 : 40} />
                    </div>
                    <p className='text-sm text-center mb-3'> *Please show this ticket</p>
                </div> : <div className="text-center max-w-[300px] font-bold text-xl mt-4 break-words" style={{ wordWrap: 'break-word' }}>
                    The Ticket is Transferred {item?.recipientDetail?.name ? 'to ' + item?.recipientDetail?.name : ''}
                </div>}
            </div>
        </div>
    </div>
}

function TicketCard(props: any) {
    const { eventData, item, clickCard } = props


    const handleShowTicket = (item: any) => {
        clickCard(item)
    }

    return (
        <>
            <div className='rounded-md shadow-md hover:shadow-2xl mt-3 flex md:hidden items-start gap-4 bg-gray-50'
                onClick={() => handleShowTicket(item)}
            >
                <div className='w-[102px] h-[98px] rounded-sm md:w-full md:h-[300px] bg-gray-200 flex justify-center items-center overflow-hidden relative '>
                    <LazyLoad threshold={0.95} className='wh-100'>
                        <img
                            src={item.eventData?.eventImage}
                            alt='event img'
                            className='fit_img'
                        />
                    </LazyLoad>
                </div>
                <div className='pt-1'>
                    <p className=' texr-1xl md:text-2xl py-1 truncate max-w-[200px] md:max-w-[500px] font-bold'>{item.eventData?.eventName}</p>
                    <div className='text-gray-400 pb-1 flex '>
                        <p className='truncate max-w-[200px] md:max-w-[500px] text-sm'>  {convertTimeStampToDateTime(item.eventData?.startDateTimeStamp)} </p>
                    </div>
                    <div className='text-gray-400 flex '>
                        <LocationIcon />
                        <span className='pl-3 truncate max-w-[200px] md:max-w-[500px] text-sm'>  {item.eventData?.address} </span>
                    </div>
                    <div className='text-gray-400  pb-2 flex'>
                        <span className='pl-3 text-sm truncate max-w-[200px] md:max-w-[500px]'>  {item.items.length} Tickets </span>
                    </div>
                </div>
            </div>
            <div className='rounded-md shadow-md hover:shadow-2xl mt-3 hidden md:block items-start gap-4 bg-gray-50'
                onClick={() => handleShowTicket(item)}
            >
                <div className='w-[102px] h-[98px] rounded-sm md:w-full md:h-[150px] bg-gray-200 flex justify-center items-center overflow-hidden relative '>
                    <LazyLoad threshold={0.95} className='wh-100'>
                        <img
                            src={item.eventData?.eventImage}
                            alt='event img'
                            className='fit_img'
                        />
                    </LazyLoad>
                </div>
                <div className='p-0 md:p-6'>
                    <p className=' texr-1xl md:text-2xl pb-2 truncate max-w-[200px] md:max-w-[500px] font-bold'>{item.eventData?.eventName}</p>
                    <div className=' pb-3 text-sm  flex'>
                        <span className='text-gray-400 text-md truncate max-w-[200px] md:max-w-[500px]'>  {item.items.length} {item.items.length === 1 ? 'Ticket' : 'Tickets'}</span>
                    </div>
                    <div className='text-gray-400 pb-2 flex '>
                        <LocationIcon />
                        <span className='pl-3 truncate max-w-[200px] md:max-w-[500px] text-sm'>  {item.eventData?.address} </span>
                    </div>
                    <div className='text-gray-400 pb-2 flex'>
                        <LocationIcon />
                        <span className='pl-3 texr-sm truncate max-w-[200px] md:max-w-[500px]'>  {item.eventData?.eventLocationTitle} </span>
                    </div>
                    <button className='bg-blue-500 text-white w-full rounded-md mt-2 p-2 text-md'>
                        Show Tickets
                    </button>
                </div>
            </div>
        </>

    )
}

export default TicketCard