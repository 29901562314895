import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getReq, postReqJson } from '../services/apiCall';
import { apiConstant } from '../contants/apiContants';
import { Like, addComments, addReplies, incrementPage, likeReply, loadReply } from '../store/features/comment/commentSlice';
import { toast } from 'react-toastify';
import { getPage, getReply } from '../store/features/comment/commentSelector';
import Loader from './Loader';
import Replies from './Replies';
import { useNavigate } from 'react-router-dom';
import { encodeUserId } from '../utils/commonFunction';


function CommentCard({ comment, userImage, newReply, setSelectedReply, selectedtoReply, setReply, setOption, commentInputRef, commentLength, index, isLogged }: any) {
    const dispatch = useDispatch()
    const lastCommentRef = useRef<HTMLDivElement>(null)
    const [isLoading, setIsLoading] = useState(false);
    const [likeLoading, setLikeLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true);
    const [loadError, setLoadError] = useState(false);
    // const [replies, setReplies] = useState([])
    const replies = useSelector(getReply(comment._id))
    const [showReply, setShowReply] = useState(false)
    const navigate = useNavigate()
    const observer = useRef<IntersectionObserver>();
    const page = useSelector(getPage)

    const limit = 10
    console.log(lastCommentRef, "length", index, page)
    const userName = comment?.userData?.businessUser ? comment.userData.businessName : comment?.userData?.firstName + " " + comment?.userData?.lastName
    const addLike = async (body: any) => {
        if (!isLogged) return toast.warn("Sign in to your account to like this comment.", { toastId: comment._id })
        setLikeLoading(true)
        const formData = new FormData()
        formData.append('userType', '1')
        try {
            Object.keys(body).map((name: string) => {
                formData.append(name, body[name])
            })
            const response = await postReqJson(`${apiConstant.BASE_URL}/comment/editEventCommentsStatus`, formData)
            if (response?.data?.responseData?.message) {
                // const comment: number = comments?.findIndex((comment: any) => comment._id === commentedOn)
                if (body.commentType.toString() === "1") dispatch(Like({ id: comment._id, status: body.status }))
                else {
                    console.log(comment._id == newReply._id, "2ids 6767")
                    if (body.likedTo === newReply._id) {
                        setReply({ ...newReply, likes: newReply.likes + (body.status ? 1 : -1) })
                        console.log(newReply.likes + (body.status ? 1 : -1), "newReroioiereoiri767")
                    }
                    else dispatch(likeReply({ commentId: comment._id, reply: body.likedTo, value: body.status ? 1 : -1 }))
                    return 1
                }
            }
            else {
                toast.error('Ack, sorry! We’re having a server issue. Try again later')
            }
        } catch (error) {
            toast.error('Ack, sorry! We’re having a server issue. Try again later')
        } finally {
            setLikeLoading(false)
        }
    }

    const loadReplies = async (page = 0) => {
        try {
            const url = isLogged ? `${apiConstant.BASE_URL}/comment/getAllCommentsByEvent?commentType=2&eventId=${comment._id}&limit=${limit}&page=${page}` : `${apiConstant.BASE_URL}/comment/getAllCommentsByEventforAdmin?commentType=2&eventId=${comment._id}&limit=${limit}&page=${page}`
            const response = await getReq(url)
            if (response?.data?.statusCode) {
                const replies = response?.data?.responseData.replies
                // setReplies(replies)
                if (page > 0) dispatch(addReplies({ _id: comment._id, data: replies }))
                else dispatch(loadReply({ _id: comment._id, data: replies }))
                return 1
            }
            else {
                return 0
            }
        } catch (error) {
            toast.error('Ack, sorry! We’re having a server issue. Try again later')
            return 0
        }
    }

    const loadComments = async (limit: number, page: number, type = 1, eventId = comment.eventId) => {
        try {
            setIsLoading(true)
            const url = isLogged ? `${apiConstant.BASE_URL}/comment/getAllCommentsByEvent?commentType=${type}&eventId=${eventId}&limit=${limit}&page=${page}` : `${apiConstant.BASE_URL}/comment/getAllCommentsByEventforAdmin?commentType=${type}&eventId=${eventId}&limit=${limit}&page=${page}`
            const response = await getReq(url)
            console.log(response, "response")
            if (response?.data?.statusCode) {
                const comments = response?.data?.responseData.replies
                dispatch(addComments(comments))
                if (type === 1) dispatch(incrementPage(page + 1))
                if (comments.length < limit || !comments.length) {
                    setHasMore(false)
                }
            }
            else if (response.data?.error.responseMessage) {
                toast.error(response.data?.error?.responseMessage)
                setLoadError(true);
            }
        } catch (error) {
            console.log(error)
            setLoadError(true);
            toast.error('Ack, sorry! We’re having a server issue. Try again later')

        } finally {
            setIsLoading(false)
        }
    }


    console.log(selectedtoReply.id, "ideeey")
    useEffect(() => {
        if (isLoading || loadError) return;
        if (selectedtoReply.id) return
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                loadComments(limit, page)
            }
        });

        if (lastCommentRef.current) {
            observer.current.observe(lastCommentRef.current);
        }
    }, [isLoading, hasMore, page, loadError]);
    const toProfile = async (id: string) => {
        if (comment.userData._id === isLogged?._id) {
            navigate(`/profile`)
        }
        else {
            const Id = await encodeUserId(id)
            navigate(`/profile/${Id}` + (comment.userData.businessUser ? `?type=${comment?.userData?.userType}` : ''))
        }
    }

    return (
        <><div className='pt-6 p-6 md:p-6 mb-8 md:mr-4   md:px-8  bg-white rounded-xl'>
            <div className="flex   justify-between items-center md:mt-2">
                <div className="flex items-center ">
                    <p className="inline-flex  mr-3   font-semibold text-lg">  {userImage ? <img
                        className="mr-4 w-[50px] h-[50px] rounded-full"
                        src={userImage}
                        alt="" />
                        : <div className="mr-4  w-[50px] h-[50px] bg-gray-500 rounded-full flex items-center justify-center">
                            <span className="text-lg font-semibold text-gray-900">{userName[0].toUpperCase()}</span>
                        </div>}
                        <div>
                            <span className='cursor-pointer' onClick={() => toProfile(comment.userData._id)}>
                                {userName}
                            </span>
                            <p className="text-xs font-normal  text-gray-500 ml-[2px]">
                                {moment(comment?.commentDate).fromNow()}
                            </p>
                        </div>
                    </p>
                </div>
                <div className=' text-center flex'>

                    {comment?.isLiked ?
                        <button
                            className=" md:static md:inline-flex items-center p-2 text-sm font-medium text-center text-gray-500   rounded-lg hover:bg-gray-100  focus:outline-none focus:ring-gray-50   "
                            disabled={likeLoading}
                            onClick={() => addLike({ likedTo: comment._id, eventId: comment.eventId, status: 0, commentType: 1, type: 1 })}
                            type="button">
                            <svg fill="#ff0000" height="16px" width="16px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M372.87,33.391c-46.903,0-90.88,23.598-116.87,62.152c-25.99-38.555-69.967-62.152-116.87-62.152 C62.413,33.391,0,95.804,0,172.522c0,37.935,14.164,73.011,39.88,98.76l200.38,200.804c4.207,4.207,9.794,6.522,15.74,6.522 s11.532-2.315,15.74-6.521l200.314-200.772C497.815,245.522,512,210.435,512,172.522C512,95.804,449.587,33.391,372.87,33.391z"></path> </g> </g> </g></svg>
                        </button> :
                        <button
                            className=" md:static md:inline-flex items-center p-2 text-sm font-medium text-center text-gray-500   rounded-lg hover:bg-gray-100  focus:outline-none focus:ring-gray-50   "
                            disabled={likeLoading}
                            type="button"
                            onClick={() => addLike({ likedTo: comment._id, eventId: comment.eventId, status: 1, commentType: 1, type: 1 })}
                        >
                            <svg fill="#6b7280" height="16px" width="16px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 511.996 511.996" xmlSpace="preserve" stroke="#6b7280"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M467.01,64.373c-29.995-29.995-69.299-44.988-108.612-44.988c-36.779,0-73.259,13.662-102.4,39.919 c-29.15-26.257-65.621-39.919-102.4-39.919c-39.313,0-78.618,14.993-108.612,44.988c-59.981,59.981-59.981,157.235,0,217.225 L255.998,492.61L467.01,281.598C526.991,221.609,526.991,124.363,467.01,64.373z M448.919,263.49L255.998,456.403L63.085,263.499 c-49.903-49.911-49.903-131.115,0-181.018c24.175-24.175,56.32-37.487,90.513-37.487c31.206,0,60.399,11.563,83.695,31.889 l18.705,17.485l18.714-17.493c23.296-20.318,52.489-31.889,83.695-31.889c34.193,0,66.33,13.312,90.513,37.487 C498.831,132.375,498.822,213.587,448.919,263.49z"></path> </g> </g> </g></svg>
                        </button>}
                    <p className='py-2   md:static text-xs md:text-sm font-medium'> {comment.likes ? comment.likes : ''}  </p>
                </div>
            </div><pre className="font-sans text-gray-500 flex  items-center mt-6 font-normal ml-1">{comment?.comment}</pre><div className="flex items-center mt-4 space-x-4 ml-1">
                {/* <p className="text-sm text-gray-500  ml-16">
        {moment(comment?.commentDate).fromNow().replace('ago', '')}
    </p> */}
                <button hidden={!comment.likes} className='  text-gray-500 text-sm font-semibold cursor-default mr-4'>
                    {comment.likes ? `${comment.likes}  ${comment.likes > 1 ? 'people' : 'person'} found this useful` : ''}
                </button>
                <button type="button"
                    className="flex items-center text-sm !ml-0 text-gray-500 hover:underline focus:underline font-semibold disabled:no-underline"
                    onClick={() => {
                        commentInputRef?.current?.scrollIntoView();
                        commentInputRef?.current?.focus();
                        setSelectedReply({ id: comment._id, name: userName });
                    }}
                    disabled={!isLogged}
                >
                    {/* <svg className="mr-1.5 w-3.5 h-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5h5M5 8h2m6-3h2m-5 3h6m2-7H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
    </svg> */}
                    Reply
                </button>

                {comment.userData._id === isLogged?._id && <button
                    onClick={() => setOption(comment._id, "")}
                    hidden={comment.userData._id !== isLogged?._id}
                    className="md:static md:inline-flex font-semibold items-center p-2 text-sm  text-center text-gray-500   rounded-lg hover:bg-gray-10;0 hover:underline focus:underline focus:underline "
                >
                    Delete
                    {/* <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 12H18.01M12 12H12.01M6 12H6.01M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12Z" stroke="#687280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg> */}
                </button>}
                {index === commentLength - 1 && <div ref={lastCommentRef}></div>}
            </div>
            {!newReply?._id || (newReply._id && comment.replies !== 1) ? <button onClick={() => {
                setReply({})
                if (!showReply) loadReplies();
                setShowReply(!showReply);
            }} hidden={!comment.replies} className='ml-1 mt-3 text-gray-500 text-sm font-normal hover:underline outline-none'>
                {showReply ? `Hide ${comment.replies} Replies` : `Show ${newReply._id ? "all" : ""} ${comment.replies} Replies`}
            </button> : ""}
            {newReply._id && comment.replies === 1 ? <button onClick={() => {
                setReply({})
                setShowReply(false)
            }} hidden={!comment.replies} className='ml-1 mt-3 text-gray-500 text-sm font-normal hover:underline outline-none'>
                {`Hide ${comment.replies} Replies`}
            </button> : ""}
            {isLoading && <Loader />}

            {showReply ? replies?.map((reply: any, index: number) => (<Replies index={index} key={reply._id} totalReply={comment.replies} userId={isLogged?._id} replyLength={replies.length} setOption={(id: string, parent: string) => setOption(id, parent)} reply={reply} addLike={addLike} loadReplies={loadReplies} />)) : ""}
            {newReply?._id && !showReply ? <Replies userId={isLogged?._id} setOption={(id: string, parent: string) => setOption(id, parent)} reply={newReply} addLike={addLike} loadReplies={loadReplies} /> : ""}
            {/* {replyOpen ? <div className='flex w-full ml-16 mt-4 '>
        <img
            className="mr-4 w-[50px] h-[50px] rounded-full"
            src={userImage}
            alt="" />
        <div className=" w-[64.5%] md:w-[70%] lg:w-[80%]  -mr-3 md:-mr-1 flex mb-6 items-center justify-between p-1   rounded-xl border-2 border-gray-300">
            <input onChange={(e) => {
                setTimeout(() => {
                    setComment(e.target.value)
                }, 300)
            }} className="text-xs md:text-base bg-transparent border-none focus:outline-none  h-full p-1  md:p-2" placeholder="Write your comment..."></input>
            <button disabled={!commentInput}
                //  onClick={() => { addComment(commentInput || "", eventId) }}
                type="submit" className="text-md p-1">
                <svg className='w-8 md:w-auto h-10 ' fill={commentInput ? "#0284C7" : "#6b7280"} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24.00 24.00" xmlSpace="preserve" width="40px" height="40px" stroke="#6b7280" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.336"></g><g id="SVGRepo_iconCarrier">  <g id="surface1"> <path d="M2,3v7.8L18,12L2,13.2V21l20-9L2,3z"></path> </g> <rect className="st0 fill-none" width="24" height="24"></rect> </g></svg>
            </button>
        </div>
    </div> : ''} */}
        </div>
            <div hidden={!isLogged} className={index === commentLength - 1 ? selectedtoReply.id ? "mb-36" : "mb-28" : ""} />
        </>
    )
}

export default CommentCard