import Footer from "../../components/Footer";
import Header from "../../components/Header";
import yoviBusiness from "../../assests/images/Top Photo for new tabs.png";
import BusinessMainImg from "../../assests/images/BusinessMainImg.png";
import Social1 from "../../assests/images/Social1.png";
import Social2 from "../../assests/images/Social2.png";
import Social3 from "../../assests/images/Social3.png";
import Social4 from "../../assests/images/Social4.png";
import Social5 from "../../assests/images/Customer Prefrences YOVI Businessess.png";
import Social6 from "../../assests/images/In App Text Updates YOVI Businessess.png";
import BusinessBtmImg from "../../assests/images/BusinessBtmImg.png";
import logo from "../../assests/logo/YOVI LOGO FINAL  1.png";

const YoviBusiness = () => {
  return (
    <div>
      <Header banner={yoviBusiness} name="YOVI For Social Businesses" header="Yovi For Business" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 my-20 mx-[72px]">
        <div className="items-center my-auto ">
          <h3 className="text-3xl pb-4 text-center md:text-left font-medium">
            Empowering Social Businesses with Targeted Marketing and Insightful
            Analytics
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left">
            YOVI provides a simple way for social businesses (bars, clubs,
            lounges etc) to market their business or event directly to current
            and potential customers. Participating businesses receive
            demographic and behavioral data on their customers from the app,
            allowing businesses to get an inside look at their customer's
            experience. Businesses and event vendors also have the ability to
            directly sell and monitor ticket sales through YOVI.
          </p>
        </div>
        <div>
          <img src={BusinessMainImg} alt="" className="mx-auto" />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 my-20 mx-[72px] ">
        <div>
          <img src={Social1} alt="" className="mb-4 mx-auto w-full" />
          <h3 className="text-xl font-medium md:text-2xl my-2 text-center md:text-left">
            Social Businesses Profiles
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left">
            Customize your page with promotions, photos, videos and social links
            for YOVI users to view
          </p>
        </div>
        <div>
          <img src={Social2} alt="" className="mb-4 mx-auto w-full" />
          <h3 className="text-xl font-medium md:text-2xl my-2 text-center md:text-left">
            Market directly to potential customers
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left">
            Utilize YOVI Ad's to enhance your marketing efforts
          </p>
        </div>
        <div>
          <img src={Social3} alt="" className="mb-4 mx-auto w-full" />
          <h3 className="text-xl font-medium md:text-2xl my-2 text-center md:text-left">
            Create and Monitor Ticket Sales
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left">
            Create and upload free or paid ticketed events with the opportunity
            to monitor your sales.
          </p>
        </div>
        <div>
          <img src={Social4} alt="" className="mb-4 mx-auto w-full" />
          <h3 className="text-xl font-medium md:text-2xl my-2 text-center md:text-left">
            Customer Demographics
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left">
            Have the opportunity to receive customer demographics for all your
            events by age, gender, ethnicity, location & college/university.
            YOVI will also allow you to compare and track your progress between
            events.
          </p>
        </div>
        <div>
          <img src={Social5} alt="" className="mb-4 mx-auto w-full" />
          <h3 className="text-xl font-medium md:text-2xl my-2 text-center md:text-left">
            Customer Preferences YOVI Businesses
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left">
            Learn what your customers favorite drinks, music, food and vibes are
            on YOVI.
          </p>
        </div>
        <div>
          <img src={Social6} alt="" className="mb-4 mx-auto w-full" />
          <h3 className="text-xl font-medium md:text-2xl my-2 text-center md:text-left">
            SMS Text Updates
          </h3>
          <p className="sm:text-lg text-sm text-center md:text-left">
            Communicate any updates or follow ups effectively with in app SMS
            texting to all customers
          </p>
        </div>
      </div>
      <div className="my-10 grid grid-cols-1 md:grid-cols-2 bg-[#EAF3F9] px-[72px] py-10 gap-10">
        <div className="my-auto ">
          <h3 className="text-3xl text-center sm:text-left mb-2 font-medium">Scalability and Flexibility</h3>
          <p className="sm:text-lg text-center sm:text-left text-sm">
            Whether you're hosting a small corporate gathering or a large-scale
            industry conference, YOVI offers scalability and flexibility to
            accommodate events of all sizes. Easily scale your event planning
            efforts up or down to meet changing business needs, without
            compromising on quality or efficiency.
          </p>
          <img src={logo} alt="" className="w-52 mx-auto sm:mx-1 mt-5" />
        </div>
        <div>
          <img src={BusinessBtmImg} alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default YoviBusiness;
