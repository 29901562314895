import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home/Home';
import LogIn from './pages/LogIn';
import SignUp from './pages/SignUp';
import UserProfile from './pages/user-profile/UserProfile';
import 'react-toastify/dist/ReactToastify.css';
import { appRoute } from './contants/appRouteConstants'
import EventDetails from './pages/event/EventDetails';
import MyTickets from './pages/ticket/MyTickets';
import SingleTicket from './pages/single-ticket/SingleTicket';
import User from './pages/user/User';
import About from './pages/Tabs/About';
import YoviUser from './pages/Tabs/YoviUser';
import YoviEventCreator from './pages/Tabs/YoviEventCreator';
import YoviBusiness from './pages/Tabs/YoviBusiness';
import YoviSupport from './pages/Tabs/YoviSupport';
import EventOrganizer from './pages/event/EventOrganizer';

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    children: []
  },
  {
    path: appRoute.Login,
    element: <LogIn />
  },
  {
    path: appRoute.Signup,
    element: <SignUp />
  },
  {
    path: appRoute.UserDetails,
    element: <UserProfile />
  },
  {
    path: appRoute.EventDetails,
    element: <EventDetails />
  },
  {
    path: appRoute.MyTickets,
    element: <MyTickets />
  },
  {
    path: appRoute.MyTicket,
    element: <MyTickets />
  },
  {
    path: appRoute.TicketDetails,
    element: <EventDetails />
  },
  {
    path: appRoute.SingleTicket,
    element: <SingleTicket />
  },
  {
    path: appRoute.Profile,
    element: <User />
  },
  {
    path:appRoute.About,
    element: <About/>
  },
  {
    path:appRoute.YoviUser,
    element: <YoviUser/>
  },
  {
    path:appRoute.YoviEventCreator,
    element: <YoviEventCreator/>
  },
  {
    path:appRoute.YoviBusiness,
    element: <YoviBusiness/>
  },
  {
    path:appRoute.YoviSupport,
    element: <YoviSupport/>
  },
  {
    path: appRoute.EventOrganizer,
    element: <EventOrganizer />
  }
])

function App() {

  return (
    <div>
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;
